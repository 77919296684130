import React from 'react'
import { Link as ReactRouterLink, useParams } from 'react-router-dom'
import { Link, Flex, Box, Breadcrumb, BreadcrumbItem } from '@chakra-ui/react'
import { MdChevronLeft } from 'react-icons/md'

const BreadcrumbNavigation = (props) => {
  let { orderID, id } = useParams()
  if (props.list.length <= 1) {
    return null
  }
  return (
    <Flex alignItems='center'>
      {props.list &&
        props.list.length > 1 &&
        props.list.map(
          (items) =>
            window.location.pathname === items.path && (
              <Box
                key={items.path}
                mr={[2, 5]}
                color='primaryText'
                cursor={'pointer'}
              >
                <Link
                  key={items.pathTo}
                  as={ReactRouterLink}
                  style={{ color: 'inherit' }}
                  to={() =>
                    items.useOrderIDParam
                      ? `/dashboard/order/${orderID}`
                      : items.pathTo
                  }
                >
                  <MdChevronLeft data-testid='leftChevron' size='50' />
                </Link>
              </Box>
            )
        )}
      <Box>
        <Breadcrumb seperator=''>
          {props.list.map(
            (items) =>
              window.location.pathname === items.path && (
                <BreadcrumbItem
                  key={items.name}
                  fontWeight='base'
                  isCurrentPage
                  w='100%'
                  fontSize={['lg', 'xl', '3xl', '4xl']}
                  color='primaryText'
                >
                  <span
                    data-testid='breadCrumbText'
                    style={{ color: 'inherit' }}
                  >
                    {items.showID
                      ? items.name + ' - ' + id.slice(id.length - 6)
                      : items.name}
                  </span>
                </BreadcrumbItem>
              )
          )}
        </Breadcrumb>
      </Box>
    </Flex>
  )
}

export default BreadcrumbNavigation
