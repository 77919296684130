import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import {
  VStack,
  Heading,
  StackDivider,
  Text,
  FormLabel,
  FormControl,
  Stack,
  useMediaQuery,
} from '@chakra-ui/react'
import Select from '../components/Select'
import { useTranslation } from 'react-i18next'
import Loading from '../components/Loading'
import { useGetSettings, usePatchSettings } from '../services/settings'
import { useProfile } from '../services/auth'
import DocumentTitle from 'react-document-title'
import { ToastCallback } from '../utils/utils'
import gtag from 'ga-gtag'
import API from '../utils/api'

const Settings = () => {
  const { t, i18n } = useTranslation()
  const [smallScreen] = useMediaQuery('(max-width: 1024px)')
  const profile = useProfile()
  const { data, error, isLoading } = useGetSettings(
    profile.isFetched && profile.data.id,
    profile.isFetched
  )
  useEffect(() => {
    gtag('Vitreo - ' + t('settings.pageTitle'))
    const getProfile = async () => {
      return await API.get('profile').then((d) => {
        return d.data && d.data.profile
      })
    }
    getProfile().then((profile) => {
      console.log('profike', profile)
      gtag('config', process.env.REACT_APP_GA4_MEASUREMENT_ID, {
        user_id: profile.username,
      })
      gtag('set', 'user_properties', {
        crm_id: profile.username,
      })
    })
  }, [t])

  const updateLanguageSettings = usePatchSettings(
    ToastCallback(
      t('settings.languageSelectionUpdated'),
      t('settings.languageSelectionNotUpdated')
    ),
    profile.isFetched && profile.data.id
  )

  const spacingProps = {
    stackWrap: smallScreen
      ? { width: '100%', spacing: 6 }
      : { width: '60%', spacing: 6 },
    vstackWrap: { spacing: '4rem', align: 'stretch' },
    vstackInner: { spacing: '2rem', align: 'stretch' },
    vstackInnerInner: {
      spacing: '2',
      align: 'stretch',
      divider: <StackDivider borderColor='gray.200' />,
    },
    heading: { size: 'md', mr: '5' },
  }

  const languageSelect = [
    {
      label: 'Deutsch',
      id: 'DE',
    },
    {
      label: 'English',
      id: 'EN',
    },
  ]

  return (
    <DocumentTitle title={'Vitreo - ' + t('settings.pageTitle')}>
      <Stack {...spacingProps.stackWrap}>
        <Heading data-testid='settingsText' as='h2' mb={8}>
          {t('settings.pageTitle')}
        </Heading>
        {isLoading && <Loading />}
        {error ||
          (data && data.length === 0 && <Text>{t('errorNoData')}</Text>)}
        {data && (
          <VStack {...spacingProps.vstackWrap}>
            <VStack {...spacingProps.vstackInner}>
              <Heading data-testid='languageText' {...spacingProps.heading}>
                {t('settings.language')}
              </Heading>
              <FormControl>
                <FormLabel data-testid='languageLabel' fontWeight='base'>
                  {t('settings.applicationLanguage')}
                </FormLabel>
                <Select
                  onChange={(e) => {
                    gtag('event', `Set ${e.id} language`)
                    i18n.changeLanguage(e.id.toLowerCase())
                    updateLanguageSettings.mutate({
                      language: e.id,
                    })
                  }}
                  value={
                    { en: languageSelect[1], de: languageSelect[0] }[
                      i18n.language
                    ]
                  }
                  options={languageSelect}
                  name='Language Select'
                  closeMenuOnSelect={true}
                  size='md'
                />
              </FormControl>
            </VStack>
          </VStack>
        )}
      </Stack>
    </DocumentTitle>
  )
}

export default withRouter(Settings)
