import * as turf from "@turf/turf";
import {Layer, Source} from "react-map-gl";
import React, {useEffect, useState} from "react";
import isEqual from "react-fast-compare";

const Cell = React.memo(({ locations, color, viewport, indexSensor, colorNonCell}) => {
        const [locationsUpdated, setLocationsUpdated] = useState([])

        useEffect(() => {
        if (locations && locations.length) {
                const locationsUpdated = locations.map(l => {
                        let lon = l["lon"], lat = l["lat"]
                        const radius = l["accuracy"] && l["source"] ? l["accuracy"] / 1000 : l["source"] ? 0.5 : 0.1
                        const center = [lon, lat];
                        const step = l["source"] ? 50 : 3
                        const options = {steps: step, units: "kilometers", properties: {foo: "bar", description: ""}};
                        const circle = turf.circle(center, radius, options);
                        const line = turf.lineString(...circle.geometry.coordinates);
                        const colorCircle = l["source"] ? color : colorNonCell
                        return {...l, "circle": circle, "line": line, "color": colorCircle}
                })
                setLocationsUpdated(locationsUpdated)
        }

        }, [locations, color, colorNonCell])

        return (<>
            {locationsUpdated && locationsUpdated.length &&
                locationsUpdated.map((l, index) => {
                    return (
                        <div key={index}>
                            <Source id={`${indexSensor}-${index}_circle`} type="geojson" data={l["circle"]}>
                                <Layer
                                    id={`${indexSensor}-${index}-point-90-hi`}
                                    type="fill"
                                    paint={{
                                        "fill-color": l["color"],
                                        "fill-opacity": 0.4,
                                        "fill-outline-color": "yellow"
                                    }}
                                />
                            </Source>
                            <Source id={`${indexSensor}-${index}_line`} type="geojson" data={l["line"]}>
                                <Layer
                                    id={`${indexSensor}-${index}-point-9-hi`}
                                    type="line"
                                    paint={{
                                        "line-color": l["color"],
                                        "line-width": viewport.zoom <= 5 ? 10 : !l["range"] ? 2 : 0
                                    }}
                                />
                            </Source>

                        </div>
                        )})
            }
        </>)
})

export default React.memo(Cell, isEqual)