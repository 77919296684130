import React, { useState } from 'react'

import '../css/WebTour.css'
import { useHistory } from 'react-router-dom'
import Tour from 'reactour'
import { useTranslation } from 'react-i18next'

const stepStyle = {
  color: '#666',
  background: '#dff9fb',
}

function SensorWebTour({ fromDashboardReverse }) {
  const [isTourOpen, setIsTourOpen] = useState(true)
  const history = useHistory()
  const { t } = useTranslation()

  const steps = [
    {
      action: () => {
        const pathname = window.location.pathname
        if (pathname === '/my-sensors') {
          history.push('/?clickItem=true')
        }
      },
    },
    {
      selector: '#addSensor',
      content: t('tour.step2'),
      style: stepStyle,
    },
    {
      selector: '#side_dashboard',
      content: t('tour.step3'),
      style: stepStyle,
    },
    {
      action: () => {
        const pathname = window.location.pathname
        if (pathname === '/my-sensors') {
          history.push({
            pathname: '/',
            search: '?fromSensor=True',
          })
        }
      },
    },
  ]

  return (
    <Tour
      startAt={fromDashboardReverse ? 2 : 1}
      steps={steps}
      isOpen={isTourOpen}
      rounded={5}
      stepInteraction={true}
      maskClassName='mask'
      accentColor='#269faf'
      onRequestClose={() => setIsTourOpen(false)}
      badgeContent={(curr, tot) => `${curr} of ${tot}`}
      showNavigation={false}
    />
  )
}

export default SensorWebTour
