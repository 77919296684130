import { Center, CircularProgress } from '@chakra-ui/react'

const Loading = (props) => {
  const defaultProps = {
    size: '100px',
    color: 'tertiary',
  }
  return (
    <Center h='25vh'>
      <CircularProgress
        size={props.size ? props.size : defaultProps.size}
        isIndeterminate
        color={props.color ? props.color : defaultProps.color}
      />
    </Center>
  )
}

export default Loading
