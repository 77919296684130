import API from '../utils/apiDomain'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'
import { usePatchSensorReadings, usePostSensorReadings } from './apiGateway'

export const useGetOrder = (id) => {
  return (
    useQuery(['order', id], async () => {
      return await API.get(`/customers/orders/${id}/`)
        .then((d) => d && d.data)
        .catch((err) => {
          if (err.response) {
            // client received an error response (5xx, 4xx)
            return err.response
          } else if (err.request) {
            // client never received a response, or request never left
            return err.request
          } else {
            // anything else
            return err
          }
        })
    }) ?? []
  )
}

export const useGetOrderReadings = (id) => {
  return useQuery(['orderReadings', id], async () => {
    return await API.get(`/customers/orders/${id}/readings/`)
      .then((d) => d && d.data)
      .catch((error) => error)
  })
}

export const useGetOrderLocations = (id) => {
  return useQuery(['orderLocations', id], async () => {
    return await API.get(`/customers/orders/${id}/locations1/`)
      .then((d) => d && d.data)
      .catch((error) => error)
  })
}

export const useGetSensorsUnassigned = (id) => {
  return (
    useQuery(['sensorUnassigned', id], async () => {
      return await API.get(`/customers/orders/${id}/list_unassigned/`)
        .then((d) => d && d.data)
        .catch((error) => error)
    }) ?? []
  )
}

export const usePatchOrder = (items, id) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  return useMutation(
    ['patchOrder', id],
    async (data) => {
      return await API.patch(`/customers/orders/${id}/`, data)
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(['order', id])
        queryClient.invalidateQueries(['orderLocations', id])
        return (
          items &&
          toast({
            position: 'bottom-right',
            title: items.success.title,
            description: items.success.description,
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
        )
      },
      onError: async () => {
        return (
          items &&
          toast({
            position: 'bottom-right',
            title: items.error.title,
            description: items.error.description,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        )
      },
    }
  )
}

export const usePostOrder = (items, id) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  return useMutation(
    ['postOrder', id],
    async (data) => {
      return await API.post(`/customers/orders/${id ? id + '/' : ''}`, data)
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('customer')
        return (
          items &&
          toast({
            position: 'bottom-right',
            title: items.success.title,
            description: items.success.description,
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
        )
      },
      onError: async () => {
        return (
          items &&
          toast({
            position: 'bottom-right',
            title: items.error.title,
            description: items.error.description,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        )
      },
    }
  )
}

export const useDeleteOrder = (items, id, callback) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  return useMutation(
    ['deleteOrder', id],
    async () => {
      return await API.delete(`/customers/orders/${id}`)
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(['order', id])
        callback()
        return (
          items &&
          toast({
            position: 'bottom-right',
            title: items.success.title,
            description: items.success.description,
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
        )
      },
      onError: async () => {
        return (
          items &&
          toast({
            position: 'bottom-right',
            title: items.error.title,
            description: items.error.description,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        )
      },
    }
  )
}

export const useUnassignSensor = (
  items,
  orderId,
  sensorId,
  assignmentStart
) => {
  const formatted_ship_start =
    new Date(assignmentStart).getUTCFullYear() +
    '-' +
    (new Date(assignmentStart).getUTCMonth() + 1) +
    '-' +
    new Date(assignmentStart).getUTCDate() +
    'T' +
    new Date(assignmentStart).getUTCHours() +
    ':' +
    new Date(assignmentStart).getUTCMinutes() +
    ':' +
    new Date(assignmentStart).getUTCSeconds() +
    'Z'
  const patchedSensorReadings = usePatchSensorReadings(
    orderId,
    sensorId,
    formatted_ship_start,
    ''
  )
  const toast = useToast()
  return useMutation(
    ['unassignSensor', orderId],
    async (data) => {
      return await API.post(
        `/customers/orders/${orderId}/unassign/${sensorId}/`,
        data
      )
    },
    {
      onSuccess: async (resp) => {
        const formatted_ship_end =
          new Date(resp['data']['data'].assigment_end).getUTCFullYear() +
          '-' +
          (new Date(resp['data']['data'].assigment_end).getUTCMonth() + 1) +
          '-' +
          new Date(resp['data']['data'].assigment_end).getUTCDate() +
          'T' +
          new Date(resp['data']['data'].assigment_end).getUTCHours() +
          ':' +
          new Date(resp['data']['data'].assigment_end).getUTCMinutes() +
          ':' +
          new Date(resp['data']['data'].assigment_end).getUTCSeconds() +
          'Z'
        patchedSensorReadings.mutate({ ship_end: formatted_ship_end })
        return (
          items &&
          toast({
            position: 'bottom-right',
            title: items.success.title,
            description: items.success.description,
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
        )
      },
      onError: async () => {
        return (
          items &&
          toast({
            position: 'bottom-right',
            title: items.error.title,
            description: items.error.description,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        )
      },
    }
  )
}

export const useAssignSensor = (items, orderId) => {
  const postedSensorReadings = usePostSensorReadings(orderId)
  const toast = useToast()
  return useMutation(
    ['assignSensor', orderId],
    async (data) => {
      return await API.post(
        `/customers/orders/${orderId}/assign/${data.sensorId}/${
          data.sensorInfo ? '?info=' + data.sensorInfo : ''
        }${'&ibc=' + data.ibcTypeId}`
      )
    },
    {
      onSuccess: async (resp) => {
        const formatted_ship_start =
          new Date(resp['data']['data'].assigment_start).getUTCFullYear() +
          '-' +
          (new Date(resp['data']['data'].assigment_start).getUTCMonth() + 1) +
          '-' +
          new Date(resp['data']['data'].assigment_start).getUTCDate() +
          'T' +
          new Date(resp['data']['data'].assigment_start).getUTCHours() +
          ':' +
          new Date(resp['data']['data'].assigment_start).getUTCMinutes() +
          ':' +
          new Date(resp['data']['data'].assigment_start).getUTCSeconds() +
          'Z'
        postedSensorReadings.mutate({
          sensor_id: resp['data']['data'].sensor_id,
          ship_start: formatted_ship_start,
        })
        return (
          items &&
          toast({
            position: 'bottom-right',
            title: items.success.title,
            description: items.success.description,
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
        )
      },
      onError: async () => {
        return (
          items &&
          toast({
            position: 'bottom-right',
            title: items.error.title,
            description: items.error.description,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        )
      },
    }
  )
}

export const useReassignSensor = (
  items,
  orderId,
  sensorId,
  assignmentStart,
  assignmentEnd
) => {
  const formatted_ship_start =
    new Date(assignmentStart).getUTCFullYear() +
    '-' +
    (new Date(assignmentStart).getUTCMonth() + 1) +
    '-' +
    new Date(assignmentStart).getUTCDate() +
    'T' +
    new Date(assignmentStart).getUTCHours() +
    ':' +
    new Date(assignmentStart).getUTCMinutes() +
    ':' +
    new Date(assignmentStart).getUTCSeconds() +
    'Z'
  const formatted_ship_end =
    new Date(assignmentEnd).getUTCFullYear() +
    '-' +
    (new Date(assignmentEnd).getUTCMonth() + 1) +
    '-' +
    new Date(assignmentEnd).getUTCDate() +
    'T' +
    new Date(assignmentEnd).getUTCHours() +
    ':' +
    new Date(assignmentEnd).getUTCMinutes() +
    ':' +
    new Date(assignmentEnd).getUTCSeconds() +
    'Z'
  const patchedSensorReadings = usePatchSensorReadings(
    orderId,
    sensorId,
    formatted_ship_start,
    formatted_ship_end
  )
  const toast = useToast()
  return useMutation(
    ['reassignSensor', orderId],
    async (data) => {
      await API.post(`/customers/orders/${orderId}/reassign/${data.sensorId}/`)
    },
    {
      onSuccess: async () => {
        patchedSensorReadings.mutate({ ship_end: '' })
        return toast({
          position: 'bottom-right',
          title: items.success.title,
          description: items.success.description,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      },
      onError: async () => {
        return toast({
          position: 'bottom-right',
          title: items.error.title,
          description: items.error.description,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    }
  )
}
