import { withRouter } from 'react-router-dom'
import { Box, Center, Heading } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Card } from '../components/Login/Card'
import { LoginForm } from '../components/Login/LoginForm'
import { Logo } from '../components/Logo'
import { loginUser } from '../services/auth'
import { useAuth } from '../context/useAuth'
import { useTranslation } from 'react-i18next'
import { getAccessToken, getIdToken, setTokens } from '../utils/common'
import DocumentTitle from 'react-document-title'

const LoginPage = (props) => {
  const { t } = useTranslation()
  const [error, setError] = useState(null)
  const { setAuthToken, setIdToken } = useAuth()
  const handleSubmit = (username, password) => {
    try {
      loginUser(username, password)
        .then((result) => {
          if (result.status === 200) {
            setTokens(result)
            setAuthToken(getAccessToken())
            setIdToken(getIdToken())
            props.history.push('/')
          }
        })
        .catch((e) => {
          if (e && e.data) setError(e.data)
        })
    } catch (error) {
      console.log('Invalid username or password')
    }
  }

  return (
    <DocumentTitle title={'Vitreo - ' + t('login.loginDocTitle')}>
      <Center {...props}>
        <Box maxW='md' mx='auto'>
          <Center>
            <Logo mx='auto' h='20' color='tertiary' />
          </Center>
          <Heading
            data-testid='headingLogin'
            color=''
            textAlign='center'
            size='xl'
            mt='10'
            fontWeight='extrabold'
          >
            {t('login.login.signInHeading')}
          </Heading>
          <Card>
            <LoginForm submitHandler={handleSubmit} err={error} />
          </Card>
        </Box>
      </Center>
    </DocumentTitle>
  )
}

export default withRouter(LoginPage)
