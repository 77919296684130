import axios from 'axios'
import {
  interceptorAPIPublicRequestHandler,
  responseErrorApiPublicHandler,
  responseSuccessHandler,
} from './common'

const DOMAIN_API_LINK = process.env.REACT_APP_DOMAIN_API_URL

const axiosInstance = axios.create({
  baseURL: DOMAIN_API_LINK,
  responseType: 'json',
})

/*Add request interceptor for initial expired session check*/
axiosInstance.interceptors.request.use((request) =>
  interceptorAPIPublicRequestHandler(request)
)

/* Response interceptor is added for domain apis to refresh token on receiving token expired error
 * It will catch each apis response and after refreshing the token, it will fire the request again
 * So the pages can have refreshed data
 * */
axiosInstance.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorApiPublicHandler(error)
)

export default axiosInstance
