import React, { useEffect, useState } from 'react'
import { AuthContext } from '../context/useAuth'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import LoginPage from '../pages/login'
import ForgotPassword from '../pages/forgot-password'
import { PrivateRoute } from './PrivateRoute'
import { ChakraProvider } from '@chakra-ui/react'
import theme from '../theme/brandTheme.js'
import { Sidebar as NavMenu } from '../components/Sidebar/Sidebar'
import { Container, Box } from '@chakra-ui/react'
import Breadcrumb from '../components/Breadcrumb'
import {
  BiHome,
  BiPulse,
  BiLogOut,
  BiCog,
  BiHelpCircle,
  BiFile,
} from 'react-icons/bi'
import OrderOverview from '../pages/order-overview'
import SensorDetail from '../pages/sensor-detail'
import Help from '../pages/help'
import Terms from '../pages/terms'
import SensorQrCode from '../pages/sensor-qr-code'
import Dashboard from '../pages/dashboard'
import MySensors from '../pages/my-sensors'
import Settings from '../pages/settings'
import { useTranslation } from 'react-i18next'
import { logoutUser } from '../services/auth'
import { getAccessToken } from '../utils/common'
import DocumentTitle from 'react-document-title'
import gtag from 'ga-gtag'

const defaultProps = {
  container: {
    pt: [0, 0, 10],
    pb: [40, 40, 40],
    px: [10, 10, 20],
    maxW: ['xl', '8xl'],
  },
  center: {
    bg: 'primaryLightBackground',
    minH: '100vh',
    py: '12',
    px: {
      base: '4',
      lg: '8',
    },
  },
}

function App() {
  const { t } = useTranslation()
  const accessToken = getAccessToken()
  const idToken1 = getAccessToken()
  const [authToken, setAuthToken] = useState(accessToken)
  const [idToken, setIdToken] = useState(idToken1)
  const setTokens = (data) => setAuthToken(data)
  const setIdTokens = (data) => setIdToken(data)

  const params = new URL(window.location.href).searchParams

  useEffect(() => {
    if (
      params.get('utm_source') &&
      params.get('utm_campaign') &&
      params.get('utm_medium')
    ) {
      const utmSource = params.get('utm_source')
      const utmCampaign = params.get('utm_campaign')
      const utmMedium = params.get('utm_medium')
      console.log('app source', utmSource)
      console.log('app campagn', utmCampaign)
      console.log('app medium', utmMedium)
      gtag(
        'event',
        `source ${utmSource} + campaign ${utmCampaign} + medium ${utmMedium}`
      )
    }
  }, [params])

  const routes = [
    {
      path: `/dashboard/order/:orderID`,
      pathTo: '/',
      name: t('orderOverview.pageTitle'),
      Component: OrderOverview,
      showID: false,
      useOrderIDParam: false,
    },
    {
      path: `/dashboard/order/:orderID/sensor/:id`,
      pathTo: '/dashboard/order/:orderID',
      name: t('sensorDetail.pageTitle'),
      Component: SensorDetail,
      showID: true,
      useOrderIDParam: true,
    },
    {
      path: `/my-sensors/order/:orderID`,
      pathTo: '/my-sensors',
      name: t('orderOverview.pageTitle'),
      Component: OrderOverview,
      showID: false,
      useOrderIDParam: false,
    },
    {
      path: `/my-sensors/sensor/:id`,
      pathTo: '/my-sensors',
      name: t('sensorDetail.pageTitle'),
      Component: SensorDetail,
      showID: true,
      useOrderIDParam: false,
    },
  ]

  const directory = [
    {
      text: t('dashboard.pageTitle'),
      link: '/',
      icon: <BiHome />,
      id: 'side_dashboard',
    },
    {
      text: t('sensors.pageTitle'),
      link: '/my-sensors',
      icon: <BiPulse />,
      id: 'side_sensors',
    },
    {
      text: t('settings.pageTitle'),
      link: '/settings',
      icon: <BiCog />,
      align: 'end',
      id: 'side_settings',
    },
    {
      text: t('help.pageTitle'),
      link: '/help',
      icon: <BiHelpCircle />,
      align: 'end',
      id: 'side_help',
    },
    {
      text: t('terms.pageTitle'),
      link: '/terms',
      icon: <BiFile />,
      align: 'end',
      id: 'side_terms',
    },
    {
      text: t('tour.startTour'),
      icon: <BiFile />,
      link: '/docs?clickItem=true',
      align: 'end',
      id: 'star_tour',
    },
    {
      text: t('logout.pageTitle'),
      link: '/login',
      icon: <BiLogOut />,
      align: 'end',
      itemHandler: logoutUser,
      id: 'side_logout',
    },
  ]

  const currentPageLink = window.location.pathname
  const currentPageIndex = directory.findIndex(function (obj) {
    return obj.link === currentPageLink
  })

  return (
    <ChakraProvider theme={theme}>
      <AuthContext.Provider
        value={{
          authToken,
          idToken,
          setAuthToken: setTokens,
          setIdToken: setIdTokens,
        }}
      >
        <DocumentTitle>
          <Router>
            <Switch>
              <Route
                path='/login'
                exact
                render={() => <LoginPage {...defaultProps.center} />}
              />
              <Route
                path='/forgot-password'
                exact
                render={() => <ForgotPassword {...defaultProps.center} />}
              />
              <Route
                sensitive={false}
                path='/my-sensors/sensor/id/:id'
                exact
                render={(props) => (
                  <NavMenu
                    sensitive
                    directory={[]}
                    index={currentPageIndex}
                    app={
                      <Container {...defaultProps.container}>
                        <Box {...defaultProps.box}>
                          <Redirect
                            to={`${props.location.pathname.toLowerCase()}`}
                          />
                          <SensorQrCode />
                        </Box>
                      </Container>
                    }
                  />
                )}
              />
              <Route
                path='/legal'
                exact
                render={() => (
                  <Container {...defaultProps.container}>
                    <Box {...defaultProps.box}>
                      <Terms {...defaultProps.center} />
                    </Box>
                  </Container>
                )}
              />
              {authToken ? (
                <NavMenu
                  directory={directory}
                  index={currentPageIndex}
                  app={
                    <Container {...defaultProps.container}>
                      <Box {...defaultProps.box}>
                        {authToken ? (
                          <>
                            {routes.map(({ path, Component }, key) => (
                              <Route
                                exact
                                path={path}
                                key={key}
                                render={(props) => {
                                  const crumbs = routes.map(
                                    ({ path, ...rest }) => ({
                                      path: Object.keys(props.match.params)
                                        .length
                                        ? Object.keys(
                                            props.match.params
                                          ).reduce(
                                            (path, param) =>
                                              path.replace(
                                                `:${param}`,
                                                props.match.params[param]
                                              ),
                                            path
                                          )
                                        : path,
                                      ...rest,
                                    })
                                  )
                                  return (
                                    <>
                                      <Breadcrumb list={crumbs} />
                                      <Box mt={16}>
                                        <Component {...props} />
                                      </Box>
                                    </>
                                  )
                                }}
                              />
                            ))}
                          </>
                        ) : (
                          <Redirect to={{ pathname: '/login' }} />
                        )}
                        <PrivateRoute path='/' exact component={Dashboard} />
                        <PrivateRoute
                          path='/my-sensors'
                          exact
                          component={MySensors}
                        />
                        <PrivateRoute path='/help' exact component={Help} />
                        <PrivateRoute
                          path='/docs'
                          exact
                          component={Dashboard}
                        />
                        <PrivateRoute path='/terms' exact component={Terms} />
                        <PrivateRoute
                          path='/settings'
                          exact
                          component={Settings}
                        />
                      </Box>
                    </Container>
                  }
                />
              ) : (
                <Redirect to={{ pathname: '/login' }} />
              )}
            </Switch>
          </Router>
        </DocumentTitle>
      </AuthContext.Provider>
    </ChakraProvider>
  )
}

export default App
