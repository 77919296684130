import React from 'react'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/airbnb.css'
import '../css/FlatPickr.css'
import { useTranslation } from 'react-i18next'
import { German } from 'flatpickr/dist/l10n/de.js'

const FlatPickr = React.forwardRef((props, ref) => {
  const { i18n } = useTranslation()
  return (
    <Flatpickr
      ref={ref}
      {...props}
      options={{
        maxDate: '01.01.2999',
        time_24hr: true,
        locale:
          i18n.language === 'de'
            ? {
                ...German,
              }
            : '',
        ...props.options,
      }}
    />
  )
})

export { FlatPickr }
