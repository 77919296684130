import React, { useState } from 'react'
import {
  Button,
  chakra,
  FormControl,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react'
import { PasswordField } from './PasswordField'
import { ErrorHandler } from '../ErrorHandler'
import { useTranslation } from 'react-i18next'

export const LoginForm = (props) => {
  const { t } = useTranslation()
  const [email, setEmail] = useState()
  const [pwd, setPwd] = useState()

  return (
    <chakra.form
      onSubmit={(e) => {
        e.preventDefault()
        props.submitHandler(email, pwd)
      }}
    >
      <Stack spacing='6'>
        <FormControl id='username'>
          <FormLabel>{t('login.login.username')}</FormLabel>
          <Input
            name='username'
            required
            onChange={(event) => setEmail(event.currentTarget.value)}
          />
        </FormControl>
        <PasswordField forgotpassword={'true'} onChangeHandler={setPwd} />
        {props.err && <ErrorHandler error={props.err} />}
        <Button type='submit' variant='primary'>
          {t('login.login.login')}
        </Button>
      </Stack>
    </chakra.form>
  )
}
