import API from '../utils/api'
import { useQuery } from 'react-query'

export const removeTokens = () => {
  return new Promise((resolve, reject) => {
    try {
      localStorage.clear()
      resolve({
        message: 'Tokens removed!',
      })
    } catch (e) {
      reject(e)
    }
  })
}

//Register a new user account
export const registerUser = (userData) => {
  return new Promise(async (resolve, reject) => {
    API.post('register', userData)
      .then((res) => {
        resolve(res)
      })
      .catch((e) => {
        reject(e.response)
      })
  })
}

//Log the user out
export const logoutUser = () => removeTokens().catch((e) => console.log(e))

//Log the user in with the entered credentials and get refresh/access/id tokens in response
export const loginUser = (username, password) => {
  const body = {
    username: username,
    password: password,
  }

  return new Promise(async (resolve, reject) => {
    API.post('auth', body)
      .then((res) => {
        resolve(res)
      })
      .catch((e) => {
        if (e.response) reject(e.response)
        else reject()
      })
  })
}

//Get Reset Password Link on E mail
export const resetPassword = (username) => {
  const body = {
    username: username,
  }

  return new Promise(async (resolve, reject) => {
    API.post('forgot_password', body)
      .then((res) => {
        resolve(res)
      })
      .catch((e) => {
        reject(e.response)
      })
  })
}

//Get Reset Password Link on E mail
export const changePassword = (username, code, password) => {
  const body = {
    username: username,
    password: password,
    code: code,
  }

  return new Promise(async (resolve, reject) => {
    API.post('confirm_forgot_password', body)
      .then((res) => {
        resolve(res)
      })
      .catch((e) => {
        reject(e.response)
      })
  })
}

export const useProfile = () => {
  return (
    useQuery({
      queryKey: 'profile',
      queryFn: getProfile,
    }) ?? []
  )
}

const getProfile = async () => {
  return await API.get('profile')
    .then((d) => {
      return d.data && d.data.profile
    })
    .catch((error) => console.log('error', error))
}
