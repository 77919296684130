import API from '../utils/apiAwsGateway'
import API_DATA_READING from '../utils/apiDataReadingsAwsGateway'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

export const useGetDeviceShadow = (id) => {
  return (
    useQuery(['getDeviceShadow', id], async () => {
      return await API.get(`/device_shadow/?device_id=${id}`)
        .then((d) => {
          return d.data
        })
        .catch((error) => error)
    }) ?? []
  )
}

export const usePatchDeviceShadow = (items, id) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  return useMutation(
    ['patchDeviceShadow', id],
    async (data) => {
      return await API.patch(`/device_shadow/?device_id=${id}`, data)
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(['getDeviceShadow', id])
        return (
          items &&
          toast({
            position: 'bottom-right',
            title: items.success.title,
            description: items.success.description,
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
        )
      },
      onError: async () => {
        return (
          items &&
          toast({
            position: 'bottom-right',
            title: items.error.title,
            description: items.error.description,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        )
      },
    }
  )
}
export const getSensorReadings1 = async (
  sensor_id,
  shipment_start,
  shipment_end,
  startDate,
  endDate
) => {
  return await API_DATA_READING.get(
    `/data-readings/${sensor_id}/?ship_start=${shipment_start}&ship_end=${shipment_end}&start_date=${startDate}&end_date=${endDate}`
  )
    .then((d) => {
      return { sensor_id: sensor_id, data: d.data }
    })
    .catch((error) => error)
}
export const usePostSensorReadings = (orderId) => {
  const queryClient = useQueryClient()
  return useMutation(
    ['addSensorReadings'],
    async (data) => {
      return await API_DATA_READING.post(
        `/data-readings/${data.sensor_id}`,
        data
      )
    },
    {
      onSuccess: async () => {
        console.log('success add sensor readings')
        queryClient.invalidateQueries(['order', orderId])
        queryClient.invalidateQueries(['sensorUnassigned', orderId])
        queryClient.invalidateQueries(['orderReadings', orderId])
        queryClient.invalidateQueries(['orderLocations', orderId])
      },
      onError: async () => {
        console.log('error add sensor readings')
      },
    }
  )
}
export const usePatchSensorReadings = (
  order_id,
  sensor_id,
  ship_start,
  ship_end
) => {
  const queryClient = useQueryClient()
  return useMutation(
    ['patchSensorReadings', sensor_id],
    async (data) => {
      return await API_DATA_READING.patch(
        `/data-readings/${sensor_id}/?ship_start=${ship_start}&ship_end=${ship_end}`,
        data
      )
    },
    {
      onSuccess: async () => {
        console.log('success patch sensor readings', order_id)
        queryClient.invalidateQueries(['order', order_id])
        queryClient.invalidateQueries(['sensorUnassigned', order_id])
        queryClient.invalidateQueries(['orderReadings', order_id])
        queryClient.invalidateQueries(['orderLocations', order_id])
      },
      onError: async () => {
        console.log('error patch sensor readings')
      },
    }
  )
}
