import React, { useState, useEffect } from 'react'
import { Line, ResponsiveLine } from '@nivo/line'
import brandColors from '../theme/brandColors.json'
import { AutoSizer } from 'react-virtualized'
import {
  Heading,
  Box,
  Grid,
  Center,
  Progress,
  Flex,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import graphColors from '../theme/graphColors.json'
import { useTranslation } from 'react-i18next'
import '../css/ReactMapGL.css'

const isEqual = require('react-fast-compare')

const LineGraphNivoPublic = React.memo((props) => {
  const { t } = useTranslation()
  const colorMode = brandColors.primaryText
  const widthThreshold = props.widthThreshold
  const tickValuesY =
    typeof props.tickValuesY === 'number' ? props.tickValuesY : 10
  const validData = Array.isArray(props.data) && props.data.length > 0
  const [data, setData] = useState()
  const [dataReady, setDataReady] = useState(false)
  const [isEmptyData, setIsEmptyData] = useState(false)
  const [hiddenIDs, setHiddenIDs] = useState([])
  const nullData =
    props.data &&
    props.data.map((data) => ({
      id: data.id,
      data: data.data.map((val) => ({ x: null, y: val.y })),
    }))

  useEffect(() => {
    if (props.data) {
      setData(
        props.data.map((data) => ({
          id: data.id,
          tooltipSymbolY: data.tooltipSymbolY,
          yHeading: data.yHeading,
          leftAxisLegend: data.leftAxisLegend,
          rightAxisLegend: data.rightAxisLegend,
          yScale: data.yScale,
          data: hiddenIDs.includes(data.id)
            ? data.data.map((val) => ({
                x:
                  props.type === 'timeSeries' && typeof props.type === 'string'
                    ? val.x.slice(0, 19)
                    : val.x,
                y: null,
              }))
            : data.data.map((val) => ({
                x:
                  props.type === 'timeSeries' && typeof props.type === 'string'
                    ? val.x.slice(0, 19)
                    : val.x,
                y: val.y,
              })),
        }))
      )
      setDataReady(true)

      setIsEmptyData(
        props.data.every(({ data }) => Object.entries(data).length === 0)
      )
    }
  }, [hiddenIDs, props.data, props.type, validData])

  const NoDataErrorMarkup = ({ msg }) => (
    <Center
      h='full'
      w='full'
      color='gray'
      justifyContent='center'
      alignItems='center'
    >
      {msg ? msg : props.errorNoData}
    </Center>
  )
  console.log('line data', data)

  return validData ? (
    <>
      {dataReady ? (
        <>
          {props.data.length <= 0 ? (
            <NoDataErrorMarkup msg={t('emptyData')} />
          ) : !props.isDatePickerUsed && isEmptyData ? (
            <NoDataErrorMarkup msg={t('emptyData')} />
          ) : props.isDatePickerUsed && isEmptyData ? (
            <NoDataErrorMarkup msg={t('dataCollectionMsg')} />
          ) : (
            <Box h={'20rem'}>
              <AutoSizer>
                {({ height, width }) => (
                  <>
                    <div className={'graphContainer'}>
                      <ResponsiveLine
                        data={data.slice(0, 2)}
                        width={width > widthThreshold ? width + 20 : width + 40}
                        height={height - 30}
                        margin={{
                          top: 30,
                          right: 80,
                          bottom: 50,
                          left: 40,
                        }}
                        enableGridY={false}
                        xScale={props.xScale}
                        xFormat={props.xFormat}
                        yScale={{
                          type: 'linear',
                          min: 'auto',
                          max: 'auto',
                        }}
                        axisRight={null}
                        axisLeft={{
                          orient: 'left',
                          tickValues:
                            typeof props.tickValuesY === 'number'
                              ? props.tickValuesY
                              : tickValuesY,
                          legend:
                            data[0].tooltipSymbolY +
                            ' / ' +
                            data[1].tooltipSymbolY,
                          legendOffset: -35,
                          legendPosition: 'middle',
                        }}
                        axisTop={null}
                        axisBottom={null}
                        curve='linear'
                        lineWidth={1.5}
                        enableCrosshair={true}
                        colors={graphColors}
                        enablePoints={false}
                        pointSize={5}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabel='tempearture'
                        pointLabelYOffset={-12}
                        theme={{
                          textColor: colorMode,
                          axis: {
                            ticks: {
                              text: {
                                fill: colorMode,
                              },
                            },
                            legend: {
                              text: {
                                fill: colorMode,
                              },
                            },
                          },
                        }}
                        legends={[
                          {
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 20000,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemTextColor: '#FFFFFF',
                            itemWidth: 0,
                            itemHeight: 0,
                            itemOpacity: 0,
                            symbolSize: 0,
                            symbolShape: 'circle',
                            symbolBorderColor: 'white',
                          },
                        ]}
                      />
                    </div>
                    <div className={'secondGraph'}>
                      <ResponsiveLine
                        data={data}
                        width={width > widthThreshold ? width + 20 : width + 40}
                        height={height - 30}
                        margin={{
                          top: 30,
                          right: 87,
                          bottom: 50,
                          left: 35,
                        }}
                        enableGridY={false}
                        xScale={props.xScale}
                        xFormat={props.xFormat}
                        yScale={{
                          type: 'linear',
                          min: 'auto',
                          max: 'auto',
                        }}
                        axisRight={{
                          orient: 'right',
                          tickValues: 4,
                          legend: data[2].tooltipSymbolY,
                          legendOffset: 50,
                          legendPosition: 'middle',
                        }}
                        axisLeft={null}
                        axisTop={null}
                        axisBottom={{
                          tickPadding: 0,
                          tickMargin: 0,
                          orient: 'bottom',
                          legend: props.axisBottomLegend + props.tooltipXSymbol,
                          format: props.axisBottomFormat,
                          tickValues:
                            typeof props.tickValuesX === 'number'
                              ? props.tickValuesX
                              : width > widthThreshold * 0.5
                              ? 6
                              : 1,
                          tickRotation: '0',
                          legendOffset: 30,
                          legendPosition: 'middle',
                        }}
                        curve='linear'
                        lineWidth={1.5}
                        enableCrosshair={true}
                        colors={[
                          'rgba(255, 255, 255, 0)',
                          'rgba(255, 255, 255, 0)',
                          graphColors[2],
                        ]}
                        enablePoints={false}
                        pointSize={5}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabel='tempearture'
                        pointLabelYOffset={-12}
                        theme={{
                          textColor: colorMode,
                          axis: {
                            ticks: {
                              text: {
                                fill: colorMode,
                              },
                            },
                            legend: {
                              text: {
                                fill: colorMode,
                              },
                            },
                          },
                        }}
                        useMesh={true}
                        enableSlices={'x'}
                        sliceTooltip={({ slice }) => {
                          return (
                            <Flex
                              w={'12rem'}
                              bg={mode(
                                'primaryLightBackground',
                                'primaryDarkBackground'
                              )}
                              opacity='50'
                              fontSize='xs'
                              py={2}
                              fontWeight='bold'
                              border='1px'
                              borderRadius={'5px'}
                              alignItems='center'
                              color={colorMode}
                            >
                              <Flex w='0.5rem' h='0.5rem' mx='0.5rem' />
                              <Flex flexDirection='column'>
                                <Flex flexDirection='row'>
                                  <Box>
                                    {slice.points[0].data.xFormatted +
                                      props.tooltipXSymbol}
                                  </Box>
                                </Flex>
                                {slice.points.map((point) => (
                                  <Flex flexDirection='row'>
                                    <Box
                                      fontWeight='extrabold'
                                      mr='0.25rem'
                                      color={
                                        point.serieId === 'temperature'
                                          ? graphColors[0]
                                          : point.serieId === 'humidity'
                                          ? graphColors[1]
                                          : graphColors[2]
                                      }
                                    >
                                      {point.serieId === 'temperature'
                                        ? data[0].yHeading
                                        : point.serieId === 'humidity'
                                        ? data[1].yHeading
                                        : data[2].yHeading}
                                    </Box>
                                    <Box>
                                      {point.data.yFormatted}
                                      {point.serieId === 'temperature'
                                        ? data[0].tooltipSymbolY
                                        : point.serieId === 'humidity'
                                        ? data[1].tooltipSymbolY
                                        : data[2].tooltipSymbolY}
                                    </Box>
                                  </Flex>
                                ))}
                              </Flex>
                            </Flex>
                          )
                        }}
                        legends={[
                          {
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 20000,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemTextColor: '#FFFFFF',
                            itemWidth: 0,
                            itemHeight: 0,
                            itemOpacity: 0,
                            symbolSize: 0,
                            symbolShape: 'circle',
                            symbolBorderColor: 'white',
                          },
                        ]}
                      />
                    </div>
                    <Grid justifyItems='center' zIndex={10}>
                      <Box justifySelf='center'>
                        <Heading fontWeight='base' size='sm'>
                          {props.title}
                        </Heading>
                      </Box>
                      <Line
                        data={nullData}
                        width={width + 60}
                        height={height + 90}
                        margin={{
                          top: 40,
                          right: width > widthThreshold ? 165 : 80,
                          bottom: 200,
                          left: width > widthThreshold ? 70 : 70,
                        }}
                        axisBottom={null}
                        axisLeft={null}
                        enableGridX={false}
                        enableGridY={false}
                        legends={[
                          {
                            itemTextColor: colorMode,
                            data: Object.entries(data).map((items, index) => ({
                              label: items[1].yHeading,
                              id: items[1].id,
                              color: hiddenIDs.includes(items[1].id)
                                ? '#EBEEF2'
                                : props.offsetColors === undefined
                                ? graphColors[index]
                                : graphColors[props.offsetColors + index],
                            })),
                            anchor: 'bottom-left',
                            onClick: (name) => {
                              if (hiddenIDs.includes(name.id)) {
                                setHiddenIDs(
                                  hiddenIDs.filter((id) => id !== name.id)
                                )
                              } else {
                                setHiddenIDs([...hiddenIDs, name.id])
                              }
                            },
                            direction: 'row',
                            justify: false,
                            translateX: -70,
                            translateY: 100,
                            itemsSpacing: 60,
                            itemDirection: 'left-to-right',
                            itemWidth: 40,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 10,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                              {
                                on: 'hover',
                                style: {
                                  symbolSize: 15,
                                },
                              },
                            ],
                          },
                        ]}
                      />
                    </Grid>
                  </>
                )}
              </AutoSizer>
            </Box>
          )}
        </>
      ) : (
        <Progress colorScheme='teal' size='xs' isIndeterminate />
      )}
    </>
  ) : (
    <NoDataErrorMarkup />
  )
})

export default React.memo(LineGraphNivoPublic, isEqual)
