import React, { useState } from 'react'
import Tour from 'reactour'
import { useHistory } from 'react-router-dom'
import '../css/WebTour.css'
import { useTranslation } from 'react-i18next'

const stepStyle = {
  color: '#666',
  background: '#dff9fb',
}
function OrdersWebTourSecond() {
  const [isTourOpen, setIsTourOpen] = useState(true)
  const history = useHistory()
  const { t } = useTranslation()
  const steps = [
    {
      action: () => {
        const pathname = window.location.pathname
        if (pathname === '/') {
          history.push({
            pathname: '/my-sensors',
            search: '?fromDashboardReverse=True',
          })
        }
      },
    },
    {
      selector: '#addCustomer',
      content: t('tour.step4'),
      style: stepStyle,
    },
  ]

  return (
    <Tour
      steps={steps}
      startAt={1}
      isOpen={isTourOpen}
      rounded={5}
      stepInteraction={true}
      maskClassName='mask'
      accentColor='#269faf'
      onRequestClose={() => setIsTourOpen(false)}
      badgeContent={(curr, tot) => `${curr + 2} of ${tot + 2}`}
      showNavigation={false}
    />
  )
}

export default OrdersWebTourSecond
