import React from 'react'
import { Link as ReactRouterLink, withRouter } from 'react-router-dom'
import { Flex, Box, Center, Heading, Text, Link } from '@chakra-ui/react'
import { useTranslation, Trans } from 'react-i18next'
import { Logo } from '../components/Logo'
import DocumentTitle from 'react-document-title'

const ForgotPassword = (props) => {
  const { t } = useTranslation()

  return (
    <DocumentTitle title={'Vitreo - ' + t('login.forgotPasswordTitle')}>
      <Center {...props}>
        <Box maxW='md' mx='auto'>
          <Center>
            <Logo mx='auto' h='20' color='tertiary' />
          </Center>
          <Heading
            textAlign='center'
            size='xl'
            mt='10'
            mb='10'
            fontWeight='extrabold'
          >
            {t('login.resetPassword.resetHeader')}
          </Heading>
          <Flex mt='8' direction={'column'}>
            <Text align='center' maxW='md' fontWeight='medium'>
              <Trans
                i18nKey='login.resetSuccess.contactAdminMsg'
                t={t}
                components={[
                  <Link href='mailto:service@vitreo.me'>
                    service@vitreo.me
                  </Link>,
                ]}
              />
            </Text>
            <Center mt='4'>
              <Link data-testid='back' as={ReactRouterLink} to={'/login'}>
                {t('login.resetPassword.back')}
              </Link>
            </Center>
          </Flex>
        </Box>
      </Center>
    </DocumentTitle>
  )
}

export default withRouter(ForgotPassword)
