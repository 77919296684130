export const mapsensorsUnassigned = (unassignedSensors) => {
  return (
    unassignedSensors.isSuccess &&
    unassignedSensors.data &&
    unassignedSensors.data.map((items) => ({
      id: items.device_id,
      label: items.device_id_short,
    }))
  )
}

export const mapOrderLocations = (orderLocations, startDate, endDate) => {
  if (orderLocations.isSuccess && orderLocations.data) {
    const locations1 = Object.values(orderLocations.data).slice(0)
    return locations1.map((obj) => {
      let locations2 = obj['locations'].slice(0)
      locations2 = locations2.filter((item) => {
        return (
          startDate &&
          endDate &&
          new Date(item.time).getTime() >= startDate.getTime() &&
          new Date(item.time).getTime() <= endDate.getTime()
        )
      })
      if (locations2.length > 1) {
        const locations_with_cell = locations2.filter((l) => l['source'])
        if (locations_with_cell && locations_with_cell.length) {
          const first_cell_row = locations_with_cell[0]
          const last_cell_row = locations_with_cell.slice(-1)[0]
          locations2 = locations2.filter((item) => {
            return (
              new Date(item.time).getTime() >=
                new Date(first_cell_row['time']).getTime() &&
              new Date(item.time).getTime() <=
                new Date(last_cell_row['time']).getTime()
            )
          })
        } else {
          locations2 = []
        }
      }

      return { ...obj, locations: locations2 }
    })
  }
}

export const mapSensorLocations = (sensorData) => {
  if (sensorData.isSuccess && sensorData.data) {
    return [sensorData.data]
  }
}

//map data to show temperature or humidity graph based on key
export const graphDataMapped = (orderReadings, key) =>
  orderReadings &&
  orderReadings.map((item) => {
    return {
      name: item['sensor_id'],
      data: item['data']
        .filter((f) => {
          return f[key]
        })
        .map((items2) => {
          return [parseInt(items2.ts), parseFloat(items2[key])]
        })
        .sort(),
      visible: !!item['data'].filter((f) => {
        return f[key]
      }).length,
    }
  })

//map sensor data to show temperature or humidity graph based on key
export const graphSensorDataMapped = (sensorReadings, key, id) => {
  let graphArr = []
  sensorReadings &&
    sensorReadings.isSuccess &&
    sensorReadings.data &&
    typeof sensorReadings.data === 'object' &&
    Array.isArray(sensorReadings.data.data) &&
    graphArr.push({
      name: id,
      data: sensorReadings.data.data
        .filter((f) => {
          return f[key]
        })
        .map((items2) => {
          return [parseInt(items2.ts) * 1000, parseFloat(items2[key])]
        })
        .sort(),
      visible: !!sensorReadings.data.data.filter((f) => {
        return f[key]
      }).length,
    })
  return graphArr
}

//map sensor data to show temperature or humidity graph based on key
export const graphPublicSensorDataMapped = (
  sensorReadings,
  id,
  temperatureLabel,
  humidityLabel,
  lightLabel
) => {
  let graphArr = []
  sensorReadings &&
    sensorReadings.isSuccess &&
    sensorReadings.data &&
    typeof sensorReadings.data === 'object' &&
    Array.isArray(sensorReadings.data) &&
    ['temperature', 'humidity', 'amb_light'].forEach((sp, index) => {
      graphArr.push({
        id: sp,
        tooltipSymbolY:
          index === 0 ? ' \u{00B0}C' : index === 1 ? ' % r.H.' : ' lux ',
        yHeading:
          index === 0
            ? temperatureLabel
            : index === 1
            ? humidityLabel
            : lightLabel,
        leftAxisLegend: index === 0 ? temperatureLabel : null,
        rightAxisLegend:
          index === 1 ? humidityLabel : index === 2 ? lightLabel : null,
        yScale:
          index === 1
            ? { min: 10, max: 50 }
            : index === 2
            ? { min: 0, max: 2000 }
            : null,
        data: sensorReadings.data
          .filter((f) => {
            return f[sp]
          })
          .map((items) => ({
            x: items.time,
            y: items[sp],
          })),
      })
    })

  return graphArr
}
export const mapIbcTypes = (ibcTypes) => {
  return (
    ibcTypes.isSuccess &&
    ibcTypes.data &&
    ibcTypes.data.map((items) => ({
      id: items.id,
      label: items.series,
    }))
  )
}
