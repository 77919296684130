import { Circle, Tooltip } from '@chakra-ui/react'

const IconCircle = (props) => {
  const defaultProps = {
    as: 'button',
    size: '10',
    bg: 'dimmed',
    color: 'black',
    _hover: { bg: 'dimmedOnChange', color: 'black' },
  }
  return (
    <Tooltip label={props.label}>
      <Circle
        {...props}
        as={props.as === null ? props.as : defaultProps.as}
        size={props.size ? props.size : defaultProps.size}
        bg={props.bg ? props.bg : defaultProps.bg}
        color={props.color ? props.color : defaultProps.color}
        _hover={props.hover ? props.hover : defaultProps._hover}
        onClick={props.onClick}
      >
        {props.icon}
      </Circle>
    </Tooltip>
  )
}

export default IconCircle
