import {
  Box,
  Link,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
} from '@chakra-ui/react'
import * as React from 'react'
import { HiEye, HiEyeOff } from 'react-icons/hi'
import { useTranslation } from 'react-i18next'
import { Link as ReactRouterLink } from 'react-router-dom'

export const PasswordField = React.forwardRef((props, ref) => {
  const { t } = useTranslation()
  const { forgotpassword, onChangeHandler, ...rest } = props

  const { isOpen, onToggle } = useDisclosure()
  const inputRef = React.useRef(null)
  const mergeRef = useMergeRefs(inputRef, ref)

  const onClickReveal = () => {
    onToggle()
    const input = inputRef.current

    if (input) {
      input.focus({
        preventScroll: true,
      })
      const length = input.value.length * 2
      requestAnimationFrame(() => {
        input.setSelectionRange(length, length)
      })
    }
  }

  return (
    <FormControl id='password'>
      <Flex justify='space-between'>
        <FormLabel>{t('login.login.password')}</FormLabel>
        <Box as='a' fontWeight='semibold' fontSize='sm'>
          {forgotpassword ? (
            <Link data-testid="forgotPassword" as={ReactRouterLink} to='/forgot-password'>
              {t('login.login.forgotPassword')}
            </Link>
          ) : (
            ''
          )}
        </Box>
      </Flex>
      <InputGroup>
        <InputRightElement>
          <IconButton
            bg='transparent !important'
            variant='ghost'
            aria-label={isOpen ? 'Mask password' : 'Reveal password'}
            icon={isOpen ? <HiEyeOff /> : <HiEye />}
            onClick={onClickReveal}
          />
        </InputRightElement>
        <Input
          ref={mergeRef}
          name='password'
          type={isOpen ? 'text' : 'password'}
          autoComplete='current-password'
          required
          onChange={(e) => onChangeHandler(e.currentTarget.value)}
          {...rest}
        />
      </InputGroup>
    </FormControl>
  )
})
PasswordField.displayName = 'PasswordField'
