import React, { useEffect, useState } from 'react'
import Tour from 'reactour'
import '../css/WebTour.css'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import gtag from 'ga-gtag'

const stepStyle = {
  color: '#666',
  background: '#dff9fb',
}
function OrdersWebTour() {
  const [isTourOpen, setIsTourOpen] = useState(true)
  const history = useHistory()
  const { t } = useTranslation()
  const location = useLocation()
  useEffect(() => {
    gtag('event', 'App guide')
  }, [])
  const steps = [
    {
      selector: '#side_sensors',
      content: t('tour.step1'),
      style: stepStyle,
    },
    {
      action: () => {
        const pathname = window.location.pathname
        if (pathname === '/docs' || pathname === '/') {
          history.push({
            pathname: '/my-sensors',
            search: '?fromDashboard=True',
          })
        }
      },
    },
  ]

  const onPopupClose = () => {
    setIsTourOpen(false)
    const queryParams = new URLSearchParams(location.search)
    if (queryParams.has('clickItem')) {
      queryParams.delete('clickItem')
      history.replace({
        search: queryParams.toString(),
      })
    }
  }

  return (
    <Tour
      steps={steps}
      isOpen={isTourOpen}
      rounded={5}
      stepInteraction={true}
      maskClassName='mask'
      accentColor='#269faf'
      onRequestClose={onPopupClose}
      badgeContent={(curr, tot) => `${curr} of ${tot + 2}`}
      showNavigation={false}
    />
  )
}

export default OrdersWebTour
