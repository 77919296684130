import React, { useEffect } from 'react'
import {
  withRouter,
  Link as ReactRouterLink,
  useLocation,
} from 'react-router-dom'
import TableExtended from '../components/TableExtended'
import {
  Box,
  Button,
  Flex,
  Text,
  Icon,
  Link,
  useDisclosure,
  VStack,
  HStack,
  useMediaQuery,
  Fade,
  Heading,
} from '@chakra-ui/react'
import ModalExtended from '../components/ModalExtended'
import { MdBatteryFull, MdBattery20, MdBattery50 } from 'react-icons/md'
import Loading from '../components/Loading'
import { useGetSensors, useRegisterSensor } from '../services/sensors'
import { useTranslation } from 'react-i18next'
import DocumentTitle from 'react-document-title'
import { GetModal } from '../utils/getModal'
import { DEFAULT_TABLE_PROPS } from '../utils/utils'
import SensorWebTour from '../components/sensorsWebTour'
import gtag from 'ga-gtag'
import API from '../utils/api'

const MySensors = () => {
  const { t } = useTranslation()
  const { data, error, isLoading } = useGetSensors()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [smallScreen] = useMediaQuery('(max-width: 1024px)')
  const location = useLocation()
  const columns = [
    {
      Header: 'null',
      columns: [
        {
          Header: t('sensors.table.id'),
          accessor: (d) => (
            <HStack h='full' spacing='5'>
              <Link
                as={ReactRouterLink}
                to={{
                  pathname: `/my-sensors/sensor/${d.device_id}`,
                  state: {
                    device_id: d.device_id_short,
                    device_id_long: d.device_id,
                  },
                }}
              >
                {d.device_id_short}
              </Link>
            </HStack>
          ),
        },
        {
          Header: t('sensors.table.status'),
          accessor: (d) => (
            <VStack {...DEFAULT_TABLE_PROPS.vstack}>
              {d.sensor_status && d.sensor_status.toLowerCase() === 'on' ? (
                <Text color={'positive'}>Online</Text>
              ) : d.sensor_status && d.sensor_status.toLowerCase() === 'na' ? (
                <Text color={'warning'}>
                  {t('sensorOverview.statusNotActivated')}
                </Text>
              ) : d.sensor_status && d.sensor_status.toLowerCase() === 'off' ? (
                <Text color={'negative'}>Offline</Text>
              ) : (
                <Text>{d.sensor_status}</Text>
              )}
            </VStack>
          ),
        },
        {
          Header: t('sensors.table.battery'),
          accessor: (d) => (
            <VStack {...DEFAULT_TABLE_PROPS.vstack}>
              {d.device_battery_color ? (
                <Icon
                  as={
                    d.device_battery_color === 'green'
                      ? MdBatteryFull
                      : d.device_battery_color === 'yellow'
                      ? MdBattery50
                      : d.device_battery_color === 'orange'
                      ? MdBattery20
                      : MdBatteryFull
                  }
                  w={9}
                  h={9}
                  {...DEFAULT_TABLE_PROPS.iconProps}
                  color={
                    d.device_battery_color === 'green'
                      ? 'positive'
                      : d.device_battery_color === 'yellow'
                      ? 'meduim'
                      : d.device_battery_color === 'orange'
                      ? 'warning'
                      : 'negative'
                  }
                  transform={'rotateZ(-90deg)'}
                />
              ) : null}
            </VStack>
          ),
        },
        {
          Header: t('sensors.table.customer'),
          accessor: (d) => (
            <VStack {...DEFAULT_TABLE_PROPS.vstack}>
              {d.current_order !== null &&
                typeof d.current_order === 'object' &&
                d.current_order.customer && (
                  <Text>{d.current_order.customer.name}</Text>
                )}
            </VStack>
          ),
        },
        {
          Header: t('sensors.table.orderNumber'),
          accessor: (d) => (
            <VStack {...DEFAULT_TABLE_PROPS.vstack}>
              {d.current_order !== null && typeof d.current_order === 'object' && (
                <Link
                  as={ReactRouterLink}
                  to={{
                    pathname: `/my-sensors/order/${d.current_order.id}`,
                    state: { order_id: d.current_order.id },
                  }}
                >
                  {d.current_order.order_number}
                </Link>
              )}
            </VStack>
          ),
        },
        {
          Header: t('sensors.table.sensorInfo'),
          accessor: (d) => (
            <VStack {...DEFAULT_TABLE_PROPS.vstack}>
              <Text>{d.sensor_info}</Text>
            </VStack>
          ),
        },
      ],
    },
  ]

  useEffect(() => {
    gtag('event', `Vitreo - ${t('sensors.documentTitle')}`)
    const getProfile = async () => {
      return await API.get('profile').then((d) => {
        return d.data && d.data.profile
      })
    }
    getProfile().then((profile) => {
      console.log('profile', profile)
      gtag('config', process.env.REACT_APP_GA4_MEASUREMENT_ID, {
        user_id: profile.username,
      })
      gtag('set', 'user_properties', {
        crm_id: profile.username,
      })
    })
  }, [t])

  const handleclick = () => onOpen()

  //prepare toast
  const toastCallback = (success, warning, error) => {
    return {
      success: {
        title: t('success') + '!',
        description: success,
      },
      warning: {
        title: t('warning') + '!',
        description: warning,
      },
      error: {
        title: t('error') + '!',
        description: error,
      },
    }
  }

  //call api
  const registerSensor = useRegisterSensor(
    toastCallback(
      t('addSensor.sensorAdded'),
      t('addSensor.sensorAlreadyRegistered'),
      t('addSensor.sensorNotFound')
    )
  )

  const handleSubmit = (data) => {
    gtag('event', 'Add new Sensor')
    registerSensor.mutate(data.inputRef)
  }

  return (
    <>
      {location.search && (
        <SensorWebTour
          fromDashboardReverse={location.search.includes(
            'fromDashboardReverse'
          )}
        />
      )}
      <DocumentTitle title={`Vitreo - ${t('sensors.documentTitle')}`}>
        <Box>
          <Heading data-testid='MySensorsHeading' as='h2' mb={8}>
            {t('sensors.pageTitle')}
          </Heading>
          <Flex justify='flex-end' mb={smallScreen ? '5' : '10'}>
            <Button
              data-testid='addSensor'
              id='addSensor'
              onClick={() => handleclick()}
              variant='primaryCurved'
            >
              {'+ ' + t('sensorOverview.addSensor')}
            </Button>
          </Flex>
          {isLoading && <Loading />}
          {error || (data && data.message) ? (
            <Text>{t('errorNoData')}</Text>
          ) : (
            <>
              {data && Array.isArray(data) && (
                <Fade in>
                  <TableExtended columns={columns} data={data} isPagination />
                </Fade>
              )}
            </>
          )}

          <ModalExtended
            callback={handleclick}
            options={GetModal(t, 'addSensor')}
            isOpen={isOpen}
            onClose={onClose}
            handleSubmit={handleSubmit}
          />
        </Box>
      </DocumentTitle>
    </>
  )
}

export default withRouter(MySensors)
