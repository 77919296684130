import axios from 'axios'

const SUCCESS_STATUS_CODE = 200
const SESSION_EXPIRED_STATUS_CODE = 401

export const interceptorRequestHandler = (request) => {
  if (getAccessToken()) {
    request.headers['Authorization'] = 'Bearer ' + getAccessToken()
  }
  return request
}

export const interceptorAPIGatewayRequestHandler = (request) => {
  if (getIdToken()) {
    request.headers['Authorization'] = getIdToken()
  }
  return request
}

export const interceptorAPIPublicRequestHandler = (request) => {
  return request
}

export const responseSuccessHandler = (response) => response

export const responseErrorHandler = (API_LINK, error, instance) => {
  const originalRequest = error.config
  let refreshToken = getRefreshToken()

  if (refreshToken && error.response.status === SESSION_EXPIRED_STATUS_CODE) {
    return axios
      .post(API_LINK + `/refresh_token`, {
        refresh_token: refreshToken,
      })
      .then((res) => {
        handleRefreshTokenSuccess(error, res)
        error.config.headers.Authorization = 'Bearer ' + getAccessToken()
        return instance.request(originalRequest)
      })
      .catch(() => (window.location.pathname = '/login'))
  }
  return Promise.reject(error)
}
export const responseErrorApiGatewayHandler = (API_LINK, error, instance) => {
  const originalRequest = error.config
  let refreshToken = getRefreshToken()

  if (refreshToken && error.response.status === SESSION_EXPIRED_STATUS_CODE) {
    return axios
      .post(API_LINK + `/refresh_token`, {
        refresh_token: refreshToken,
      })
      .then((res) => {
        handleRefreshTokenSuccess(error, res)
        error.config.headers.Authorization = getIdToken()
        return instance.request(originalRequest)
      })
      .catch(() => (window.location.pathname = '/login'))
  }
  return Promise.reject(error)
}

export const responseErrorApiPublicHandler = (error) => {
  return Promise.reject(error)
}

const handleRefreshTokenSuccess = (error, res) => {
  if (res.status === SUCCESS_STATUS_CODE) {
    setTokens(res)
  } else {
    //navigate to login screen if refresh token fails and clear local storage
    localStorage.clear()
    window.location.pathname = '/login'
  }
}

export const setTokens = (result) => {
  localStorage.setItem('accessToken', result.data.credentials.access_token)
  localStorage.setItem('idToken', result.data.credentials.id_token)
  localStorage.setItem('refreshToken', result.data.credentials.refresh_token)
}

export const getAccessToken = () => localStorage.getItem('accessToken') || ''
export const getRefreshToken = () => localStorage.getItem('refreshToken') || ''
export const getIdToken = () => localStorage.getItem('idToken') || ''
