import React, { useEffect } from 'react'
import { withRouter, Link as ReactRouterLink } from 'react-router-dom'
import { Stack, Flex, Heading, Link, Text, VStack } from '@chakra-ui/react'
import { useTranslation, Trans } from 'react-i18next'
import { useAuth } from '../context/useAuth'
import { MdArrowBack } from 'react-icons/md'
import DocumentTitle from 'react-document-title'
import gtag from 'ga-gtag'
import API from '../utils/api'

const Terms = () => {
  const { t } = useTranslation()
  const { authToken } = useAuth()
  const conditions = t('terms.conditions', { returnObjects: true })

  useEffect(() => {
    gtag('Vitreo - ' + t('terms.pageTitle'))
    const getProfile = async () => {
      return await API.get('profile').then((d) => {
        return d.data && d.data.profile
      })
    }
    getProfile().then((profile) => {
      console.log('profike', profile)
      gtag('config', process.env.REACT_APP_GA4_MEASUREMENT_ID, {
        user_id: profile.username,
      })
      gtag('set', 'user_properties', {
        crm_id: profile.username,
      })
    })
  }, [t])

  return (
    <DocumentTitle title={'Vitreo - ' + t('terms.pageTitle')}>
      <Stack shouldWrapChildren spacing={10}>
        {!authToken && (
          <Flex align='center'>
            <MdArrowBack />
            <Link as={ReactRouterLink} to={'/register'}>
              {t('login.login.register')}
            </Link>
          </Flex>
        )}
        <Heading as='h2' mb={8}>
          {t('terms.pageHeading')}
        </Heading>
        <VStack spacing={6} align='stretch'>
          {conditions.map((condition) => (
            <Text key={condition.id}>
              <Text fontWeight='bold' mb={2}>
                {condition.heading}
              </Text>
              <Trans>{condition.subHeading}</Trans>
            </Text>
          ))}
        </VStack>
      </Stack>
    </DocumentTitle>
  )
}

export default withRouter(Terms)
