import React from 'react'
import { Box, Progress } from '@chakra-ui/react'
import { CardFull } from '../components/Card'
import LineGraphNivoPublic from '../components/LineGraphNivoPublic'

export const ShowGraphsPublic = ({
  data,
  xHeading,
  yHeading,
  graphData,
  tooltipSymbolY,
  errMsg,
  markers,
  isDatePickerUsed,
  yScale,
  tickValuesY,
  leftAxisLegend,
}) => {
  return (
    <CardFull
      data-testid='graphsCard'
      color='gray.500'
      heading={yHeading}
      content={
        <Box data-testid='graphsbox' w='full'>
          {data.isLoading && data.isFetching ? (
            <Progress colorScheme='teal' size='xs' isIndeterminate />
          ) : (
            data.isSuccess && (
              <LineGraphNivoPublic
                markers={markers}
                data={graphData}
                type={'timeSeries'}
                widthThreshold={800}
                tickValuesX={''}
                tickValuesY={tickValuesY || 5}
                xScale={{
                  type: 'time',
                  format: '%Y-%m-%dT%H:%M:%S',
                  useUTC: false,
                }}
                yScale={{
                  min: yScale && yScale.min,
                  max: yScale && yScale.max,
                }}
                xFormat='time:%Y-%m-%d, %H:%M:%S'
                axisLeftLegend={leftAxisLegend || yHeading}
                axisBottomLegend={xHeading}
                axisBottomFormat={'%m-%d'}
                tooltipID={'Id'}
                tooltipX={xHeading}
                tooltipY={yHeading}
                tooltipXSymbol={''}
                tooltipYSymbol={tooltipSymbolY}
                tooltipWidth={'15rem'}
                errorNoData={errMsg}
                offsetColors={null}
                isDatePickerUsed={isDatePickerUsed}
              />
            )
          )}
        </Box>
      }
    />
  )
}
