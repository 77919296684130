import API from '../utils/apiDomain'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

export const useCustomer = () => {
  return useQuery('customer', async () => {
    return await API.get('/customers/?no_pagination=true')
      .then((d) => d && d.data)
      .catch((error) => error)
  })
}

export const usePatchCustomer = (items, id) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  return useMutation(
    ['patchCustomer', id],
    async (data) => {
      return await API.patch(`/customers/${id}/`, data)
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('customer')
        return toast({
          position: 'bottom-right',
          title: items.success.title,
          description: items.success.description,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      },
      onError: async () => {
        return toast({
          position: 'bottom-right',
          title: items.error.title,
          description: items.error.description,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    }
  )
}

export const usePostCustomer = (items) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  return useMutation(
    'postCustomer',
    async (data) => {
      return await API.post('/customers/', data)
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('customer')
        return toast({
          position: 'bottom-right',
          title: items.success.title,
          description: items.success.description,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      },
      onError: async () => {
        return toast({
          position: 'bottom-right',
          title: items.error.title,
          description: items.error.description,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    }
  )
}

export const useDeleteCustomer = (items, id) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  return useMutation(
    ['deleteCustomer', id],
    async () => {
      return await API.delete(`/customers/${id}/`)
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('customer')
        return toast({
          position: 'bottom-right',
          title: items.success.title,
          description: items.success.description,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      },
      onError: async () => {
        return toast({
          position: 'bottom-right',
          title: items.error.title,
          description: items.error.description,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    }
  )
}
