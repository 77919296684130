import { Box, Stack, Text, Flex, Center } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import React from 'react'

const CardSmall = (props) => {
  const history = useHistory()
  const defaultProps = {
    rounded: 'lg',
    shadow: 'base',
    alignItems: 'center',
    p: '5',
  }
  return (
    <Box overflow='auto' {...defaultProps} {...props}>
      <Stack direction={'row'}>
        <Stack
          mr='2'
          w={
            props.rightcolumn ? (props.extrawideright ? '50%' : '70%') : '100%'
          }
          h={'60%'}
          _hover={
            props.isHeadingClickable && {
              fontWeight: 'semibold',
              cursor: 'pointer',
            }
          }
          onClick={() =>
            props.isHeadingClickable &&
            props.currentHeadingItem &&
            history.push({
              pathname: `/dashboard/order/${props.currentHeadingItem.orderID}/sensor/${props.currentHeadingItem.data.device_id}`,
              state: {
                device_id: props.currentHeadingItem.data.device_id_short,
                device_id_long: props.currentHeadingItem.data.device_id,
              },
            })
          }
        >
          <Text color={'gray.500'} textAlign={'left'} fontWeight='bold'>
            {props.heading}
          </Text>
          <>{props.content}</>
        </Stack>
        <Flex
          justify='flex-end'
          w={props.rightcolumn ? (props.extrawideright ? '50%' : '30%') : '0%'}
          h={'60%'}
        >
          {props.rightcolumn}
        </Flex>
      </Stack>
      {props.cardCaption && <>{props.cardCaption}</>}
    </Box>
  )
}

const CardFull = (props) => {
  const defaultProps = {
    rounded: 'lg',
    shadow: 'base',
    p: '5',
  }
  return (
    <Stack {...defaultProps} {...props}>
      <Text fontWeight='bold'>{props.heading}</Text>
      <Center w='full'>{props.content}</Center>
    </Stack>
  )
}

export { CardSmall, CardFull }
