import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, AlertIcon, AlertDescription } from '@chakra-ui/react'

const ShowAlert = ({ message }) => {
  const { t } = useTranslation()
  return (
    <Alert status='error'>
      <AlertIcon />
      <AlertDescription>{t(message)}</AlertDescription>
    </Alert>
  )
}

const errorKeys = {
  username: 'login.login.error.incorrectUsernameOrPassword',
  password: 'login.login.error.password',
  UserNotConfirmedException: 'login.login.error.userNotConfirmedException',
  UserNotFoundException: 'login.login.error.userDoesNotExist',
  default: 'login.login.error.errorOccurred',
}

export const ErrorHandler = ({ error, isAuthErr }) => {
  return (
    <>
      {error
        ? error.error
          ? Object.values(error.error).length > 0
            ? Object.entries(error.error).map((value) => {
                let translationsArray = []
                if (value[0] === 'username' && isAuthErr) {
                  translationsArray.push('login.login.error.auth')
                } else {
                  translationsArray.push(
                    errorKeys[value[0]] || errorKeys.default
                  )
                }
                return translationsArray.map((message, index) => (
                  <ShowAlert key={index} message={message} />
                ))
              })
            : false
          : false
        : false}
    </>
  )
}
