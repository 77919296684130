import React, { useEffect } from 'react'
import {
  withRouter,
  useParams,
  Link as ReactRouterLink,
} from 'react-router-dom'
import {
  Box,
  Fade,
  Heading,
  Link,
  Progress,
  SimpleGrid,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import DocumentTitle from 'react-document-title'
import { CardSmall } from '../components/Card'
import { useGetSensorPublicReadings } from '../services/sensors'
import { CreateDateTimeHTML } from '../utils/utils'
import Loading from '../components/Loading'
import { graphPublicSensorDataMapped } from '../utils/prepareGraphAndMapData'
import { ShowGraphsPublic } from '../utils/prepareGraphsPublic'
import { WarningTwoIcon } from '@chakra-ui/icons'
import gtag from 'ga-gtag'

const SensorQrCode = () => {
  const { t } = useTranslation()
  const [smallScreen] = useMediaQuery('(max-width: 1024px)')
  const { id } = useParams()
  const sensorPublicReadings = useGetSensorPublicReadings(id)
  const NUMBER_OF_GRAPHS = 1

  useEffect(() => {
    gtag(`Vitreo-Quick-Page - ${t('sensorDetail.pageTitle')} #${id}`)
  }, [id, t])

  return (
    <DocumentTitle
      title={`Vitreo-Quick-Page - ${t('sensorDetail.pageTitle')} #${id}`}
    >
      <Box>
        {sensorPublicReadings.isLoading && <Loading />}
        {sensorPublicReadings.error ||
        (sensorPublicReadings.data &&
          sensorPublicReadings.data.response &&
          sensorPublicReadings.data.response.status === 404) ||
        (sensorPublicReadings.data &&
          sensorPublicReadings.data.response &&
          sensorPublicReadings.data.response.status === 400) ||
        (sensorPublicReadings.data &&
          sensorPublicReadings.data.length === 0) ? (
          <Text>{t('errorNoData')}</Text>
        ) : (
          <>
            {sensorPublicReadings.data && (
              <Fade in>
                <VStack align='stretch' spacing='2rem'>
                  <SimpleGrid
                    data-testid='infoBatteryCustgrid'
                    columns={smallScreen ? 1 : 1}
                    spacing={smallScreen ? 1 : 1}
                  >
                    <Heading as='h5' mb={0}>
                      {id}
                    </Heading>
                  </SimpleGrid>
                  <VStack align='stretch' spacing='1rem'>
                    <Heading data-testid='currentReadings' size='md' mr='0'>
                      {t('publicSensorDetail.currentReadings')}:{' '}
                      {sensorPublicReadings.data.slice(-1)[0] &&
                        CreateDateTimeHTML(
                          sensorPublicReadings.data.slice(-1)[0].time,
                          '-',
                          true
                        )}
                    </Heading>
                    <SimpleGrid
                      data-testid='readingsPublicSensorDetail'
                      columns={smallScreen ? 3 : 3}
                      spacing={smallScreen ? 2 : 10}
                    >
                      <CardSmall
                        p={1}
                        data-testid='tempPublicSensorDetail'
                        color='gray.500'
                        heading={t('publicSensorDetail.temperature')}
                        content={
                          sensorPublicReadings.isLoading ? (
                            <Progress
                              colorScheme='teal'
                              size='xs'
                              isIndeterminate
                            />
                          ) : sensorPublicReadings.data &&
                            sensorPublicReadings.data.slice(-1)[0] &&
                            sensorPublicReadings.data.slice(-1)[0]
                              .temperature ? (
                            <Text>
                              {sensorPublicReadings.data.slice(-1)[0] &&
                                sensorPublicReadings.data.slice(-1)[0]
                                  .temperature}
                              °C
                            </Text>
                          ) : (
                            <>&#8212;</>
                          )
                        }
                      />
                      <CardSmall
                        p={1}
                        data-testid='humidityPublicSensorDetail'
                        color='gray.500'
                        heading={t('publicSensorDetail.humidity')}
                        content={
                          sensorPublicReadings.isLoading ? (
                            <Progress
                              colorScheme='teal'
                              size='xs'
                              isIndeterminate
                            />
                          ) : sensorPublicReadings.data &&
                            sensorPublicReadings.data.slice(-1)[0] &&
                            sensorPublicReadings.data.slice(-1)[0].humidity ? (
                            <Text>
                              {sensorPublicReadings.data.slice(-1)[0].humidity}{' '}
                              %r.H.
                            </Text>
                          ) : (
                            <>&#8212;</>
                          )
                        }
                      />
                      <CardSmall
                        p={1}
                        data-testid='ambLightPublicSensorDetail'
                        color='gray.500'
                        heading={t('publicSensorDetail.light')}
                        content={
                          sensorPublicReadings.isLoading ? (
                            <Progress
                              colorScheme='teal'
                              size='xs'
                              isIndeterminate
                            />
                          ) : sensorPublicReadings.data &&
                            sensorPublicReadings.data.slice(-1)[0] &&
                            sensorPublicReadings.data.slice(-1)[0].amb_light ? (
                            <Text>
                              {sensorPublicReadings.data.slice(-1)[0].amb_light}{' '}
                              lux
                            </Text>
                          ) : (
                            <>&#8212;</>
                          )
                        }
                      />
                    </SimpleGrid>
                  </VStack>
                  {[...Array(NUMBER_OF_GRAPHS)].map((el, graphIndex) => (
                    <ShowGraphsPublic
                      key={graphIndex}
                      data={sensorPublicReadings}
                      graphData={graphPublicSensorDataMapped(
                        sensorPublicReadings,
                        id,
                        t('publicSensorDetail.temperature'),
                        t('publicSensorDetail.humidity'),
                        t('publicSensorDetail.light')
                      )}
                      xHeading={t('date')}
                      errMsg={t('errorNoData')}
                      tickValuesY={10}
                    />
                  ))}
                  <Box data-testid='checkDetails' zIndex={10}>
                    {sensorPublicReadings.data &&
                      sensorPublicReadings.data[0] &&
                      sensorPublicReadings.data[0].alert_triggered && (
                        <VStack>
                          <WarningTwoIcon
                            alignSelf='center'
                            w={30}
                            h={30}
                            mx={2}
                            color='tertiary'
                          />
                          <Text
                            color={'gray.500'}
                            fontWeight='bold'
                            fontSize='lg'
                          >
                            {t('publicSensorDetail.specificationWarning')}
                          </Text>
                        </VStack>
                      )}
                    <Box
                      w='full'
                      align='center'
                      fontWeight='semibold'
                      fontSize='sm'
                    >
                      <Link as={ReactRouterLink} to={'/login'} mt='2'>
                        {t('publicSensorDetail.checkDetails')}
                      </Link>
                    </Box>
                  </Box>
                </VStack>
              </Fade>
            )}
          </>
        )}
      </Box>
    </DocumentTitle>
  )
}

export default withRouter(SensorQrCode)
