/*Copyright declaration:
This file is part of the Vitreo project which is licensed to Vistas Technology GmbH, Berlin Germany
Exclusive permission from Vistas Technology GmbH is required to use any part of the software resources.
Author: Emna Kammoun.
Date: 08.11.2022*/
import React, { useState, useEffect } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import HC_Data from 'highcharts/modules/export-data'
import { useTranslation } from 'react-i18next'
import graphColors from '../theme/graphColors.json'
import isEqual from 'react-fast-compare'
import { Center, Progress } from '@chakra-ui/react'

const HighchartsGraph = React.memo((props) => {
  const { t } = useTranslation()
  const validData = Array.isArray(props.data) && props.data.length > 0
  const [dataReady, setDataReady] = useState(false)
  const [isEmptyData, setIsEmptyData] = useState(false)
  const [data, setData] = useState()
  HC_exporting(Highcharts)
  HC_Data(Highcharts)

  useEffect(() => {
    if (props.data) {
      setData((prev) => {
        if (!prev || prev.length !== props.data.length) {
          return props.data
        } else {
          return prev.map((m) => {
            const serverData = props.data.find((g) => g.name === m.name)
            return {
              ...m,
              data: serverData.data,
            }
          })
        }
      })
      setDataReady(true)
      setIsEmptyData(
        props.data.every(({ data }) => Object.entries(data).length === 0)
      )
    }
  }, [props.data])
  const Option = {
    chart: {
      type: 'line',
      zoomType: 'x',
    },
    time: {
      useUTC: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      title: {
        text: props.axisBottomLegend + props.tooltipXSymbol,
      },
      type: 'datetime',
    },
    yAxis: {
      title: {
        text: props.axisLeftLegend + props.tooltipYSymbol,
      },

      maxZoom: 0.1,
    },
    legend: {
      //enabled: false
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      itemMarginBottom: 10,
      padding: 0,
      labelFormatter: function () {
        return this.name.slice(-6)
      },
    },
    plotOptions: {
      series: {
        events: {
          legendItemClick: function (item) {
            if (!this.visible) {
              props.setClickedSensorId(item.target.name)
            }
            setData((prev) => {
              prev.find((g) => g.name === item.target.name).visible = !t.visible
              return prev
            })
          },
        },
      },
    },

    credits: {
      enabled: false,
    },
    colors:
      props.offsetColors === undefined
        ? graphColors
        : graphColors.slice(props.offsetColors),

    /* csv file exporting */
    hc_exporting: {
      enabled: true,

      showTable: true,
    },
    accessibility: {
      enabled: false,
    },

    series: data,
  }
  const NoDataErrorMarkup = ({ msg }) => (
    <Center
      h='full'
      w='full'
      color='gray'
      justifyContent='center'
      alignItems='center'
    >
      {msg ? msg : props.errorNoData}
    </Center>
  )
  return validData ? (
    <>
      {dataReady ? (
        <>
          {props.data.length <= 0 ? (
            <NoDataErrorMarkup msg={t('emptyData')} />
          ) : !props.isDatePickerUsed && isEmptyData ? (
            <NoDataErrorMarkup msg={t('emptyData')} />
          ) : props.isDatePickerUsed && isEmptyData ? (
            <NoDataErrorMarkup msg={t('dataCollectionMsg')} />
          ) : (
            <HighchartsReact
              options={Option}
              highcharts={Highcharts}
              constructorType={'chart'}
            />
          )}
        </>
      ) : (
        <Progress colorScheme='teal' size='xs' isIndeterminate />
      )}
    </>
  ) : (
    <NoDataErrorMarkup />
  )
})
export default React.memo(HighchartsGraph, isEqual)
