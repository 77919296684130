import React, { useEffect, useState } from 'react'
import { withRouter, useParams } from 'react-router-dom'
import {
  Box,
  Fade,
  Heading,
  Progress,
  SimpleGrid,
  Switch,
  Text,
  useDisclosure,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import DocumentTitle from 'react-document-title'
import {
  useGetSensorByID,
  useGetSensorReadings,
  useGetSensorLocations,
} from '../services/sensors'
import { CardFull, CardSmall } from '../components/Card'
import ReactMapGL from '../components/Map/ReactMapGL'
import {
  graphSensorDataMapped,
  mapSensorLocations,
} from '../utils/prepareGraphAndMapData'
import Loading from '../components/Loading'
import { ShowGraphs } from '../utils/prepareGraphs'
import { CreateDateTimeHTML, ToastCallback } from '../utils/utils'
import { EditIcon } from '@chakra-ui/icons'
import {
  useGetDeviceShadow,
  usePatchDeviceShadow,
} from '../services/apiGateway'
import ModalExtended from '../components/ModalExtended'
import { sendingIntervals } from '../utils/prepareDeviceShadow'
import IconCircle from '../components/IconCircle'
import gtag from 'ga-gtag'
import API from '../utils/api'

const SensorDetail = (props) => {
  const { t } = useTranslation()
  const [smallScreen] = useMediaQuery('(max-width: 1024px)')
  const { id } = useParams()
  const longSensorID = props.location.state
    ? props.location.state.device_id_long
    : id
  //api calls for data to show on screen
  const [modalChoice, setModalChoice] = useState()
  const [radarChecked, setRadarChecked] = useState(0)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data, error, isLoading } = useGetSensorByID(longSensorID)
  const dataDeviceShadow = useGetDeviceShadow(longSensorID)
  const allSensorReadings = useGetSensorReadings(longSensorID)
  const sensorId = props.location.state
    ? props.location.state.device_id
    : data && data.device_id_short
  const sensorLocations = useGetSensorLocations(longSensorID)
  const NUMBER_OF_GRAPHS = 5

  const handleclick = (e) => {
    onOpen()
    setModalChoice(e)
  }

  const editDeviceShadow = usePatchDeviceShadow(
    ToastCallback(t('deviceShadowUpdated'), t('deviceShadowNotUpdated')),
    id
  )
  const getModal = (modal) =>
    ({
      editSendingInterval: [
        {
          type: 'select_generic',
          options: sendingIntervals(),
          key: 'editSendingInterval',
          title: t('modalEditSendingInterval.title'),
          defaultValue:
            dataDeviceShadow.data &&
            dataDeviceShadow.data.cfg &&
            sendingIntervals().find(
              (f) => f.id === dataDeviceShadow.data.cfg.data_send_interval
            )
              ? {
                  id: dataDeviceShadow.data.cfg.data_send_interval,
                  value: dataDeviceShadow.data.cfg.data_send_interval,
                  label: sendingIntervals().find(
                    (f) => f.id === dataDeviceShadow.data.cfg.data_send_interval
                  ).label,
                }
              : null,
        },
        {
          type: 'alert',
          alertType: 'warning',
          title: t('modalEditSendingInterval.alertText'),
          button: 'save',
        },
      ],
      confirmEnableFillLevel: [
        {
          key: 'confirmEnableFillLevel',
          title: radarChecked
            ? t('modalEnableFillLevel.titleEnable')
            : t('modalEnableFillLevel.titleDisable'),
          type: 'confirm',
          button: 'save',
          saveButtonText: t('modalEnableFillLevel.confirmBtnTxt'),
        },
      ],
    }[modal])
  const handleSubmit = (items) => {
    const submitOperation = {
      editSendingInterval: function () {
        gtag('event', 'Edit Device shadow sending interval')
        editDeviceShadow.mutate({
          data_send_interval: items.genericSelectItem,
        })
      },
      confirmEnableFillLevel: function () {
        editDeviceShadow.mutate({
          radar: radarChecked,
        })
      },
    }
    return submitOperation[items.key]() || []
  }
  const handleRadar = (e) => {
    if (e.target.checked) {
      gtag('event', 'Turn on Device shadow fill level')
      setRadarChecked(1)
    } else {
      gtag('event', 'Turn off Device shadow fill level')
      setRadarChecked(0)
    }
    handleclick('confirmEnableFillLevel')
  }
  useEffect(() => {
    gtag('event', `Vitreo - ${t('sensorDetail.pageTitle')} #${sensorId}`)
    const getProfile = async () => {
      return await API.get('profile').then((d) => {
        return d.data && d.data.profile
      })
    }
    getProfile().then((profile) => {
      console.log('profike', profile)
      gtag('config', process.env.REACT_APP_GA4_MEASUREMENT_ID, {
        user_id: profile.username,
      })
      gtag('set', 'user_properties', {
        crm_id: profile.username,
      })
    })
  }, [sensorId, t])
  return (
    <DocumentTitle
      title={`Vitreo - ${t('sensorDetail.pageTitle')} #${sensorId}`}
    >
      <Box>
        {isLoading && <Loading />}
        {error ||
        (data && data.status === 404) ||
        (data && data.length === 0) ? (
          <Text>{t('errorNoData')}</Text>
        ) : (
          <>
            {data && (
              <Fade in>
                <VStack align='stretch' spacing='3rem'>
                  <SimpleGrid
                    data-testid='infoBatteryCustgrid'
                    columns={smallScreen ? 1 : 3}
                    spacing={smallScreen ? 5 : 10}
                  >
                    <CardSmall
                      data-testid='sensorInfoSensorDetail'
                      color='gray.500'
                      heading={t('sensorDetail.sensorInfo')}
                      content={
                        data.sensor_info ? (
                          <Text>{data.sensor_info}</Text>
                        ) : (
                          <>&#8212;</>
                        )
                      }
                    />
                    <CardSmall
                      data-testid='sensorBatterySensorDetail'
                      color='gray.500'
                      heading={t('sensorDetail.battery') + ' '}
                      content={
                        <Text>
                          {t('sensorDetail.batteryStatus')}:{' '}
                          {data.battery_status &&
                          data.battery_status.toLowerCase() === 'al'
                            ? t('sensorDetail.batteryStatusOk')
                            : t('sensorDetail.batteryStatusOffline')}
                        </Text>
                      }
                    />
                    <CardSmall
                      data-testid='sensorCustomerSensorDetail'
                      color='gray.500'
                      content={
                        <>
                          <Text>
                            <span style={{ fontWeight: 'bold' }}>
                              {t('sensorDetail.manufacturer') + ': '}
                            </span>
                            {data.current_order &&
                            data.current_order.customer &&
                            data.current_order.customer.name ? (
                              data.current_order.customer.name
                            ) : (
                              <>&#8212;</>
                            )}
                          </Text>
                          <Text>
                            <span style={{ fontWeight: 'bold' }}>
                              {t('order') + ': '}
                            </span>
                            {data.current_order &&
                            data.current_order.order_number ? (
                              data.current_order.order_number
                            ) : (
                              <>&#8212;</>
                            )}
                          </Text>
                        </>
                      }
                    />
                    <CardSmall
                      data-testid='deviceShadowSendingInterval'
                      color='gray.500'
                      heading={t('sendingInterval')}
                      content={
                        dataDeviceShadow.isLoading ? (
                          <Progress
                            colorScheme='teal'
                            size='xs'
                            isIndeterminate
                          />
                        ) : dataDeviceShadow.data &&
                          dataDeviceShadow.data.cfg ? (
                          <Text>
                            {dataDeviceShadow.data.cfg.data_send_interval < 3600
                              ? `${
                                  dataDeviceShadow.data.cfg.data_send_interval /
                                  60
                                } min`
                              : `${
                                  dataDeviceShadow.data.cfg.data_send_interval /
                                  3600
                                } h`}
                          </Text>
                        ) : (
                          <>&#8212;</>
                        )
                      }
                      rightcolumn={
                        <IconCircle
                          data-testid='editSendingInterval'
                          label={t('tooltip.editSendingInterval')}
                          icon={<EditIcon />}
                          onClick={() => handleclick('editSendingInterval')}
                        />
                      }
                    />
                    <CardSmall
                      data-testid='radar'
                      id='radar'
                      color='gray.500'
                      heading={t('radarEnabled')}
                      rightcolumn={
                        dataDeviceShadow.isLoading ? (
                          <Progress
                            colorScheme='teal'
                            size='xs'
                            isIndeterminate
                          />
                        ) : (
                          <Switch
                            data-testid='switchRadar'
                            isChecked={
                              dataDeviceShadow.data &&
                              dataDeviceShadow.data.cfg &&
                              dataDeviceShadow.data.cfg.en_flags.radar
                            }
                            colorScheme='switch'
                            onChange={(e) => handleRadar(e)}
                            size='lg'
                          />
                        )
                      }
                    />
                  </SimpleGrid>
                  <VStack align='stretch' spacing='5'>
                    <Heading data-testid='currentReadings' size='md' mr='5'>
                      {t('sensorDetail.currentReadings')}:{' '}
                      {data.last_reading &&
                        CreateDateTimeHTML(data.last_reading.time, '-', true)}
                    </Heading>

                    <SimpleGrid
                      data-testid='readingsSensorDetail'
                      columns={smallScreen ? 1 : 3}
                      spacing={smallScreen ? 5 : 10}
                    >
                      <CardSmall
                        data-testid='tempSensorDetail'
                        color='gray.500'
                        heading={t('temperature')}
                        content={
                          data.isLoading ? (
                            <Progress
                              colorScheme='teal'
                              size='xs'
                              isIndeterminate
                            />
                          ) : data &&
                            data.last_reading &&
                            data.last_reading.data &&
                            data.last_reading.data.temperature ? (
                            <Text>
                              {data.last_reading.data &&
                                data.last_reading.data.temperature}
                              °C
                            </Text>
                          ) : (
                            <>&#8212;</>
                          )
                        }
                      />
                      <CardSmall
                        data-testid='humiditySensorDetail'
                        color='gray.500'
                        heading={t('humidity')}
                        content={
                          data.isLoading ? (
                            <Progress
                              colorScheme='teal'
                              size='xs'
                              isIndeterminate
                            />
                          ) : data &&
                            data.last_reading &&
                            data.last_reading.data &&
                            data.last_reading.data.humidity ? (
                            <Text>{data.last_reading.data.humidity}</Text>
                          ) : (
                            <>&#8212;</>
                          )
                        }
                      />
                      <CardSmall
                        data-testid='airPressureSensorDetail'
                        color='gray.500'
                        heading={t('airPressure')}
                        content={
                          data.isLoading ? (
                            <Progress
                              colorScheme='teal'
                              size='xs'
                              isIndeterminate
                            />
                          ) : data &&
                            data.last_reading &&
                            data.last_reading.data &&
                            data.last_reading.data.air_pressure ? (
                            <Text>{data.last_reading.data.air_pressure}</Text>
                          ) : (
                            <>&#8212;</>
                          )
                        }
                      />
                      <CardSmall
                        data-testid='zirQualitySensorDetail'
                        color='gray.500'
                        heading={t('airQuality')}
                        content={
                          data.isLoading ? (
                            <Progress
                              colorScheme='teal'
                              size='xs'
                              isIndeterminate
                            />
                          ) : data &&
                            data.last_reading &&
                            data.last_reading.data &&
                            data.last_reading.data.air_quality ? (
                            <Text>{data.last_reading.data.air_quality}</Text>
                          ) : (
                            <>&#8212;</>
                          )
                        }
                      />
                      <CardSmall
                        data-testid='ambLightSensorDetail'
                        color='gray.500'
                        heading={t('ambientLight')}
                        content={
                          data.isLoading ? (
                            <Progress
                              colorScheme='teal'
                              size='xs'
                              isIndeterminate
                            />
                          ) : data &&
                            data.last_reading &&
                            data.last_reading.data &&
                            data.last_reading.data.amb_light ? (
                            <Text>{data.last_reading.data.amb_light}</Text>
                          ) : (
                            <>&#8212;</>
                          )
                        }
                      />
                    </SimpleGrid>
                  </VStack>
                  <CardFull
                    data-testid='mapSensorDetail'
                    color='gray.500'
                    heading={t('location')}
                    position='relative'
                    content={
                      <ReactMapGL
                        data={mapSensorLocations(sensorLocations)}
                        isZoom={false}
                      />
                    }
                  />
                  {[...Array(NUMBER_OF_GRAPHS)].map((el, graphIndex) => (
                    <ShowGraphs
                      key={graphIndex}
                      graphData={
                        graphIndex === 0
                          ? graphSensorDataMapped(
                              allSensorReadings,
                              'temperature',
                              longSensorID
                            )
                          : graphIndex === 1
                          ? graphSensorDataMapped(
                              allSensorReadings,
                              'humidity',
                              longSensorID
                            )
                          : graphIndex === 2
                          ? graphSensorDataMapped(
                              allSensorReadings,
                              'air_pressure',
                              longSensorID
                            )
                          : graphIndex === 3
                          ? graphSensorDataMapped(
                              allSensorReadings,
                              'air_quality',
                              longSensorID
                            )
                          : graphIndex === 4
                          ? graphSensorDataMapped(
                              allSensorReadings,
                              'amb_light',
                              longSensorID
                            )
                          : ''
                      }
                      tooltipSymbolY={
                        graphIndex === 0
                          ? ' \u{00B0}C'
                          : graphIndex === 1
                          ? ' (% r.H.)'
                          : graphIndex === 2
                          ? ' (hPa)'
                          : graphIndex === 4
                          ? ' (Lux)'
                          : ''
                      }
                      xHeading={t('date')}
                      yHeading={
                        graphIndex === 0
                          ? t('temperature')
                          : graphIndex === 1
                          ? t('humidity')
                          : graphIndex === 2
                          ? t('airPressure')
                          : graphIndex === 3
                          ? t('airQuality')
                          : graphIndex === 4
                          ? t('ambientLight')
                          : ''
                      }
                      leftAxisLegend={
                        graphIndex === 0
                          ? t('temperature')
                          : graphIndex === 1
                          ? t('humidity')
                          : graphIndex === 2
                          ? t('airPressure')
                          : graphIndex === 3
                          ? t('airQualityYAxis')
                          : graphIndex === 4
                          ? t('ambientLight')
                          : ''
                      }
                      yScale={
                        graphIndex === 1 //Humidity
                          ? { min: 0, max: 100 }
                          : graphIndex === 2 // Air Pressure
                          ? { min: 300, max: 1100 }
                          : graphIndex === 3 //Air Quality
                          ? { min: 0, max: 500 }
                          : ''
                      }
                      errMsg={t('errorNoData')}
                      tickValuesY={10}
                    />
                  ))}
                </VStack>
                {dataDeviceShadow.data && (
                  <ModalExtended
                    options={getModal(modalChoice)}
                    isOpen={isOpen}
                    onClose={onClose}
                    handleSubmit={handleSubmit}
                  />
                )}
              </Fade>
            )}
          </>
        )}
      </Box>
    </DocumentTitle>
  )
}

export default withRouter(SensorDetail)
