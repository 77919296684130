import axios from 'axios'
import {
  getIdToken,
  interceptorAPIGatewayRequestHandler,
  responseErrorApiGatewayHandler,
  responseSuccessHandler,
} from './common'

const AWS_GATEWAY_API_LINK =
  process.env.REACT_APP_AWS_API_GATEWAY_DATA_READINGS_URL
const API_LINK = process.env.REACT_APP_API_ROOT_URL

const axiosInstance = axios.create({
  baseURL: AWS_GATEWAY_API_LINK,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*',
    Authorization: getIdToken(),
  },
})

/*Add request interceptor for initial expired session check*/
axiosInstance.interceptors.request.use((request) =>
  interceptorAPIGatewayRequestHandler(request)
)

/* Response interceptor is added for domain apis to refresh token on receiving token expired error
 * It will catch each apis response and after refreshing the token, it will fire the request again
 * So the pages can have refreshed data
 * */
axiosInstance.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorApiGatewayHandler(API_LINK, error, axiosInstance)
)

export default axiosInstance
