import API from '../utils/apiDomain'
import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

export const usePostAlert = (items) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  return useMutation(
    async (data) => {
      const { data: response } = await API.post('customers/alarm_rules/', data)
      return response.data
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('order')
        return (
          items &&
          toast({
            position: 'bottom-right',
            title: items.success.title,
            description: items.success.description,
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
        )
      },
      onError: async () => {
        return (
          items &&
          toast({
            position: 'bottom-right',
            title: items.error.title,
            description: items.error.description,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        )
      },
    }
  )
}

export const useDeleteAlert = (items) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  return useMutation(
    async (id) => await API.delete(`/customers/alarm_rules/${id}/`),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('order')
        return toast({
          position: 'bottom-right',
          title: items.success.title,
          description: items.success.description,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      },
      onError: async () => {
        return toast({
          position: 'bottom-right',
          title: items.error.title,
          description: items.error.description,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    }
  )
}
