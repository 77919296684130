import React, { useState } from 'react'
import {
  Flex,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Button,
  useMediaQuery,
  chakra,
} from '@chakra-ui/react'
import Select from './Select'
import { useTranslation } from 'react-i18next'

export const CreateAlert = (props) => {
  const { t } = useTranslation()
  const [smallScreen] = useMediaQuery('(max-width: 1024px)')
  const [condition, setCondition] = useState('')
  const [alertType, setAlertType] = useState('')
  const [measure, setMeasure] = useState('')

  const alert_type_options = [
    { id: 1, label: t('fillLevel'), value: 'fill_level' },
    { id: 2, label: t('humidity'), value: 'humidity' },
    { id: 3, label: t('temperature'), value: 'temperature' },
    { id: 4, label: t('airPressure'), value: 'air_pressure' },
    { id: 5, label: t('airQuality'), value: 'air_quality' },
    { id: 6, label: t('ambientLight'), value: 'amb_light' },
  ]

  const condition_options = [
    { id: 1, label: t('orderOverview.alertSettings.lessThan'), value: 'LT' },
    {
      id: 2,
      label: t('orderOverview.alertSettings.greaterThan'),
      value: 'GTE',
    },
  ]
  return (
    <chakra.form
      onSubmit={(e) => {
        e.preventDefault()
        let details = {
          alertType: alertType,
          condition: condition,
          measure: measure,
        }
        //reset form after taking values in an object
        setAlertType(null)
        setCondition(null)
        e.target.reset()
        props.submitHandler(details)
      }}
    >
      <FormControl as={'fieldset'} mt={'5'}>
        <SimpleGrid columns={smallScreen ? 1 : 4} spacing={smallScreen ? 3 : 5}>
          <Flex direction={'column'}>
            <FormControl isRequired>
              <FormLabel as='legend'>
                {t('orderOverview.alertSettings.alertType')}
              </FormLabel>
              <Select
                options={alert_type_options}
                value={
                  alert_type_options.find((o) => o.value === alertType) || ''
                }
                closeMenuOnSelect={true}
                size='md'
                onChange={(e) => setAlertType(e.value)}
              />
            </FormControl>
          </Flex>
          <Flex direction={'column'}>
            <FormLabel as='legend'>
              {t('orderOverview.alertSettings.alertCondition')}
            </FormLabel>
            <Select
              options={condition_options}
              value={condition_options.find((o) => o.value === condition) || ''}
              closeMenuOnSelect={true}
              size='md'
              onChange={(e) => setCondition(e.value)}
            />
          </Flex>
          <Flex direction={'column'}>
            <FormLabel as='legend'>
              {t('orderOverview.alertSettings.alertValue')}
            </FormLabel>
            <Input
              name='measure'
              placeholder={'e.g. 30'}
              required
              onChange={(event) => setMeasure(event.currentTarget.value)}
            />
          </Flex>
          <Button
            type='submit'
            variant='primary'
            alignSelf={'end'}
            id='saveAlert'
          >
            {t('orderOverview.alertSettings.saveAlertBtn')}
          </Button>
        </SimpleGrid>
      </FormControl>
    </chakra.form>
  )
}
