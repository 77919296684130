import React, {useState} from 'react'
import MapGL, {
  FullscreenControl,
  GeolocateControl,
  NavigationControl,
  ScaleControl,
  WebMercatorViewport,
} from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import '../../css/ReactMapGL.css'
import {addAlpha, getMinOrMax} from '../../utils/utils'
import {Flex, Stack} from '@chakra-ui/layout'
import {MAPBOX_TOKEN} from '../../mapbox-API-token'
import graphColors from "../../theme/graphColors.json";
import PolylineOverlay from "./RenderPolylineOverlay";
import Cell from "./RenderCells";
import LocationsTooltips from "./RenderLocationsTooltip";
import { useTranslation } from 'react-i18next'

const isEqual = require('react-fast-compare')

const ReactMapGL1 = React.memo(({ data = [],  isZoom, setClickedSensorId}) => {
      const { t } = useTranslation()
      const [viewport, setViewport] = useState({
        width: '100vw',
        height: '30rem',
        latitude: 52.52,
        longitude: 13.409016,
        zoom: isZoom ? 6 : 8, //condition added for sensor-detail page since there is always only 1 marker
      })
      const [dataUpdated, setDataUpdated] = useState([])
      const [filteredData, setFilteredData] = useState([])

      const MAP_CONFIG = {
        mapStyle: 'mapbox://styles/mapbox/streets-v11',
        mapboxApiAccessToken: MAPBOX_TOKEN,
      }

      const getBounds = (markers) => {
        const maxLat = getMinOrMax(markers, 'max', 'latitude')
        const minLat = getMinOrMax(markers, 'min', 'latitude')
        const maxLng = getMinOrMax(markers, 'max', 'longitude')
        const minLng = getMinOrMax(markers, 'min', 'longitude')

        const southWest = [minLng, minLat]
        const northEast = [maxLng, maxLat]
        return [southWest, northEast]
      }

      React.useEffect(() => {
        const dataBounds = []
        if (data.length) {
          setDataUpdated((previousUpdated)=> {
            if(data && data.length) {
              if((!previousUpdated || !previousUpdated.length) || previousUpdated.length < data.length) {
                return data.map((d, indexData) => {
                  const coordinates = []
                  let locationUpdated = d.locations
                  if (d.locations.length) {
                    dataBounds.push({
                      "latitude": d.locations[d.locations.length - 1]["lat"],
                      "longitude": d.locations[d.locations.length - 1]["lon"]
                    })
                    locationUpdated = d.locations.map(l => {
                      coordinates.push([l["lon"], l["lat"]])
                      return {...l, showPopup: false}
                    })
                  }
                  return {
                    ...d,
                    "coordinates": coordinates,
                    "locations": locationUpdated,
                    "legendClicked": "legendClicked" in d,
                    "legendDisabled": !d.legendClicked && !(locationUpdated && locationUpdated.length),
                    "color": graphColors[indexData]}
                })
              } else {
                return previousUpdated.map((f) => {
                  const serverSensorData = data.find(g => g.device_id === f.device_id)
                  let coordinates = []
                  serverSensorData.locations.forEach((l) => {
                    coordinates.push([l["lon"], l["lat"]])
                  })
                  return {...f, "locations": serverSensorData.locations, "coordinates": coordinates}
                })
              }

            }
          })
          if (dataBounds.length && isZoom) {
            const MARKERS_BOUNDS = getBounds(dataBounds)
            setViewport(prev => {
              const result = new WebMercatorViewport({...prev})
                  .fitBounds(MARKERS_BOUNDS, {
                    padding: 50,
                  });
              return {...result, zoom: prev.zoom}
            } );
          }
        }
      },[data, isZoom])
      React.useEffect(() => {
        setFilteredData(()=> {
          return dataUpdated.filter(f => !f.legendDisabled)
        })
      }, [dataUpdated]);

      React.useEffect(() => {
        if (!isZoom && data.length && data[0].locations.length) {
          setViewport(prev => {
            return {...prev, latitude: data[0].locations[data[0].locations.length - 1]["lat"],
              longitude: data[0].locations[data[0].locations.length - 1]["lon"]}
          } );
        }
      },[data, isZoom])

      const mouseEnter = (data, location) => {
        setDataUpdated(prev => {
          return prev.map(d => {
            if (d.device_id_short === data.device_id_short) {
              d.locations.map(l =>  {
                if (l.lat === location.lat && l.lon === location.lon) {
                  l.showPopup = true;
                }
                return l
              })
            }
            return {...d, locations: d.locations}
          })
        })
      }

      const mouseLeave = () => {
        setDataUpdated(prev => {
          return prev.map(d => {
            d.locations.map(l => {
              l.showPopup = false;
              return l
            })
            return {...d, locations: d.locations}
          })
        })
      }

      const onLegendItemClicked = (device) => {
        if (device.legendDisabled) {
          setClickedSensorId(device.device_id)
        }
        if (device.legendDisabled) {
          const checkedDeviceLegend = dataUpdated.find(f => f.device_id_short === device.device_id_short)
          setFilteredData(prevfilteredData => {
            return [...prevfilteredData, checkedDeviceLegend]
          })
        } else {
          setFilteredData(prevfilteredData => {
            return prevfilteredData.filter(f => f.device_id_short !== device.device_id_short)
          })
        }
        setDataUpdated(prev => {
          return prev.map(d => {
            return {...d, legendClicked: d.device_id_short === device.device_id_short ? true: d.legendClicked, legendDisabled: d.device_id_short === device.device_id_short ? !d.legendDisabled: d.legendDisabled}
          })
        })
      }
      const adjustColor = (color, amount) => {
        return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
      }
      return (
          <>
            <MapGL
                {...viewport}
                {...MAP_CONFIG}
                onViewportChange={(viewport) => setViewport(viewport)}
            >
              {!!filteredData && !!filteredData.length && filteredData.map((d, indexData) => {
                return (
                    d.locations && d.locations.length ? (
                        <div key={`${indexData}_cell_container`}>
                          <Cell key={`${indexData}_cell`} locations={d["locations"]} color={d.color}
                                viewport={viewport} indexSensor={indexData} colorNonCell={adjustColor(d.color, 20)}/>
                          <PolylineOverlay key={`${indexData}_line`} points={d["coordinates"]} color={addAlpha(d.color, 0.6)}/>
                        </div>
                    ) : null

                )
              })}
              {!!filteredData && !!filteredData.length && filteredData.map((d, indexData) => {
                return  (
                    d.locations && d.locations.length ?
                        (<LocationsTooltips  key={`${indexData}_tooltip`} locations={d["locations"]} indexSensor={indexData}
                                             deviceId={d.device_id_short} deviceInfo={d.sensor_info}
                                             deviceColor={d.color}
                                             onEnter={(loc) => mouseEnter(d, loc)} onLeave={mouseLeave}/>
                        ): null
                )
              })
              }
              <Flex h='full' direction='column' m='3'>
                <Stack direction='column' w='29px'>
                  <GeolocateControl
                      positionOptions={{ enableHighAccuracy: true }}
                      trackUserLocation={false}
                      showUserLocation={false}
                      auto
                  />
                  <FullscreenControl />
                  <NavigationControl />
                </Stack>
                <Flex h='full' mb='50px' align='flex-end'>
                  <ScaleControl />
                </Flex>
              </Flex>
            </MapGL>
            <div className="mapLegend">
              {!!dataUpdated && !!dataUpdated.length && dataUpdated.map((d, indexData) => {
                return (
                    <div key={`${indexData}_legend`} onClick={() => onLegendItemClicked(d)} className={d.legendDisabled ? "legendItemClicked": ""}>
                      <div className="circle"  style={{backgroundColor: d.color}}/>
                      <div className="text"> {d.device_id_short}</div>
                    </div>
                )
              })
              }
            </div>
            <div className="positionsIndicator">
              <div>
                <div className="circle"/>
                <div className="text">{t('orderOverview.map.cellPosition')}</div>
              </div>
              <div>
                <div className="triangle"/>
                <div className="text">{t('orderOverview.map.nonCellPosition')}</div>
              </div>
            </div>
          </>
      )
    }
)

export default React.memo(ReactMapGL1, isEqual)
