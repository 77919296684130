import React, { useState, useEffect, useRef } from 'react'
import {
  chakra,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Button,
  VStack,
  Link,
  Flex,
  Text,
  Alert,
  AlertIcon,
} from '@chakra-ui/react'
import { FlatPickr as DatePicker } from '../components/DatePicker'
import Select from '../components/Select'
import { useTranslation } from 'react-i18next'

const ModalExtended = (props) => {
  const { t } = useTranslation()
  const [dateTimeStart, setDateTimeStart] = useState()
  const [dateTime, setDateTime] = useState()
  const [selectItem, setSelectItem] = useState()
  const [genericSelectItem, setGenericSelectItem] = useState()
  const [defaultSelect, setDefaultSelect] = useState(true)
  const [defaultGenericSelect, setDefaultGenericSelect] = useState(true)
  const saveRef = useRef(null)
  const inputRef = useRef(null)
  const inputRefGeneric = useRef(null)
  const inputSecondRefGeneric = useRef(null)

  const handleSubmit = (e) => {
    e.preventDefault()
    props.onClose()
    props.handleSubmit({
      inputRef: inputRef && inputRef.current ? inputRef.current.value : null,
      inputRefGeneric:
        (inputRefGeneric &&
          inputRefGeneric.current &&
          inputRefGeneric.current.value) ||
        null,
      inputSecondRefGeneric:
        (inputSecondRefGeneric &&
          inputSecondRefGeneric.current &&
          inputSecondRefGeneric.current.value) ||
        null,
      dateTimeStart: dateTimeStart ? dateTimeStart : new Date(),
      dateTime: dateTime ? dateTime : new Date(),
      selectItem: selectItem,
      genericSelectItem: genericSelectItem,
      key: props.options[0].key,
    })
    if (props.isFail) {
      setDefaultSelect(true)
      setDefaultGenericSelect(true)
    } else {
      setDefaultSelect(false)
      setDefaultGenericSelect(false)
    }
    setSelectItem()
    setGenericSelectItem()
  }

  useEffect(() => {
    if (props.isOpen) {
    } else {
      setDefaultSelect(true)
      setDefaultGenericSelect(true)
      setSelectItem()
      setGenericSelectItem()
    }
  }, [props.isOpen])

  return (
    <>
      {props.options ? (
        <Modal
          initialFocusRef={props.initialRef}
          finalFocusRef={props.finalRef}
          isOpen={props.isOpen}
          onClose={props.onClose}
          isCentered
          trapFocus={false}
        >
          <chakra.form
            data-testid='formModal'
            onSubmit={(e) => {
              handleSubmit(e)
            }}
            {...props}
          >
            <ModalOverlay />
            <ModalContent color='primaryText'>
              <ModalHeader as='div'>{props.modalTitle}</ModalHeader>
              <ModalCloseButton data-testid='closeModal' />
              <ModalBody>
                <VStack data-testid='modalForm' spacing={6}>
                  {props.options.map((items, index) => (
                    <FormControl key={index}>
                      {
                        {
                          dateSecondary: (
                            <>
                              {items.defaultDate &&
                                !dateTimeStart &&
                                setDateTimeStart(items.defaultDate)}
                              <FormLabel
                                data-testid='modalLabelDate'
                                as='div'
                                htmlFor='published-date'
                              >
                                {items.title}
                              </FormLabel>
                              <DatePicker
                                data-enable-time
                                options={{
                                  maxDate: items.maxDate && items.maxDate,
                                  minDate: items.minDate && items.minDate,
                                  dateFormat: 'Y-m-d H:i',
                                }}
                                value={
                                  items.defaultDate
                                    ? items.defaultDate
                                    : dateTimeStart
                                }
                                onChange={(e) => setDateTimeStart(e[0])}
                              />
                            </>
                          ),
                          date: (
                            <>
                              {items.defaultDate &&
                                !dateTime &&
                                setDateTime(items.defaultDate)}
                              <FormLabel
                                data-testid='modalLabelDate'
                                as='div'
                                htmlFor='published-date'
                              >
                                {items.title}
                              </FormLabel>
                              <DatePicker
                                data-enable-time
                                options={{
                                  maxDate: items.maxDate && items.maxDate,
                                  minDate: items.minDate && items.minDate,
                                  dateFormat: 'Y-m-d H:i',
                                }}
                                value={
                                  items.defaultDate
                                    ? items.defaultDate
                                    : dateTime
                                }
                                onChange={(e) => setDateTime(e[0])}
                              />
                            </>
                          ),
                          email: (
                            <>
                              <FormLabel data-testid='modalLabelEmail' as='div'>
                                {items.title}
                              </FormLabel>
                              <Input
                                data-testId='emailModalExtended'
                                type='email'
                                ref={inputRef}
                                defaultValue={items.defaultValue}
                                placeholder={items.placeholder}
                                required
                              />
                            </>
                          ),
                          confirm: (
                            <Text as='div' ref={inputRef}>
                              {items.title}
                            </Text>
                          ),
                          select: (
                            <>
                              <FormLabel as='div' htmlFor='published-date'>
                                {items.title}
                              </FormLabel>
                              <Select
                                key={
                                  items.options &&
                                  items.options.length > 0 &&
                                  JSON.stringify(items.options[0].id)
                                }
                                cacheOptions={false}
                                onChange={(e) => setSelectItem(e.id)}
                                options={items.options}
                                defaultValue={
                                  defaultSelect
                                    ? items.defaultValue
                                    : items.options &&
                                      items.options.length > 0 && {
                                        value: items.options[0].id,
                                        label: items.options[0].id,
                                      }
                                }
                                placeholder={selectItem || t('select')}
                                closeMenuOnSelect={true}
                                size='md'
                              />
                            </>
                          ),
                          select_generic: (
                            <>
                              <FormLabel as='div' htmlFor='published-date'>
                                {items.title}
                              </FormLabel>
                              <Select
                                key={
                                  items.options &&
                                  items.options.length > 0 &&
                                  JSON.stringify(items.options[0].id)
                                }
                                cacheOptions={false}
                                onChange={(e) => setGenericSelectItem(e.id)}
                                options={items.options}
                                defaultValue={
                                  defaultGenericSelect
                                    ? items.defaultValue
                                    : items.options &&
                                      items.options.length > 0 && {
                                        value: items.options[0].id,
                                        label: items.options[0].label,
                                      }
                                }
                                placeholder={genericSelectItem || t('select')}
                                closeMenuOnSelect={true}
                                size='md'
                              />
                            </>
                          ),
                          null: (
                            <>
                              <FormLabel data-testid='labelModal' as='div'>
                                {items.title}
                              </FormLabel>
                              <Input
                                maxLength={
                                  items.maxLength ? items.maxLength : null
                                }
                                data-testid='inputModalExtended'
                                ref={inputRef}
                                defaultValue={items.defaultValue}
                                placeholder={items.placeholder}
                                required={
                                  items.required === false
                                    ? items.required
                                    : true
                                }
                              />
                            </>
                          ),
                          input_generic: (
                            <>
                              <FormLabel
                                data-testid={
                                  items.dataTestId || 'labelModalInput'
                                }
                                as='div'
                              >
                                {items.title}
                              </FormLabel>
                              <Input
                                data-testid='inputGenericModalExtended'
                                ref={inputRefGeneric}
                                defaultValue={items.defaultValue}
                                placeholder={items.placeholder}
                                required={
                                  items.required === false
                                    ? items.required
                                    : true
                                }
                              />
                            </>
                          ),
                          input_second_generic: (
                            <>
                              <FormLabel
                                data-testid='labelModalSecondInput'
                                as='div'
                              >
                                {items.title}
                              </FormLabel>
                              <Input
                                data-testid='secondInputGenericModalExtended'
                                ref={inputSecondRefGeneric}
                                defaultValue={items.defaultValue}
                                placeholder={items.placeholder}
                                required={
                                  items.required === false
                                    ? items.required
                                    : true
                                }
                              />
                            </>
                          ),
                          link: (
                            <Link
                              position='absolute'
                              mt='-6'
                              onClick={() =>
                                typeof props.callback === 'function' &&
                                props.callback(items.callbackKey)
                              }
                            >
                              {items.title}
                            </Link>
                          ),
                          alert: (
                            <Alert
                              status={items.alertType}
                              variant='left-accent'
                            >
                              <AlertIcon />
                              {items.title}
                            </Alert>
                          ),
                        }[items.type]
                      }
                      <FormHelperText data-testId='helperModalExtended'>
                        {items.helperText}
                      </FormHelperText>
                    </FormControl>
                  ))}
                </VStack>
              </ModalBody>
              <ModalFooter>
                {props.options.map(
                  (items, index) =>
                    ({
                      save: (
                        <Button
                          data-testId='submitModalExtended'
                          key={index}
                          type='submit'
                          ref={saveRef}
                          variant='primary'
                          mr={5}
                        >
                          {items.saveButtonText
                            ? items.saveButtonText
                            : t('saveBtnTxt')}
                        </Button>
                      ),
                      remove: (
                        <Button
                          data-testid='deleteSubmit'
                          key={index}
                          type='submit'
                          ref={saveRef}
                          variant='negative'
                          mr={5}
                        >
                          {items.deleteButtonText
                            ? items.deleteButtonText
                            : t('delete')}
                        </Button>
                      ),
                      edit: (
                        <Flex key={index} w='full'>
                          <Flex pl='2' w='full' align='center' justify='start'>
                            <Link
                              data-testid='deleteModelExtended'
                              onClick={() =>
                                typeof props.callback === 'function' &&
                                props.callback(items.callbackKey)
                              }
                              color='negative'
                            >
                              {items.deleteButtonText
                                ? items.deleteButtonText
                                : t('delete')}
                            </Link>
                          </Flex>
                          <Flex justify='end'>
                            <Button
                              data-testid='submitModelExtended'
                              type='submit'
                              ref={saveRef}
                              variant='primary'
                              mr={5}
                            >
                              {t('saveBtnTxt')}
                            </Button>
                          </Flex>
                        </Flex>
                      ),
                    }[items.button])
                )}
                <Button
                  data-testid='cancelModelExtended'
                  onClick={props.onClose}
                >
                  {t('cancelBtnTxt')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </chakra.form>
        </Modal>
      ) : (
        ''
      )}
    </>
  )
}

export default ModalExtended
