export const downloadData = (orderNumber, response) => {
  if (response && response.data) {
    const type = response.headers['content-type']
    const blob = new Blob([response.data], {
      type: type,
      encoding: 'UTF-8',
    })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = orderNumber + '.pdf'
    link.click()
  }
}
