import { Box } from '@chakra-ui/react'
import * as React from 'react'

export const ScrollArea = (props) => (
  <Box
    overflowY='auto'
    height='80vh'
    minH='px'
    maxH='full'
    {...props}
    sx={{
      '&::-webkit-scrollbar-track': {
        bg: 'transparent',
      },
      '&::-webkit-scrollbar': {
        width: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        bg: 'whiteAlpha.200',
        borderRadius: '20px',
      },
    }}
  />
)
