import { Box } from '@chakra-ui/react'
import * as React from 'react'
import { HiMenu, HiX } from 'react-icons/hi'

export const MobileMenuButton = (props) => {
  const { onClick, isOpen } = props
  return (
    <Box
      data-testid='mobileMenu'
      alignSelf='start'
      display={{
        base: 'block',
        md: 'none',
      }}
      as='button'
      type='button'
      rounded='md'
      p='5'
      fontSize='5xl'
      color='tertiary'
      onClick={onClick}
    >
      <Box srOnly>{isOpen ? 'Close Menu' : 'Open Menu'}</Box>
      {isOpen ? <HiX /> : <HiMenu />}
    </Box>
  )
}
