import React from "react";
import isEqual from "react-fast-compare";
import {Marker, Popup} from "react-map-gl";
import {Text, VStack} from "@chakra-ui/layout";
import {addAlpha} from "../../utils/utils";
import moment from "moment";
import {useTranslation} from "react-i18next";

const LocationsTooltips = React.memo(({ locations, indexSensor, deviceId, deviceInfo, deviceColor, onEnter, onLeave}) => {

    const {t} = useTranslation()

    return (<>
            <div key={`${indexSensor}_sensor_popup`}>
                {locations && locations.length &&
                    <Popup
                        key={`${indexSensor}_popup`}
                        latitude={locations[locations.length - 1]["lat"]}
                        longitude={locations[locations.length - 1]["lon"]}
                        closeButton={false}
                        anchor={indexSensor % 2 === 1 ? 'bottom' : 'top'}
                        className='fill'
                    >
                        <VStack align='stretch' style={{backgroundColor: addAlpha(deviceColor, 0.8), color: "white"}}>
                            <Text fontSize='xs' fontWeight='bold' className='innerText'>
                                {deviceId}
                            </Text>
                            <Text fontSize='xs' className='innerText'>
                                {deviceInfo}
                            </Text>
                        </VStack>
                    </Popup>
                }
            </div>
            {locations && locations.length && locations.map((l, indexLoc) => {
                return <div key={`${indexSensor}_${indexLoc}_location_popup`}>
                    <Marker
                        id={`${indexSensor}_${indexLoc}_marker`}
                        key={`${indexSensor}_${indexLoc}_marker`}
                        latitude={l["lat"]}
                        longitude={l["lon"]}>
                        <button onMouseEnter={() => onEnter(l)} onMouseLeave={() => onLeave()}>
                            <Text style={{color: "#00000000"}}>
                                Nothing
                            </Text>
                        </button>
                    </Marker>
                    {
                        l.showPopup && <Popup
                            key={`${indexSensor}_${indexLoc}_popup`}
                            latitude={l["lat"]}
                            longitude={l["lon"]}
                            closeButton={false}
                            anchor={indexSensor % 2 === 1 ? 'left' : 'right'}
                            className='fill'
                        >
                            <VStack align='stretch'
                                    style={{backgroundColor: addAlpha(deviceColor, 0.8), color: "white"}}>
                                <Text fontSize='xs' className='innerText' fontWeight='bold'>
                                    {moment(l["time"]).format('DD-MM-YY HH:mm')}
                                </Text>
                                <Text fontSize='xs' className='innerText'>
                                    <Text fontSize='xs' fontWeight='bold' className='innerText'
                                          style={{display: "inline"}}>{t('temperature')}: </Text>
                                    <Text fontSize='xs' className='innerText' style={{display: "inline"}}>
                                        {l["temperature"]} ℃
                                    </Text>
                                </Text>
                                <Text fontSize='xs' className='innerText'>
                                    <Text fontSize='xs' fontWeight='bold' className='innerText'
                                          style={{display: "inline"}}>{t('humidity')}: </Text>
                                    <Text fontSize='xs' className='innerText' style={{display: "inline"}}>
                                        {l["humidity"]} (% r.H.)
                                    </Text>
                                </Text>
                            </VStack>
                        </Popup>
                    }
                </div>
            })
            }
        </>
    )
})

export default React.memo(LocationsTooltips, isEqual)