import { Box, Flex, Stack, Center } from '@chakra-ui/react'
import * as React from 'react'
import { MobileMenuButton } from './MobileMenuButton'
import { ScrollArea } from './ScrollArea'
import { SidebarLink } from './SidebarLink'
import { useMobileMenuState } from './useMobileMenuState'
import { NavLink } from 'react-router-dom'
import { Logo } from '../Logo'

export const Sidebar = (props) => {
  const { isOpen, toggle, off } = useMobileMenuState()
  return (
    <Flex
      height='100vh'
      bg={props.bg ? props.bg : 'tertiary'}
      overflow='hidden'
      sx={{
        '--sidebar-width': '16rem',
      }}
      position='fixed'
      width='full'
    >
      <Box
        data-testid='sideBar'
        as='nav'
        display='block'
        flex='1'
        width='var(--sidebar-width)'
        left='0'
        py='5'
        px='3'
        position='fixed'
      >
        <Box fontSize='sm' lineHeight='tall'>
          <Box data-testid='sideBarLogo' display='block' transition='background 0.1s'>
            <Center>
              <Box
                p='3'
                display='block'
                transition='background 0.1s'
                _hover={{ bg: 'whiteAlpha.200' }}
                rounded='xl'
                whiteSpace='nowrap'
              >
                <NavLink  to='/' exact>
                  <Logo h='10' color={props.logoColor ? props.logoColor : 'logo'} />
                </NavLink>
              </Box>
            </Center>
          </Box>
          <ScrollArea pt='10' pb='20'>
            <Flex h='full' flexDirection='column'>
              <Stack w='100%' pb='6'>
                {props.directory.map(
                  ({ text, link, icon, align, itemHandler, id }, index) => (
                    <React.Fragment key={index}>
                      {align === 'end' ? (
                        ''
                      ) : (
                        <SidebarLink id = {id} data-testid = {link}
                          textColor={props.textColor}
                          w='full'
                          icon={icon}
                          link={link}
                          onClick={off}
                          itemHandler={itemHandler}
                        >
                          {text}
                        </SidebarLink>
                      )}
                    </React.Fragment>
                  )
                )}
              </Stack>
              <Flex h='full' align='flex-end'>
                <Stack data-testid='sidebarStack' w='100%' pb='6'>
                  {props.directory.map(
                    ({ text, link, icon, align, itemHandler, id }, index) => (
                      <React.Fragment key={index}>
                        {align === 'end' ? (
                          <SidebarLink id= {id} data-testid = {link}
                            textColor={props.textColor}
                            w='full'
                            icon={icon}
                            link={link}
                            onClick={off}
                            itemHandler={itemHandler}
                          >
                            {text}
                          </SidebarLink>
                        ) : (
                          ''
                        )}
                      </React.Fragment>
                    )
                  )}
                </Stack>
              </Flex>
            </Flex>
          </ScrollArea>
        </Box>
      </Box>
      <Box
        flex='1'
        marginStart={{
          md: 'var(--sidebar-width)',
        }}
        position='relative'
        left={isOpen ? 'var(--sidebar-width)' : '0'}
        transition='left 0.2s'
        overflow='auto'
        bg='white'
        height='100%'
      >
        <Flex direction='column' align='center' height='full' width='full'>
          <MobileMenuButton onClick={toggle} isOpen={isOpen} />
          <Box w='full' onClick={off}>
            {props.app}
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}
