import { extendTheme } from '@chakra-ui/react'
import brandColors from './brandColors.json'

const Heading = {
  baseStyle: {
    fontWeight: 'base',
  },
  variants: {
    primary: { fontWeight: 'bold', size: 'xs' },
  },
}

const Link = {
  baseStyle: {
    fontWeight: 'base',
    color: 'linkText',
    textDecoration: 'underline',
  },
}
const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const Button = {
  baseStyle: {
    fontWeight: 'bold',
    color: 'primary',
    textTransform: null,
    borderRadius: 'md',
  },
  defaultProps: {},
  variants: {
    primary: {
      size: 'md',
      background: 'tertiary',
      color: 'secondaryText',
      _hover: {
        background: 'tertiaryOnChange',
        color: 'tertiaryText',
        _disabled: {
          background: 'dimmed',
          color: 'black',
        },
      },
      _disabled: {
        color: 'black',
        background: 'dimmed',
      },
    },
    primaryCurved: {
      size: 'md',
      background: 'tertiary',
      borderRadius: 'full',
      color: 'secondaryText',
      _hover: { background: 'tertiaryOnChange', color: 'tertiaryText' },
      _disabled: {
        background: 'secondary',
        _hover: {
          background: '',
          color: '',
        },
      },
    },
    secondaryCurved: {
      size: 'md',
      borderRadius: 'full',
      _hover: { background: 'tertiaryOnChange', color: 'tertiaryText' },
      _disabled: {
        background: 'secondary',
        _hover: {
          background: '',
          color: '',
        },
      },
    },
    primaryInvertedCurved: {
      size: 'md',
      background: 'tertiaryOnChange',
      borderRadius: 'full',
      color: 'tertiaryText',
      _hover: {
        background: 'tertiary',
        color: 'secondaryText',
        _disabled: {
          background: 'dimmed',
          color: 'black',
        },
      },
      _disabled: {
        color: 'black',
        background: 'dimmed',
      },
    },
    negative: {
      size: 'md',
      background: 'negative',
      color: 'white',
      _hover: { background: 'negativeOnChange', color: 'white' },
      _disabled: {
        background: 'secondary',
        _hover: {
          background: '',
          color: '',
        },
      },
    },
  },
}
const theme = extendTheme({
  colors: {
    primary: brandColors.primary,
    primaryOnChange: brandColors.primaryOnChange,
    primaryText: brandColors.primaryText,
    primaryTextOnChange: brandColors.primaryTextOnChange,
    primaryDarkBackground: brandColors.primaryDarkBackground,
    primaryLightBackground: brandColors.primaryLightBackground,
    secondary: brandColors.secondary,
    secondaryText: brandColors.secondaryText,
    secondaryTextOnChange: brandColors.secondaryTextOnChange,
    tertiary: brandColors.tertiary,
    tertiaryText: brandColors.tertiaryText,
    tertiaryOnChange: brandColors.tertiaryOnChange,
    dimmed: brandColors.dimmed,
    dimmedOnChange: brandColors.dimmedOnChange,
    negativeText: brandColors.negative,
    negative: brandColors.negative,
    negativeOnChange: brandColors.negativeOnChange,
    positive: brandColors.positive,
    meduim: brandColors.meduim,
    warning: brandColors.warning,
    linkText: brandColors.linkText,
    logo: brandColors.logo,
    switch: {
      500: brandColors.tertiary,
    },
  },
  components: {
    Button,
    Heading,
    Link,
  },
  styles: {
    global: () => ({
      'html, body': {
        color: 'primaryText',
        fontWeight: 'light',
      },
      a: {
        color: 'linkText',
      },
    }),
  },
  config,
})

export default theme
