import axios from 'axios'
import {
  getAccessToken,
  interceptorRequestHandler,
  responseSuccessHandler,
} from './common'

const DOMAIN_LINK = process.env.REACT_APP_DOMAIN_API_URL

const axiosInstance = axios.create({
  baseURL: DOMAIN_LINK,
  responseType: 'blob',
  headers: {
    'Content-Type': 'application/blob',
    Accept: '*/*',
    Authorization: 'Bearer ' + getAccessToken(),
  },
})

/*Add request interceptor for initial 401 unauth check*/
axiosInstance.interceptors.request.use((request) =>
  interceptorRequestHandler(request)
)

/* Response interceptor is added for domain apis to refresh token on receiving token expired error
 * It will catch each apis response and after refreshing the token, it will fire the request again
 * So the pages can have refreshed data
 * */
axiosInstance.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  function (error) {
    const originalRequest = error.config
    let refreshToken = localStorage.getItem('refreshToken')

    if (
      refreshToken &&
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      return axios
        .post(process.env.REACT_APP_API_ROOT_URL + `/refresh_token`, {
          refresh_token: refreshToken,
        })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem(
              'accessToken',
              res.data.credentials.access_token
            )
            localStorage.setItem(
              'refreshToken',
              res.data.credentials.refresh_token
            )
            localStorage.setItem('idToken', res.data.credentials.id_token)
            error.config.headers.Authorization =
              'Bearer ' + res.data.credentials.access_token
            return axiosInstance.request(originalRequest)
          }
        })
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
