import React, { useEffect, useState } from 'react'
import {
  withRouter,
  Link as ReactRouterLink,
  useLocation,
} from 'react-router-dom'
import TableExtended from '../components/TableExtended'
import {
  Box,
  Button,
  Flex,
  Text,
  Link,
  useDisclosure,
  VStack,
  HStack,
  useMediaQuery,
  Center,
  Fade,
  Heading,
  Icon,
  Tooltip,
} from '@chakra-ui/react'
import {
  WiFlood,
  WiThermometer,
  WiHumidity,
  WiSmoke,
  WiDaySunny,
  WiCloudDown,
} from 'react-icons/wi'
import ModalExtended from '../components/ModalExtended'
import Loading from '../components/Loading'
import IconCircle from '../components/IconCircle'
import { EditIcon } from '@chakra-ui/icons'
import {
  useCustomer,
  usePostCustomer,
  usePatchCustomer,
  useDeleteCustomer,
} from '../services/customer'
import { usePostOrder } from '../services/orders'
import { useTranslation } from 'react-i18next'
import { CreateDateTimeHTML } from '../utils/utils'
import DocumentTitle from 'react-document-title'
import { GetModal } from '../utils/getModal'
import { DEFAULT_TABLE_PROPS } from '../utils/utils'
import { ToastCallback } from '../utils/utils'
import OrdersWebTourSecond from '../components/ordersWebTourSecond'
import OrdersWebTour from '../components/ordersWebTour'
import API from '../utils/api'
import API_DOMAIN from '../utils/apiDomain'
import gtag from 'ga-gtag'
import CookiesModal from '../components/cookiesModal'

const Dashboard = () => {
  const { t } = useTranslation()
  const { data, error, isLoading } = useCustomer()
  const [selectedCustomer, setSelectedCustomer] = useState()
  const [modalChoice, setModalChoice] = useState()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [smallScreen] = useMediaQuery('(max-width: 1024px)')
  const [previouslyLogin, setPreviouslyLogin] = useState(null)
  const location = useLocation()
  const [openModal, setOpenModal] = useState(false)
  const [profile, setProfile] = useState(null)

  useEffect(() => {
    gtag('event', 'Vitreo - ' + t('dashboard.pageTitle'))
    const getProfile = async () => {
      return await API.get('profile').then((d) => {
        return d.data && d.data.profile
      })
    }
    getProfile().then((profile) => {
      setProfile(profile)
      gtag('config', process.env.REACT_APP_GA4_MEASUREMENT_ID, {
        user_id: profile.username,
      })
      gtag('set', 'user_properties', {
        crm_id: profile.username,
      })
      const getUserDetails = async () => {
        return await API_DOMAIN.get(`/users/user-details/${profile.id}/`).then(
          (det) => {
            return det
          }
        )
      }
      getUserDetails().then((usrDetails) => {
        setPreviouslyLogin((previous) => {
          return previous == null
            ? usrDetails.data.is_previously_logged_in
            : previous
        })
        setOpenModal(usrDetails.data.is_cookie_opened == null)
        if (!usrDetails.data.is_previously_logged_in) {
          const patchUserDetails = async () => {
            return await API_DOMAIN.patch(
              `/users/user-details/${profile.id}/`,
              { is_previously_logged_in: true }
            )
          }
          patchUserDetails()
        }
      })
    })
  }, [t])

  const addCustomer = usePostCustomer(
    ToastCallback(t('dashboard.customerAdded'), t('dashboard.customerNotAdded'))
  )

  const editCustomer = usePatchCustomer(
    ToastCallback(
      t('dashboard.customerUpdated'),
      t('dashboard.customerNotUpdated')
    ),
    selectedCustomer && selectedCustomer.id
  )

  const deleteCustomer = useDeleteCustomer(
    ToastCallback(
      t('dashboard.customerDeleted'),
      t('dashboard.customerNotDeleted')
    ),
    selectedCustomer && selectedCustomer.id
  )

  const addOrder = usePostOrder(
    ToastCallback(t('dashboard.orderAdded'), t('dashboard.orderNotAdded'))
  )

  const columns = [
    {
      Header: 'null',
      columns: [
        {
          Header: t('dashboard.table.customer'),
          accessor: (d) => (
            <HStack h='full' spacing='5'>
              <Text>{d.name}</Text>
              <IconCircle
                data-testid='editCustomer'
                label={t('tooltip.editCustomer')}
                icon={<EditIcon />}
                onClick={() => {
                  handleclick('editCustomer')
                  setSelectedCustomer({ id: d.id, name: d.name })
                }}
              />
            </HStack>
          ),
        },
        {
          Header: t('dashboard.table.orderNumber'),
          accessor: (d) => (
            <VStack data-testid='addOrder' {...DEFAULT_TABLE_PROPS.vstack}>
              {d.order_set.map((items, i) => {
                return (
                  <Link
                    data-testid='orderLink'
                    as={ReactRouterLink}
                    to={{
                      pathname: `/dashboard/order/${items.id}`,
                      state: { order_id: items.id },
                    }}
                    key={i}
                  >
                    {items.order_number}
                  </Link>
                )
              })}
              <Link
                data-testid='addOrderLink'
                onClick={() => {
                  handleclick('addOrder')
                  setSelectedCustomer({ id: d.id, name: d.name })
                }}
              >
                {t('dashboard.table.addOrderLink')}
              </Link>
            </VStack>
          ),
        },
        {
          Header: t('dashboard.table.shippingDate'),
          accessor: (d) => (
            <VStack {...DEFAULT_TABLE_PROPS.vstack}>
              {d.order_set.map((items, i) => {
                return (
                  <Text key={i}>{CreateDateTimeHTML(items.shipping_date)}</Text>
                )
              })}
            </VStack>
          ),
        },
        {
          Header: t('dashboard.table.alerts'),
          accessor: (d) => (
            <VStack {...DEFAULT_TABLE_PROPS.vstack}>
              {d.order_set.map((items, i) => {
                return (
                  <HStack key={i}>
                    <Tooltip label={t('fillLevelTooltip')}>
                      <Center {...DEFAULT_TABLE_PROPS.centerIcon}>
                        <Icon
                          as={WiFlood}
                          w={7}
                          h={7}
                          {...DEFAULT_TABLE_PROPS.iconProps}
                          color={
                            items.alerts_status &&
                            items.alerts_status.fill_level
                              ? 'negative'
                              : ''
                          }
                        />
                      </Center>
                    </Tooltip>
                    <Tooltip label={t('temperature')}>
                      <Center {...DEFAULT_TABLE_PROPS.centerIcon}>
                        <Icon
                          as={WiThermometer}
                          w={7}
                          h={7}
                          {...DEFAULT_TABLE_PROPS.iconProps}
                          color={
                            items.alerts_status &&
                            items.alerts_status.temperature
                              ? 'negative'
                              : ''
                          }
                        />
                      </Center>
                    </Tooltip>
                    <Tooltip label={t('humidity')}>
                      <Center {...DEFAULT_TABLE_PROPS.centerIcon}>
                        <Icon
                          as={WiHumidity}
                          w={7}
                          h={7}
                          {...DEFAULT_TABLE_PROPS.iconProps}
                          color={
                            items.alerts_status && items.alerts_status.humidity
                              ? 'negative'
                              : ''
                          }
                        />
                      </Center>
                    </Tooltip>
                    <Tooltip label={t('airQuality')}>
                      <Center {...DEFAULT_TABLE_PROPS.centerIcon}>
                        <Icon
                          as={WiSmoke}
                          w={7}
                          h={7}
                          {...DEFAULT_TABLE_PROPS.iconProps}
                          color={
                            items.alerts_status &&
                            items.alerts_status.air_quality
                              ? 'negative'
                              : ''
                          }
                        />
                      </Center>
                    </Tooltip>
                    <Tooltip label={t('airPressure')}>
                      <Center {...DEFAULT_TABLE_PROPS.centerIcon}>
                        <Icon
                          as={WiCloudDown}
                          w={7}
                          h={7}
                          {...DEFAULT_TABLE_PROPS.iconProps}
                          color={
                            items.alerts_status &&
                            items.alerts_status.air_pressure
                              ? 'negative'
                              : ''
                          }
                        />
                      </Center>
                    </Tooltip>
                    <Tooltip label={t('ambientLight')}>
                      <Center {...DEFAULT_TABLE_PROPS.centerIcon}>
                        <Icon
                          as={WiDaySunny}
                          w={7}
                          h={7}
                          {...DEFAULT_TABLE_PROPS.iconProps}
                          color={
                            items.alerts_status && items.alerts_status.amb_light
                              ? 'negative'
                              : ''
                          }
                        />
                      </Center>
                    </Tooltip>
                  </HStack>
                )
              })}
            </VStack>
          ),
        },
      ],
    },
  ]

  const handleclick = (e) => {
    onOpen()
    setModalChoice(e)
  }

  const handleSubmit = (data) => {
    if (data.key === 'addCustomer') {
      gtag('event', 'Create Customer')
      addCustomer.mutate({
        name: data.inputRef,
      })
    }
    if (data.key === 'editCustomer') {
      gtag('event', 'Edit Customer')
      editCustomer.mutate({
        name: data.inputRef,
      })
    }
    if (data.key === 'deleteCustomer') {
      gtag('event', 'Delete Customer')
      deleteCustomer.mutate()
    }
    if (data.key === 'addOrder') {
      gtag('event', 'Create Order')
      addOrder.mutate({
        customer: selectedCustomer.id,
        shipping_date: data.dateTime,
        order_number: data.inputRef,
      })
    }
  }

  const patchUserDetails = async (cookiesEvent) => {
    console.log('profile for patch', profile)
    return await API_DOMAIN.patch(`/users/user-details/${profile.id}/`, {
      is_cookie_opened: cookiesEvent,
    })
  }
  const onAcceptListener = () => {
    console.log('accept')
    patchUserDetails(true)
    setOpenModal(false)
  }

  const onRefuseListener = () => {
    console.log('refuse')
    patchUserDetails(false)
    setOpenModal(false)
  }

  return (
    <div>
      <CookiesModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onAccept={onAcceptListener}
        onRefuse={onRefuseListener}
      />

      {location.search && location.search.includes('fromSensor') && (
        <OrdersWebTourSecond />
      )}
      {((previouslyLogin === false && !location.search) ||
        location.search.includes('clickItem')) && <OrdersWebTour />}
      <DocumentTitle title={'Vitreo - ' + t('dashboard.pageTitle')}>
        <Box>
          <Heading data-testid='textDashboard' as='h2' mb={8}>
            {t('dashboard.pageTitle')}
          </Heading>
          <Flex justify='flex-end' mb={smallScreen ? '5' : '10'}>
            <Button
              data-testid='addCustomerButtonDashboard'
              id='addCustomer'
              onClick={() => handleclick('addCustomer')}
              variant='primaryCurved'
            >
              {'+ ' + t('dashboard.addCustomer')}
            </Button>
          </Flex>
          {(isLoading || previouslyLogin === null) && <Loading />}
          {error || (data && data.message) ? (
            <Text>{t('errorNoData')}</Text>
          ) : null}
          {previouslyLogin !== null && data && Array.isArray(data) && (
            <Fade in>
              <TableExtended columns={columns} data={data} isPagination />
            </Fade>
          )}
          <ModalExtended
            callback={handleclick}
            options={GetModal(t, modalChoice, '', '', selectedCustomer)}
            isOpen={isOpen}
            onClose={onClose}
            handleSubmit={handleSubmit}
          />
        </Box>
      </DocumentTitle>
    </div>
  )
}
export default withRouter(Dashboard)
