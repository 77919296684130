import axios from 'axios'
import {
  interceptorRequestHandler,
  responseErrorHandler,
  responseSuccessHandler,
} from './common'

const API_LINK = process.env.REACT_APP_API_ROOT_URL

const axiosInstanceAPI = axios.create({
  baseURL: API_LINK,
  responseType: 'json',
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN',
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*',
  },
})

/*Add request interceptor for initial expired session check*/
axiosInstanceAPI.interceptors.request.use((request) =>
  interceptorRequestHandler(request)
)

/* Response interceptor is added for domain apis to refresh token on receiving token expired error
 * It will catch each apis response and after refreshing the token, it will fire the request again
 * So the pages can have refreshed data
 * */
axiosInstanceAPI.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(API_LINK, error, axiosInstanceAPI)
)

export default axiosInstanceAPI
