import API from '../utils/apiDomain'
import PUBLIC_API from '../utils/apiPublicDomain'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'
import { usePatchSensorReadings } from './apiGateway'

export const useGetSensors = () => {
  return (
    useQuery('sensor', async () => {
      return await API.get(`/devices/sensors/?no_pagination=true`)
        .then((d) => d && d.data)
        .catch((error) => error)
    }) ?? []
  )
}

export const useSensorAssignment = (
  items,
  orderId,
  id,
  sensorId,
  assignmentStart,
  assignmentEnd,
  method,
  editHistory = false
) => {
  const toast = useToast()
  const formatted_ship_start = assignmentStart
    ? new Date(assignmentStart).getUTCFullYear() +
      '-' +
      (new Date(assignmentStart).getUTCMonth() + 1) +
      '-' +
      new Date(assignmentStart).getUTCDate() +
      'T' +
      new Date(assignmentStart).getUTCHours() +
      ':' +
      new Date(assignmentStart).getUTCMinutes() +
      ':' +
      new Date(assignmentStart).getUTCSeconds() +
      'Z'
    : ''
  const formatted_ship_end = assignmentEnd
    ? new Date(assignmentEnd).getUTCFullYear() +
      '-' +
      (new Date(assignmentEnd).getUTCMonth() + 1) +
      '-' +
      new Date(assignmentEnd).getUTCDate() +
      'T' +
      new Date(assignmentEnd).getUTCHours() +
      ':' +
      new Date(assignmentEnd).getUTCMinutes() +
      ':' +
      new Date(assignmentEnd).getUTCSeconds() +
      'Z'
    : ''
  const patchedSensorReadings = usePatchSensorReadings(
    orderId,
    sensorId,
    formatted_ship_start,
    formatted_ship_end
  )
  return useMutation(
    ['sensorAssignment', id],
    async (data) => {
      return method === 'patch'
        ? await API.patch(`/devices/sensor_assigment/${id}/`, data)
        : await API.delete(`/devices/sensor_assigment/${id}/`)
    },
    {
      onSuccess: async (resp) => {
        if (method === 'patch') {
          if (!editHistory) {
            patchedSensorReadings.mutate({
              height_sensor: resp['data']['ibc_nested']['height_sensor'],
              height_ibc: resp['data']['ibc_nested']['height'],
            })
          } else {
            const formatted_ship_new_start =
              new Date(resp['data']['assignment_start']).getUTCFullYear() +
              '-' +
              (new Date(resp['data']['assignment_start']).getUTCMonth() + 1) +
              '-' +
              new Date(resp['data']['assignment_start']).getUTCDate() +
              'T' +
              new Date(resp['data']['assignment_start']).getUTCHours() +
              ':' +
              new Date(resp['data']['assignment_start']).getUTCMinutes() +
              ':' +
              new Date(resp['data']['assignment_start']).getUTCSeconds() +
              'Z'
            const formatted_ship_new_end =
              new Date(resp['data']['assignment_end']).getUTCFullYear() +
              '-' +
              (new Date(resp['data']['assignment_end']).getUTCMonth() + 1) +
              '-' +
              new Date(resp['data']['assignment_end']).getUTCDate() +
              'T' +
              new Date(resp['data']['assignment_end']).getUTCHours() +
              ':' +
              new Date(resp['data']['assignment_end']).getUTCMinutes() +
              ':' +
              new Date(resp['data']['assignment_end']).getUTCSeconds() +
              'Z'
            patchedSensorReadings.mutate({
              change_history: true,
              height_sensor: resp['data']['ibc_nested']['height_sensor'],
              height_ibc: resp['data']['ibc_nested']['height'],
              new_ship_start: formatted_ship_new_start,
              new_ship_end: formatted_ship_new_end,
            })
          }
        }
        return toast({
          position: 'bottom-right',
          title: items.success.title,
          description: items.success.description,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      },
      onError: async () => {
        return toast({
          position: 'bottom-right',
          title: items.error.title,
          description: items.error.description,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    }
  )
}

export const useRegisterSensor = (items) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  return useMutation(
    'registerSensor',
    async (id) =>
      await API.post(`/devices/sensors/${id}/register/?use_short_id=true`),
    {
      onSuccess: async (response) => {
        await queryClient.invalidateQueries()
        //already registered
        if (response.status === 200)
          return toast({
            position: 'bottom-right',
            title: items.warning.title,
            description: items.warning.description,
            status: 'warning',
            duration: 5000,
            isClosable: true,
          })
        //successfully registered
        else if (response.status === 201)
          return toast({
            position: 'bottom-right',
            title: items.success.title,
            description: items.success.description,
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
        //usually 204 status code when random number is entered
        else
          return toast({
            position: 'bottom-right',
            title: items.error.title,
            description: items.error.description,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
      },
      onError: async () => {
        return toast({
          position: 'bottom-right',
          title: items.error.title,
          description: items.error.description,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    }
  )
}

export const useGetSensorByID = (id) => {
  return (
    useQuery(['sensorWithID', id], async () => {
      return await API.get(`/devices/sensors/${id}/`)
        .then((d) => d && d.data)
        .catch((error) => error && error.response && error.response)
    }) ?? []
  )
}

export const useGetSensorReadings = (id) => {
  return (
    useQuery(
      ['sensorReadings', id],
      async () => {
        return await API.get(`/devices/sensors/${id}/readings/`)
          .then((d) => d)
          .catch((error) => error)
      },
      { enabled: !!id }
    ) ?? []
  )
}

export const useGetSensorPublicReadings = (id) => {
  return useQuery(['publicSensorReadings', id], async () => {
    return await PUBLIC_API.get(`/devices/public_sensors/${id}/`)
      .then((d) => d && d.data)
      .catch((error) => error)
  })
}

export const useGetSensorLocations = (id) => {
  return useQuery(['sensorLocations', id], async () => {
    return await API.get(`/devices/sensors/${id}/location1/`)
      .then((d) => d && d.data)
      .catch((error) => error)
  })
}

export const useSensorUpdate = (items, id) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  return useMutation(
    ['sensorUpdates', id],
    async (data) => await API.put(`/devices/sensors/${id}/`, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('sensorWithID')
        await queryClient.invalidateQueries('sensorLocations')
        return toast({
          position: 'bottom-right',
          title: items.success.title,
          description: items.success.description,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      },
      onError: async () => {
        return toast({
          position: 'bottom-right',
          title: items.error.title,
          description: items.error.description,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    }
  )
}

export const useGetIBCTypes = () => {
  return useQuery(['ibcTypes'], async () => {
    return await API.get(`/devices/ibc/`)
      .then((d) => d && d.data)
      .catch((error) => error)
  })
}

export const useRegisterIBCType = (items) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  return useMutation(
    'registerIBCType',
    async (data) => await API.post(`/devices/ibc/`, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('ibcTypes')
        return toast({
          position: 'bottom-right',
          title: items.success.title,
          description: items.success.description,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      },
      onError: async () => {
        return toast({
          position: 'bottom-right',
          title: items.error.title,
          description: items.error.description,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    }
  )
}
