import React from 'react'
import { useAuth } from '../context/useAuth'
import { Route, Redirect } from 'react-router-dom'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { authToken } = useAuth()
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {authToken ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/login' }} />
          )}
        </>
      )}
    />
  )
}
