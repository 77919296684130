import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { VStack, Heading, Text, Link } from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'
import DocumentTitle from 'react-document-title'
import gtag from 'ga-gtag'
import API from '../utils/api'

const Help = () => {
  const { t } = useTranslation()
  useEffect(() => {
    gtag('event', 'Vitreo - ' + t('help.pageTitle'))
    const getProfile = async () => {
      return await API.get('profile').then((d) => {
        return d.data && d.data.profile
      })
    }
    getProfile().then((profile) => {
      console.log('profike', profile)
      gtag('config', process.env.REACT_APP_GA4_MEASUREMENT_ID, {
        user_id: profile.username,
      })
      gtag('set', 'user_properties', {
        crm_id: profile.username,
      })
    })
  }, [t])

  const faqData = t('help.faqItems', { returnObjects: true })
  return (
    <DocumentTitle title={'Vitreo - ' + t('help.pageTitle')}>
      <VStack spacing={6} align='stretch'>
        <Heading as='h2' mb={10}>
          {t('help.pageTitle')}
        </Heading>
        <VStack spacing={0} align='stretch'>
          <Text>Kontakt</Text>
          <Text>vitreo service team</Text>
          <Link href='mailto:service@vitreo.me'>service@vitreo.me</Link>
          <Text>+49 173 218 3897</Text>
        </VStack>
        <Heading as='h4' size='md'>
          FAQ
        </Heading>
        <VStack spacing={6} align='stretch'>
          {faqData.map((faq) => (
            <React.Fragment key={faq.id}>
              <Text fontWeight='bold' mb={2}>
                {faq.question}
              </Text>
              <Trans>{faq.answer}</Trans>
            </React.Fragment>
          ))}
        </VStack>
      </VStack>
    </DocumentTitle>
  )
}

export default withRouter(Help)
