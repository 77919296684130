import API from '../utils/apiDomain'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

export const useGetSettings = (id, wait) => {
  return (
    useQuery(
      'settings',
      async () => {
        return await API.get(`/users/settings/${id}/`)
          .then((d) => d && d.data)
          .catch((error) => error)
      },
      { enabled: typeof wait === 'boolean' ? wait : true }
    ) ?? []
  )
}

export const usePutSettings = (items, id) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  return useMutation(
    ['putSettings', id],
    async (data) => {
      return await API.put(`/users/settings/${id}/`, data)
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('settings')
        return toast({
          position: 'bottom-right',
          title: items.success.title,
          description: items.success.description,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      },
      onError: async () => {
        return toast({
          position: 'bottom-right',
          title: items.error.title,
          description: items.error.description,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    }
  )
}

export const usePatchSettings = (items, id) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  return useMutation(
    ['patchSettings', id],
    async (data) => {
      return await API.patch(`/users/settings/${id}/`, data)
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('settings')
        return toast({
          position: 'bottom-right',
          title: items.success.title,
          description: items.success.description,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      },
      onError: async () => {
        return toast({
          position: 'bottom-right',
          title: items.error.title,
          description: items.error.description,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    }
  )
}
