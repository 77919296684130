import React, { useEffect, useState } from 'react'
import Tour from 'reactour'
import '../css/WebTour.css'
import { useTranslation } from 'react-i18next'
import gtag from 'ga-gtag'

const stepStyle = {
  color: '#666',
  background: '#dff9fb',
}
function OrderDetailWebTour() {
  const [isTourOpen, setIsTourOpen] = useState(true)
  const { t } = useTranslation()
  useEffect(() => {
    gtag('event', 'Order details guide')
  }, [])

  const steps = [
    {
      selector: '#addSensorOverview',
      content: t('tour.orderDetails.step1'),
      style: stepStyle,
    },
    {
      selector: '#addEmail',
      content: t('tour.orderDetails.step2'),
      style: stepStyle,
    },
    {
      selector: '#saveAlert',
      content: t('tour.orderDetails.step3'),
      style: stepStyle,
    },
    {
      selector: '#switchSendNotif',
      content: t('tour.orderDetails.step4'),
      style: stepStyle,
    },
    {
      selector: '#closeOrderOverview',
      content: t('tour.orderDetails.step5'),
      style: stepStyle,
    },
  ]

  return (
    <Tour
      steps={steps}
      isOpen={isTourOpen}
      rounded={5}
      stepInteraction={true}
      maskClassName='mask'
      accentColor='#269faf'
      onRequestClose={() => setIsTourOpen(false)}
    />
  )
}

export default OrderDetailWebTour
