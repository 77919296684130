import React from 'react'
import { CanvasOverlay } from 'react-map-gl'
import isEqual from "react-fast-compare";

const PolylineOverlay = (props) => {

    const _redraw  = ({ width, height, ctx, isDragging, project }) => {
        const { points, color = 'red', lineWidth = 5, renderWhileDragging = true } = props
        ctx.clearRect(0, 0, width, height)
        ctx.globalCompositeOperation = 'lighter'

        if ((renderWhileDragging || !isDragging) && points) {
            ctx.lineWidth = lineWidth
            ctx.setLineDash([5, 1]);
            ctx.strokeStyle = color
            ctx.beginPath()
            points.forEach(point => {
                const pixel = project([point[0], point[1]])
                ctx.lineTo(pixel[0], pixel[1])
            })
            ctx.stroke()
        }
    }

        return (<CanvasOverlay redraw={_redraw.bind(this)} />)
}
export default React.memo(PolylineOverlay, isEqual)