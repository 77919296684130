export const sendingIntervals = () => {
  return [
    {
      label: '20 min',
      value: 1200,
      id: 1200,
    },
    {
      label: '40 min',
      value: 2400,
      id: 2400,
    },
    {
      label: '1 hr',
      value: 3600,
      id: 3600,
    },
    {
      label: '2 hr',
      value: 7200,
      id: 7200,
    },
    {
      label: '3 hr',
      value: 10800,
      id: 10800,
    },
    {
      label: '6 hr',
      value: 21600,
      id: 21600,
    },
    {
      label: '12 hr',
      value: 43200,
      id: 43200,
    },
    {
      label: '24 hr',
      value: 86400,
      id: 86400,
    },
    {
      label: '48 hr',
      value: 172800,
      id: 172800,
    },
  ]
}
