import React from 'react'
import { useTable, usePagination } from 'react-table'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  IconButton,
  Text,
  Tooltip,
  Select,
  Center,
  VStack,
} from '@chakra-ui/react'
import {
  ArrowRightIcon,
  ArrowLeftIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from '@chakra-ui/icons'
import { useTranslation } from 'react-i18next'

function CustomTable({ columns, data, isPagination, defaultPageSize = 10 }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: defaultPageSize },
    },
    usePagination
  )

  const { t } = useTranslation()

  return (
    <>
      <Flex overflowX='scroll'>
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.slice(1).map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    data-testid='column'
                    py='4'
                    color='primaryText'
                    textTransform='none'
                    fontSize='lg'
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page && page.length > 0 ? (
              <>
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <Tr {...row.getRowProps()}>
                      {row.cells.map((cell, i) => {
                        return (
                          <Td
                            style={{
                              verticalAlign: `${i === 0 ? '' : 'text-top'}`,
                              whiteSpace: 'nowrap',
                            }}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </Td>
                        )
                      })}
                    </Tr>
                  )
                })}
              </>
            ) : (
              <Tr>
                <Td>{t('emptyData')}</Td>
                {headerGroups.map((headerGroup, i) => (
                  <React.Fragment key={i}>
                    {headerGroup.headers.slice(1).map((header) => (
                      <Td key={header.id}></Td>
                    ))}
                  </React.Fragment>
                ))}
              </Tr>
            )}
          </Tbody>
        </Table>
      </Flex>
      {isPagination && (
        <VStack align='stretch'>
          <Flex
            justifyContent='space-between'
            mt={6}
            mx={3}
            alignItems='center'
          >
            <Flex>
              <Tooltip label='First Page'>
                <IconButton
                  data-testid='firstPage'
                  _hover={{
                    backgroundColor: 'tertiaryOnChange',
                    color: 'tertiaryText',
                  }}
                  backgroundColor='tertiary'
                  color='secondaryText'
                  size={'sm'}
                  variant='outline'
                  aria-label='First Page'
                  onClick={() => gotoPage(0)}
                  isDisabled={!canPreviousPage}
                  icon={<ArrowLeftIcon h={3} w={3} />}
                  mr={4}
                />
              </Tooltip>
              <Tooltip label='Previous Page'>
                <IconButton
                  data-testid='previousPage'
                  _hover={{
                    backgroundColor: 'tertiaryOnChange',
                    color: 'tertiaryText',
                  }}
                  backgroundColor='tertiary'
                  color='secondaryText'
                  size={'sm'}
                  variant='outline'
                  aria-label='Previous Page'
                  onClick={previousPage}
                  isDisabled={!canPreviousPage}
                  icon={<ChevronLeftIcon h={6} w={6} />}
                />
              </Tooltip>
            </Flex>

            <Flex alignItems='center'>
              <Select
                data-testid='pageOptions'
                mx={3}
                color='primaryText'
                variant='outline'
                size={'sm'}
                borderRadius='md'
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value))
                }}
              >
                {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </Select>
            </Flex>

            <Flex>
              <Tooltip label='Next Page'>
                <IconButton
                  data-testid='nextPage'
                  _hover={{
                    backgroundColor: 'tertiaryOnChange',
                    color: 'tertiaryText',
                  }}
                  backgroundColor='tertiary'
                  color='secondaryText'
                  size={'sm'}
                  variant='outline'
                  aria-label='Next Page'
                  onClick={nextPage}
                  isDisabled={!canNextPage}
                  icon={<ChevronRightIcon h={6} w={6} />}
                />
              </Tooltip>
              <Tooltip label='Last Page'>
                <IconButton
                  data-testid='lastPage'
                  _hover={{
                    backgroundColor: 'tertiaryOnChange',
                    color: 'tertiaryText',
                  }}
                  backgroundColor='tertiary'
                  color='secondaryText'
                  size={'sm'}
                  variant='outline'
                  aria-label='Last Page'
                  onClick={() => gotoPage(pageCount - 1)}
                  isDisabled={!canNextPage}
                  icon={<ArrowRightIcon h={3} w={3} />}
                  ml={3}
                />
              </Tooltip>
            </Flex>
          </Flex>
          <Center>
            <Text color='primaryText' fontSize={'sm'} flexShrink={0}>
              Page{' '}
              <Text
                data-testid='page'
                color='primaryText'
                fontSize={'sm'}
                fontWeight='bold'
                as='span'
              >
                {pageIndex + 1}
              </Text>{' '}
              of{' '}
              <Text
                data-testid='pageOf'
                color='primaryText'
                fontSize={'sm'}
                fontWeight='bold'
                as='span'
              >
                {pageOptions.length}
              </Text>
            </Text>
          </Center>
        </VStack>
      )}
    </>
  )
}

function TableExtended(items) {
  return (
    <CustomTable
      columns={items.columns}
      data={items.data}
      isPagination={items.isPagination}
      defaultPageSize={items.defaultPageSize}
    />
  )
}

export default TableExtended
