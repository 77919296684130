import React from 'react'
import { HStack, Text } from '@chakra-ui/layout'
import { WarningIcon } from '@chakra-ui/icons'
import { CreateDateTimeHTML, filterData } from './utils'
import { CgAsterisk } from 'react-icons/cg'
export const GetModal = (
  t,
  modal,
  data = '',
  mapsensorsUnassigned = '',
  selectedCustomer = '',
  selectedSensor = '',
  mapIbcTypes
) => {
  const editSensorFilterData =
    data &&
    filterData(data, 'assigned_sensors_set', 'device_id', selectedSensor)

  return {
    addCustomer: [
      {
        key: 'addCustomer',
        title: t('dashboard.modalAddCustomer.title'),
        placeholder: t('dashboard.modalAddCustomer.placeholder'),
        helperText: t('dashboard.modalAddCustomer.helperText'),
        type: null,
        button: 'save',
      },
    ],
    editCustomer: [
      {
        key: 'editCustomer',
        callbackKey: 'deleteCustomer',
        title: t('dashboard.modalEditCustomer.title'),
        placeholder: t('dashboard.modalEditCustomer.placeholder'),
        defaultValue: selectedCustomer && selectedCustomer.name,
        helperText: t('dashboard.modalEditCustomer.helperText'),
        type: null,
        button: 'edit',
        deleteButtonText: t('dashboard.deleteCustomer'),
      },
    ],
    deleteCustomer: [
      {
        key: 'deleteCustomer',
        title: t('dashboard.modalDeleteCustomer.title'),
        type: 'confirm',
        button: 'remove',
      },
    ],
    addOrder: [
      {
        key: 'addOrder',
        title: t('dashboard.modalAddOrder.title'),
        placeholder: t('dashboard.modalAddOrder.placeholder'),
        helperText: t('dashboard.modalAddOrder.helperText'),
        type: null,
      },
      {
        key: 'addOrder',
        title: t('dashboard.modalAddShippingDate.title'),
        placeholder: t('dashboard.modalAddShippingDate.placeholder'),
        helperText: t('dashboard.modalAddShippingDate.helperText'),
        defaultDate: new Date(),
        type: 'date',
        button: 'save',
      },
    ],
    closeOrder: [
      {
        key: 'closeOrder',
        title: (
          <HStack spacing='4'>
            <WarningIcon w={5} h={5} color='negative' />
            <Text> {t('orderOverview.modalCloseOrder.title')}</Text>
          </HStack>
        ),
        type: 'confirm',
        button: 'remove',
        deleteButtonText: t('orderOverview.closeOrder'),
      },
    ],
    reopenOrder: [
      {
        key: 'reopenOrder',
        title: (
          <HStack spacing='4'>
            <WarningIcon w={5} h={5} color='negative' />
            <Text> {t('orderOverview.modalReopenOrder.title')}</Text>
          </HStack>
        ),
        type: 'confirm',
        button: 'save',
        saveButtonText: t('orderOverview.unlockOrder'),
      },
    ],
    editOrder: [
      {
        type: null,
        key: 'editOrder',
        callbackKey: 'deleteOrder',
        title: t('orderOverview.modalEditOrder.title'),
        defaultValue: data && data.order_number ? data.order_number : '',
        placeholder: t('orderOverview.modalEditOrder.placeholder'),
        helperText: t('orderOverview.modalEditOrder.helperText'),
      },
      {
        type: 'date',
        key: 'editOrder',
        callbackKey: 'deleteOrder',
        title: t('orderOverview.modalEditShippingDate.title'),
        placeholder: t('orderOverview.modalEditShippingDate.placeholder'),
        helperText: t('orderOverview.modalEditShippingDate.helperText'),
        defaultDate: data && CreateDateTimeHTML(data.shipping_date),
        button: 'edit',
        deleteButtonText: t('orderOverview.deleteOrder'),
      },
    ],
    deleteOrder: [
      {
        type: 'confirm',
        key: 'deleteOrder',
        title: t('orderOverview.modalDeleteOrder.title'),
        button: 'remove',
      },
    ],
    unassignSensor: [
      {
        type: 'confirm',
        key: 'unassignSensor',
        title: (
          <HStack spacing='4'>
            <WarningIcon w={5} h={5} color='negative' />
            <Text> {t('orderOverview.modalUnassignSensor.title')}</Text>
          </HStack>
        ),

        button: 'remove',
        deleteButtonText: t(
          'orderOverview.modalUnassignSensor.unassignSensorButton'
        ),
      },
    ],
    reassignSensor: [
      {
        type: 'confirm',
        key: 'reassignSensor',
        title: (
          <HStack spacing='4'>
            <WarningIcon w={5} h={5} color='negative' />
            <Text> {t('orderOverview.modalReassignSensor.title')}</Text>
          </HStack>
        ),

        button: 'save',
      },
    ],
    addSensor: [
      {
        key: 'addSensor',
        title: t('addSensor.inputFieldTitle'),
        placeholder: t('addSensor.sensorPlaceholder'),
        helperText: t('addSensor.helperText'),
        type: null,
        maxLength: 6,
        button: 'save',
      },
    ],
    addIBCType: [
      {
        key: 'addIBCType',
        title: t('addIBCType.inputFieldTitle'),
        placeholder: t('addIBCType.ibcPlaceholder'),
        helperText: t('addIBCType.helperText'),
        type: null,
      },
      {
        key: 'addIBCType',
        title: t('addIBCType.fillLevelHeightTitle'),
        placeholder: t('addIBCType.fillLevelHeightPlaceholder'),
        helperText: t('addIBCType.fillLevelHeightHelperText'),
        type: 'input_generic',
      },
      {
        key: 'addIBCType',
        title: t('addIBCType.fillLevelSensorHeightTitle'),
        placeholder: t('addIBCType.fillLevelSensorHeightPlaceholder'),
        helperText: t('addIBCType.fillLevelSensorHeightHelperText'),
        type: 'input_second_generic',
        button: 'save',
      },
    ],
    assignSensor: [
      {
        type: 'select',
        options: mapsensorsUnassigned,
        key: 'assignSensor',
        title: t('orderOverview.selectSensor'),
        defaultDate: data && CreateDateTimeHTML(data.shipping_date),
      },
      {
        type: 'link',
        required: false,
        key: 'addSensor',
        callbackKey: 'addSensor',
        title: t('orderOverview.addSensor'),
        button: null,
      },
      {
        type: 'select_generic',
        options: mapIbcTypes,
        key: 'assignSensor',
        title: t('orderOverview.selectIBCType'),
      },
      {
        type: 'link',
        required: false,
        key: 'addIBCType',
        callbackKey: 'addIBCType',
        title: t('orderOverview.modalAssignSensor.title'),
        button: null,
      },
      {
        type: null,
        required: true,
        key: 'assignSensor',
        placeholder: 'IBC-NR-4223',
        helperText: t('orderOverview.enterSensorInfoPlaceholder'),
        button: 'save',
        title: (
          <HStack spacing='1'>
            <CgAsterisk w={5} h={5} color='red' />
            <Text> {t('orderOverview.enterSensorInfo')}</Text>
          </HStack>
        ),
      },
    ],
    editSensor: [
      {
        type: null,
        required: false,
        key: 'editSensor',
        callbackKey: 'deleteSensor',
        title: t('orderOverview.enterSensorInfo'),
        defaultValue:
          editSensorFilterData && editSensorFilterData.assigment_sensor_info,
        placeholder: 'IBC-NR-4223',
        helperText: t('orderOverview.enterSensorInfoPlaceholder'),
        button: 'edit',
        deleteButtonText: t('orderOverview.deleteSensor'),
      },
      {
        type: 'select_generic',
        options: mapIbcTypes,
        key: 'editSensor',
        title: t('orderOverview.selectIBCType'),
        defaultValue: editSensorFilterData && {
          id: editSensorFilterData.assigment_ibc_id,
          label: editSensorFilterData.assigment_ibc_serie,
        },
      },
    ],
    editHistorySensor: [
      {
        type: null,
        required: false,
        key: 'editHistorySensor',
        title: t('orderOverview.enterSensorInfo'),
        defaultValue:
          editSensorFilterData && editSensorFilterData.assigment_sensor_info,
        placeholder: 'IBC-NR-4223',
        helperText: t('orderOverview.enterSensorInfoPlaceholder'),
      },
      {
        type: 'dateSecondary',
        key: 'editHistorySensor',
        title: t('orderOverview.modalEditSensorDetails.titleStartDate'),
        placeholder: t(
          'orderOverview.modalEditSensorDetails.placeholderStartDate'
        ),
        helperText: t(
          'orderOverview.modalEditSensorDetails.helperTextStartDate'
        ),
        maxDate: new Date(),
        minDate: data && data.shipping_date,
        defaultDate:
          editSensorFilterData &&
          CreateDateTimeHTML(editSensorFilterData.assigment_start),
      },
      {
        type: 'date',
        key: 'editHistorySensor',
        callbackKey: 'deleteSensor',
        title: t('orderOverview.modalEditSensorDetails.titleEndDate'),
        placeholder: t(
          'orderOverview.modalEditSensorDetails.placeholderEndDate'
        ),
        helperText: t('orderOverview.modalEditSensorDetails.helperTextEndDate'),
        maxDate: new Date(),
        minDate: data && data.shipping_date,
        defaultDate:
          editSensorFilterData &&
          CreateDateTimeHTML(editSensorFilterData.assigment_end),
        button: 'edit',
        deleteButtonText: t('orderOverview.deleteSensor'),
      },
      {
        type: 'select_generic',
        options: mapIbcTypes,
        key: 'editHistorySensor',
        title: t('orderOverview.selectIBCType'),
        defaultValue: editSensorFilterData && {
          id: editSensorFilterData.assigment_ibc_id,
          label: editSensorFilterData.assigment_ibc_serie,
        },
      },
    ],
    deleteSensor: [
      {
        type: 'confirm',
        key: 'deleteSensor',
        title: t('orderOverview.modalDeleteSensor.title'),
        button: 'remove',
      },
    ],
  }[modal]
}
