import API from '../utils/apiDownload'
import { useQuery } from 'react-query'
import { useToast } from '@chakra-ui/react'
import { downloadData } from '../utils/downloadData'

export const useDownloadPdfReport = (
  id,
  isEnabledReportLink,
  errMessages,
  orderNumber
) => {
  const toast = useToast()
  return (
    useQuery(
      ['orderReportDownload', id],
      async () => {
        return await API.get(`/reports/order/${id}/?type=pdf`)
          .then((d) => d)
          .catch((err) => {
            if (err.response) {
              // client received an error response (5xx, 4xx)
              return err.response
            } else if (err.request) {
              // client never received a response, or request never left
              return err.request
            } else {
              // anything else
              return err
            }
          })
      },
      {
        refetchOnWindowFocus: false,
        enabled: isEnabledReportLink, //manual refetch is needed
        useErrorBoundary: (error) => error.response?.status >= 500,
        onSuccess: async (data) => {
          //query was successful but no data returned so show toast message
          //observed mostly for 502 response
          if (data.response === null)
            return toast({
              position: 'bottom-right',
              title: errMessages.title,
              description: errMessages.description,
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          else downloadData(orderNumber, data)
        },
        onError: async () => {
          //generic error toast in case query fails and returns error
          return toast({
            position: 'bottom-right',
            title: errMessages.title,
            description: errMessages.description,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        },
      }
    ) ?? []
  )
}
