/*Copyright declaration:
This file is part of the Vitreo project which is licensed to Vistas Technology GmbH, Berlin Germany
Exclusive permission from Vistas Technology GmbH is required to use any part of the software resources.
Author: Emna Kammoun.
Date: 28. September 2022*/

import * as React from 'react'
import '../css/Cookie.css'
import { useTranslation } from 'react-i18next'
import { Button } from '@chakra-ui/react'

export function CookiesModal({ open, onClose, onAccept, onRefuse }) {
  const { t } = useTranslation()
  if (!open) return null
  return (
    <>
      <div onClick={onClose} className='overlay'>
        <div
          onClick={(e) => {
            e.stopPropagation()
          }}
          className='modalContainer'
        >
          <div className='modalRight'>
            <div className='content'>
              <h1>
                <strong>{t('dashboard.modalCookie.title')}</strong>
              </h1>
              <p>
                {t('dashboard.modalCookie.patchUserDetails')}

                <a
                  href={t('dashboard.modalCookie.hereLink')}
                  className='hereLink'
                >
                  {' '}
                  {t('dashboard.modalCookie.hereTitle')}.
                </a>
              </p>
            </div>
            <div className='btnContainer'>
              <Button
                data-testid='acceptCookies'
                id='acceptCookies'
                onClick={onAccept}
                variant='primaryCurved'
              >
                <span className='bold'>
                  {t('dashboard.modalCookie.onAcceptListener')}
                </span>
              </Button>
              <Button
                data-testid='rejectCookies'
                id='rejectCookies'
                onClick={onRefuse}
                variant='secondaryCurved'
              >
                <span className='bold'>
                  {t('dashboard.modalCookie.onRefuseListener')}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CookiesModal
