import React, { useState, useRef, useEffect } from 'react'
import {
  Link as ReactRouterLink,
  withRouter,
  useParams,
} from 'react-router-dom'
import {
  Button,
  Flex,
  VStack,
  Link,
  SimpleGrid,
  useDisclosure,
  useMediaQuery,
  Switch,
  Fade,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  HStack,
  Heading,
  Box,
  Tooltip,
  Stack,
} from '@chakra-ui/react'
import { BiUnlink, BiLink, BiEdit, BiTrash } from 'react-icons/bi'
import {
  CreateDateTimeHTML,
  DEFAULT_TABLE_PROPS,
  ToastCallback,
  DisplayAlertInfo,
} from '../utils/utils'
import ModalExtended from '../components/ModalExtended'
import ReactMapGL from '../components/Map/ReactMapGL'
import { useTranslation } from 'react-i18next'
import { CardSmall, CardFull } from '../components/Card'
import IconCircle from '../components/IconCircle'
import {
  EditIcon,
  WarningIcon,
  AddIcon,
  LockIcon,
  DeleteIcon,
} from '@chakra-ui/icons'
import Loading from '../components/Loading'
import {
  useGetOrder,
  usePatchOrder,
  useDeleteOrder,
  usePostOrder,
  useUnassignSensor,
  useAssignSensor,
  useGetSensorsUnassigned,
  useGetOrderLocations,
  useReassignSensor,
} from '../services/orders'
import { useDownloadPdfReport } from '../services/downloadFile'
import {
  useSensorAssignment,
  useRegisterSensor,
  useGetIBCTypes,
  useRegisterIBCType,
} from '../services/sensors'
import DocumentTitle from 'react-document-title'
import { FlatPickr as DatePicker } from '../components/DatePicker'
import { GetModal } from '../utils/getModal'
import {
  mapsensorsUnassigned,
  mapOrderLocations,
  graphDataMapped,
  mapIbcTypes,
} from '../utils/prepareGraphAndMapData'
import { CreateAlert } from '../components/createAlert'
import { usePostAlert, useDeleteAlert } from '../services/alerts'
import TableExtended from '../components/TableExtended'
import { Spinner } from '@chakra-ui/react'
import OrderDetailWebTour from '../components/orderDetailWebTour'
import gtag from 'ga-gtag'
import API from '../utils/api'
import '../css/WebTour.css'
import { getSensorReadings1 } from '../services/apiGateway'
import { ShowGraphs } from '../utils/prepareGraphs'

const OrderOverview = (props) => {
  const { t } = useTranslation()
  const [smallScreen] = useMediaQuery('(max-width: 1024px)')
  let { orderID } = useParams()
  const orderId = props.location.state ? props.location.state.order_id : orderID
  const [selectedSensor, setselectedSensor] = useState()
  const [selectedAssignmentStart, setselectedAssignmentStart] = useState()
  const [selectedAssignmentEnd, setselectedAssignmentEnd] = useState()
  const [selectedAssignmentUuid, setSelectedAssignmentUuid] = useState()
  const [modalChoice, setModalChoice] = useState()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedStartDate, setSelectedStartDate] = useState(null)
  const [selectedEndDate, setSelectedEndDate] = useState(null)
  const [defaultStartDate, setDefaultStartDate] = useState(null)
  const [apiGatewaySensorData, setApiGatewaySensorData] = useState(null)
  const [allDataButton, setAllDataButton] = useState(false)
  const [reportLinkClicked, setReportLinkClicked] = useState(false)
  const fp = useRef(null)
  const pdfErrorToastMsg = {
    title: t('error') + '!',
    description: t('login.login.error.errorOccurred'),
  }
  const [selectedEmailAddress, setSelectedEmailAddress] = useState()
  const [firstInit, setFirstInit] = useState(false)
  const [startDoc, setStartDoc] = useState(false)
  const NUMBER_OF_GRAPHS = 6

  // Axios custom hook
  const { data, error, isLoading } = useGetOrder(orderId)
  const unassignedSensors = useGetSensorsUnassigned(orderId)
  const ibcTypes = useGetIBCTypes()
  const orderLocations = useGetOrderLocations(orderId)
  const orderLastDataPointTime = data && data.last_data_point_time
  const isOrderActive = data && data.active
  const alarmRules = data && data.alarmrule_set
  const orderNumber = data && data.order_number

  const { refetch, isFetching } = useDownloadPdfReport(
    orderId,
    reportLinkClicked,
    pdfErrorToastMsg,
    orderNumber
  )

  useEffect(() => {
    const getProfile = async () => {
      return await API.get('profile').then((d) => {
        return d.data && d.data.profile
      })
    }
    getProfile().then((profile) => {
      console.log('profike', profile)
      gtag('config', process.env.REACT_APP_GA4_MEASUREMENT_ID, {
        user_id: profile.username,
      })
      gtag('set', 'user_properties', {
        crm_id: profile.username,
      })
    })
  }, [])

  useEffect(() => {
    if (data && unassignedSensors && ibcTypes && orderLocations && !firstInit) {
      setFirstInit(true)
    }
  }, [data, unassignedSensors, ibcTypes, orderLocations, firstInit])

  useEffect(() => {
    if (orderLastDataPointTime && data) {
      if (!isOrderActive) {
        setSelectedEndDate(new Date(orderLastDataPointTime))
        setSelectedStartDate(new Date(data.shipping_date))
        setDefaultStartDate(new Date(data.shipping_date))
      } else {
        const lastWeekDay = new Date(
          new Date(orderLastDataPointTime).getFullYear(),
          new Date(orderLastDataPointTime).getMonth(),
          new Date(orderLastDataPointTime).getDate() - 14
        )
        setSelectedEndDate(new Date(orderLastDataPointTime))
        setSelectedStartDate(lastWeekDay)
        setDefaultStartDate(lastWeekDay)
      }
    }
  }, [orderLastDataPointTime, isOrderActive, data])

  useEffect(() => {
    fp.current && fp.current.createFlatpickrInstance()
    setSelectedEndDate((previous) => {
      if (previous) {
        if (new Date().toDateString() === previous.toDateString()) {
          console.log('same date')
          return new Date()
        }
      }
      return previous
    })
  }, [data])
  useEffect(() => {
    const lastWeekDay1 = new Date(
      new Date(orderLastDataPointTime).getFullYear(),
      new Date(orderLastDataPointTime).getMonth(),
      new Date(orderLastDataPointTime).getDate() - 14
    )
    if (
      data &&
      (allDataButton || new Date(data.shipping_date) > lastWeekDay1)
    ) {
      setDefaultStartDate(new Date(data.shipping_date))
      setSelectedStartDate(new Date(data.shipping_date))
      fp.current.createFlatpickrInstance()
    }
  }, [data, orderLastDataPointTime, allDataButton])

  const createAlertRule = usePostAlert(
    ToastCallback(
      t('orderOverview.alertSettings.alertCreated'),
      t('orderOverview.alertSettings.alertNotCreated')
    )
  )
  useEffect(() => {
    if (
      data &&
      data.hasOwnProperty('assigned_sensors_set') &&
      data.assigned_sensors_set.length > 0 &&
      selectedStartDate &&
      selectedEndDate
    ) {
      const apiDataPromises = data.assigned_sensors_set.map((sensor) => {
        const dateStringStart =
          selectedStartDate.getUTCFullYear() +
          '-' +
          (selectedStartDate.getUTCMonth() + 1) +
          '-' +
          selectedStartDate.getUTCDate() +
          'T' +
          selectedStartDate.getUTCHours() +
          ':' +
          selectedStartDate.getUTCMinutes() +
          ':' +
          selectedStartDate.getUTCSeconds() +
          'Z'
        const dateStringEnd =
          selectedEndDate.getUTCFullYear() +
          '-' +
          (selectedEndDate.getUTCMonth() + 1) +
          '-' +
          selectedEndDate.getUTCDate() +
          'T' +
          selectedEndDate.getUTCHours() +
          ':' +
          selectedEndDate.getUTCMinutes() +
          ':' +
          selectedEndDate.getUTCSeconds() +
          'Z'
        const assignment_end_utc = sensor.assigment_end
          ? new Date(new Date(sensor.assigment_end).toUTCString())
          : ''
        const assignment_end = assignment_end_utc
          ? assignment_end_utc.getUTCFullYear() +
            '-' +
            (assignment_end_utc.getUTCMonth() + 1) +
            '-' +
            assignment_end_utc.getUTCDate() +
            'T' +
            assignment_end_utc.getUTCHours() +
            ':' +
            assignment_end_utc.getUTCMinutes() +
            ':' +
            assignment_end_utc.getUTCSeconds() +
            'Z'
          : ''
        const assignment_start_utc = new Date(
          new Date(sensor.assigment_start).toUTCString()
        )
        const assignment_start =
          assignment_start_utc.getUTCFullYear() +
          '-' +
          (assignment_start_utc.getUTCMonth() + 1) +
          '-' +
          assignment_start_utc.getUTCDate() +
          'T' +
          assignment_start_utc.getUTCHours() +
          ':' +
          assignment_start_utc.getUTCMinutes() +
          ':' +
          assignment_start_utc.getUTCSeconds() +
          'Z'
        return getSensorReadings1(
          sensor.device_id,
          assignment_start,
          assignment_end,
          dateStringStart,
          dateStringEnd
        )
      })
      Promise.all(apiDataPromises).then((f) => {
        setApiGatewaySensorData(f)
      })
    }
  }, [data, selectedStartDate, selectedEndDate])

  const columns = [
    {
      Header: 'null',
      columns: [
        {
          Header: t('orderOverview.alertLogs.tableCol1'),
          className: 'sticky',
          accessor: (d) => (
            <VStack {...DEFAULT_TABLE_PROPS.vstack}>
              {d.alarm_rule.alert_field && DisplayAlertInfo(t, d.alarm_rule)}
            </VStack>
          ),
        },
        {
          Header: t('orderOverview.alertLogs.tableCol2'),
          accessor: (d) => (
            <HStack h='full' spacing='5'>
              <Link
                as={ReactRouterLink}
                to={{
                  pathname: `/my-sensors/sensor/${d.sensor}`,
                  state: {
                    device_id: d.sensor.slice(d.sensor.length - 6),
                    device_id_long: d.sensor,
                  },
                }}
              >
                {d.sensor.slice(d.sensor.length - 6)}
              </Link>
            </HStack>
          ),
        },
        {
          Header: t('orderOverview.alertLogs.tableCol4'),
          accessor: (d) => (
            <VStack {...DEFAULT_TABLE_PROPS.vstack}>
              {d.alarm_rule.alert_field && (
                <Text>{CreateDateTimeHTML(d.created_at)}</Text>
              )}
            </VStack>
          ),
        },
      ],
    },
  ]

  const addEmailAddress = usePatchOrder(
    ToastCallback(
      t('orderOverview.emailAdded'),
      t('orderOverview.emailNotAdded')
    ),
    orderId
  )

  const removeEmailAddress = usePatchOrder(
    ToastCallback(
      t('orderOverview.emailRemoved'),
      t('orderOverview.emailNotRemoved')
    ),
    orderId
  )

  const editEmailAddress = usePatchOrder(
    ToastCallback(
      t('orderOverview.emailUpdated'),
      t('orderOverview.emailNotUpdated')
    ),
    orderId
  )

  const getModal = (modal) =>
    ({
      addEmail: [
        {
          key: 'addEmail',
          title: t('settings.modalAddEmail.title'),
          placeholder: t('settings.modalAddEmail.placeholder'),
          type: 'email',
          button: 'save',
        },
      ],
      editEmail: [
        {
          key: 'editEmail',
          title: t('settings.modalEditEmail.title'),
          placeholder: t('settings.modalEditEmail.title'),
          defaultValue: selectedEmailAddress ? selectedEmailAddress : '',
          type: 'email',
          button: 'save',
        },
      ],
      removeEmail: [
        {
          key: 'removeEmail',
          title: t('settings.modalDeleteEmail.title'),
          type: 'confirm',
          button: 'remove',
        },
      ],
    }[modal])

  const postAlertRule = (details) => {
    if (details.alertType && details.measure) {
      gtag('event', 'Add Alert')
      details.condition
        ? createAlertRule.mutate({
            name: 'Alarm Rule',
            order: orderId,
            eval_mode: details.condition,
            alert_field: details.alertType,
            measure_value: details.measure,
          })
        : createAlertRule.mutate({
            name: 'Alarm Rule',
            order: orderId,
            alert_field: details.alertType,
            measure_value: details.measure,
          })
    }
  }

  const handleDeleteAlert = (id) => {
    gtag('event', 'Delete Alert')
    deleteAlert.mutate(id)
  }

  const deleteAlert = useDeleteAlert(
    ToastCallback(
      t('orderOverview.alertSettings.alertDeleted'),
      t('orderOverview.alertSettings.alertNotDeleted')
    )
  )

  const updateOrder = usePatchOrder(
    modalChoice === 'closeOrder'
      ? ToastCallback(
          t('orderOverview.orderClosed'),
          t('orderOverview.orderNotClosed')
        )
      : modalChoice === 'reopenOrder'
      ? ToastCallback(
          t('orderOverview.orderReopened'),
          t('orderOverview.orderNotReopened')
        )
      : ToastCallback(
          t('orderOverview.orderUpdated'),
          t('orderOverview.orderNotUpdated')
        ),
    orderId
  )

  const unAssignAllSensors = usePostOrder('', orderId + '/unassign_all')

  const updateEmailNotifications = usePatchOrder(
    ToastCallback(
      data && data.send_notifications
        ? t('orderOverview.emailNotificationsOff')
        : t('orderOverview.emailNotificationsOn'),
      t('orderOverview.emailNotificationsNotUpdated')
    ),
    orderId
  )

  const unassignSensor = useUnassignSensor(
    ToastCallback(
      t('orderOverview.sensorUnassigned'),
      t('orderOverview.sensorNotUnassigned')
    ),
    orderId,
    selectedSensor,
    selectedAssignmentStart
  )

  const assignSensor = useAssignSensor(
    ToastCallback(
      t('orderOverview.sensorAssigned'),
      t('orderOverview.sensorNotAssigned')
    ),
    orderId
  )

  const registerSensor = useRegisterSensor(
    ToastCallback(
      t('addSensor.sensorAdded'),
      t('addSensor.sensorNotFound'),
      t('addSensor.sensorAlreadyRegistered')
    )
  )

  const registerIBCType = useRegisterIBCType(
    ToastCallback(
      t('orderOverview.ibcTypeRegistered'),
      t('orderOverview.ibcTypeNotRegistered')
    )
  )

  const deleteOrder = useDeleteOrder(
    ToastCallback(
      t('orderOverview.orderDeleted'),
      t('orderOverview.orderNotDeleted')
    ),
    orderId,
    () => {
      props.history.push('/')
    }
  )

  const reassignSensor = useReassignSensor(
    ToastCallback(
      t('orderOverview.sensorReassigned'),
      t('orderOverview.sensorNotReassigned')
    ),
    orderId,
    selectedSensor,
    selectedAssignmentStart,
    selectedAssignmentEnd
  )

  const editSensor = useSensorAssignment(
    ToastCallback(
      t('orderOverview.sensorDetailsUpdated'),
      t('orderOverview.sensorDetailsNotUpdated')
    ),
    orderId,
    selectedAssignmentUuid,
    selectedSensor,
    selectedAssignmentStart,
    selectedAssignmentEnd,
    'patch'
  )
  const editSensorHistory = useSensorAssignment(
    ToastCallback(
      t('orderOverview.sensorDetailsUpdated'),
      t('orderOverview.sensorDetailsNotUpdated')
    ),
    orderId,
    selectedAssignmentUuid,
    selectedSensor,
    selectedAssignmentStart,
    selectedAssignmentEnd,
    'patch',
    true
  )

  const deleteSensor = useSensorAssignment(
    ToastCallback(
      t('orderOverview.sensorDeleted'),
      t('orderOverview.sensorNotDeleted')
    ),
    orderId,
    selectedAssignmentUuid,
    'delete'
  )

  const handleclick = (e) => {
    onOpen()
    setModalChoice(e)
  }

  //pdf report download link handler
  const HandlePdfClick = () => {
    gtag('event', 'Generate PDF')
    refetch()
  }

  const handleSubmit = (items) => {
    const submitOperation = {
      addEmail: function () {
        gtag('event', 'Add new notification email address')
        const emailArray = data.notification_emails
          ? data.notification_emails
          : []
        emailArray.push(items.inputRef)
        addEmailAddress.mutate({
          notification_emails: emailArray,
        })
      },
      removeEmail: function () {
        gtag('event', 'Remove a notification email address')
        const filteredEmailArray = data.notification_emails.filter(
          (emailAddress) => emailAddress !== selectedEmailAddress
        )
        removeEmailAddress.mutate({
          notification_emails: filteredEmailArray.length
            ? filteredEmailArray
            : null,
        })
      },
      editEmail: function () {
        gtag('event', 'Edit the notification email address')
        const filteredEmailArray = data.notification_emails.filter(
          (emailAddress) => emailAddress !== selectedEmailAddress
        )
        filteredEmailArray.push(items.inputRef)
        editEmailAddress.mutate({
          notification_emails: filteredEmailArray,
        })
      },
      editOrder: function () {
        gtag('event', 'Edit Order')
        updateOrder.mutate({
          order_number: items.inputRef,
          shipping_date: items.dateTime,
        })
      },
      deleteOrder: function () {
        gtag('event', 'Delete Order')
        deleteOrder.mutate()
      },
      unassignSensor: function () {
        gtag('event', 'Unassign Sensor')
        unassignSensor.mutate()
      },
      closeOrder: function () {
        gtag('event', 'Close Order')
        updateOrder.mutate({
          active: false,
        })
        unAssignAllSensors.mutate()
      },
      reopenOrder: function () {
        gtag('event', 'Reopen Order')
        updateOrder.mutate({
          active: true,
        })
      },
      reassignSensor: function () {
        gtag('event', 'Reassign Sensor')
        reassignSensor.mutate({
          sensorId: selectedSensor,
        })
      },
      assignSensor: function () {
        gtag('event', 'Assign Sensor')
        const sensorID = items.selectItem
          ? items.selectItem
          : registerSensor.isSuccess &&
            mapsensorsUnassigned(unassignedSensors)[0].id
        const ibcTypeId = items.genericSelectItem
          ? items.genericSelectItem
          : registerIBCType.isSuccess && mapIbcTypes(ibcTypes)[0].id
        sensorID &&
          ibcTypeId &&
          assignSensor.mutate({
            sensorId: sensorID,
            ibcTypeId: ibcTypeId,
            sensorInfo: encodeURIComponent(items.inputRef),
          })
      },
      editSensor: function () {
        gtag('event', 'Edit Sensor')
        const obj = {
          ...(items.genericSelectItem && { ibc: items.genericSelectItem }),
          ...(items.inputRef && { sensor_info: items.inputRef }),
        }
        editSensor.mutate(obj)
      },
      editHistorySensor: function () {
        gtag('event', 'Edit History Sensor')
        const assig_start =
          items.dateTimeStart < new Date(data.shipping_date)
            ? new Date(data.shipping_date)
            : items.dateTimeStart
        const assig_end =
          items.dateTime < new Date(data.shipping_date)
            ? new Date(data.shipping_date)
            : items.dateTime
        items.inputRef
          ? editSensorHistory.mutate({
              assigment_start: assig_start,
              assigment_end: assig_end,
              sensor_info: items.inputRef,
              ibc: items.genericSelectItem,
            })
          : editSensorHistory.mutate({
              assigment_start: assig_start,
              assigment_end: assig_end,
              ibc: items.genericSelectItem,
            })
      },
      deleteSensor: function () {
        gtag('event', 'Delete Sensor')
        deleteSensor.mutate()
      },
      addSensor: function () {
        gtag('event', 'Add Sensor')
        registerSensor.mutate(items.inputRef)
        handleclick('assignSensor')
      },
      addIBCType: function () {
        gtag('event', 'Add IBC Type')
        registerIBCType.mutate({
          series: items.inputRef,
          height: items.inputRefGeneric / 100,
          height_sensor: items.inputSecondRefGeneric / 100,
        })
        handleclick('assignSensor')
      },
    }
    return submitOperation[items.key]() || []
  }

  const handleEmailNotifications = (e) => {
    updateEmailNotifications.mutate({
      send_notifications: e.target.checked,
    })
    if (e.target.checked) {
      gtag('event', 'Turn On Notification')
    } else {
      gtag('event', 'Turn Off Notification')
    }
  }

  const setClickedSensorId = (sensorId) => {
    const sensor_end_time = data.assigned_sensors_set.find(
      (f) => f.device_id === sensorId
    ).assigment_end
    let sensor_last_time
    if (sensor_end_time) {
      sensor_last_time = sensor_end_time
    } else {
      sensor_last_time = data.assigned_sensors_set.find(
        (f) => f.device_id === sensorId
      ).last_reading_time
    }
    const lastWeekDay1 = new Date(
      new Date(sensor_last_time).getFullYear(),
      new Date(sensor_last_time).getMonth(),
      new Date(sensor_last_time).getDate() - 14
    )
    if (
      lastWeekDay1 < selectedStartDate &&
      lastWeekDay1 > new Date(data.shipping_date)
    ) {
      setSelectedStartDate(lastWeekDay1)
      setDefaultStartDate(lastWeekDay1)
    }
  }

  const cardRightColumn = (item, editEventType, linkEventType) => (
    <HStack spacing='2'>
      <IconCircle
        data-testid='editSensorAssigned'
        label={t('tooltip.editSensor')}
        icon={<BiEdit />}
        onClick={() => {
          setselectedSensor(item.device_id)
          setselectedAssignmentStart(item.assigment_start)
          setselectedAssignmentEnd(item.assigment_end)
          setSelectedAssignmentUuid(item.assigment_uuid)
          handleclick(editEventType) //editEventType
        }}
      />
      <IconCircle
        data-testid='unassignSensor'
        label={
          linkEventType === 'unassignSensor'
            ? t('tooltip.unassignSensor')
            : t('tooltip.assignSensor')
        }
        icon={linkEventType === 'unassignSensor' ? <BiUnlink /> : <BiLink />}
        onClick={() => {
          setselectedSensor(item.device_id)
          setselectedAssignmentStart(item.assigment_start)
          setselectedAssignmentEnd(item.assigment_end)
          setSelectedAssignmentUuid(item.assigment_uuid)
          handleclick(linkEventType) //linkEventType
        }}
      />
    </HStack>
  )

  return (
    <>
      {((data && data.is_first_new_created) || startDoc) && !isLoading && (
        <OrderDetailWebTour />
      )}
      {isLoading && <Loading />}
      {error || (data && data.status === 404) || (data && data.length === 0) ? (
        <DocumentTitle title={`Vitreo - ${t('order')} - not defined`}>
          <Text>{t('errorNoData')}</Text>
        </DocumentTitle>
      ) : (
        data && (
          <DocumentTitle
            title={`Vitreo - ${t('order')} - ${data && data.order_number}`}
          >
            <Box pointerEvents={isOrderActive ? 'all' : 'none'}>
              <Flex
                data-testid='orderCloseContainer'
                justify='flex-end'
                mb={smallScreen ? '5' : '10'}
                mt={smallScreen ? '5' : '-6.9rem'}
              >
                {!isOrderActive && (
                  <Box data-testid='oderActive' pointerEvents='all' as='button'>
                    <Tooltip label={t('tooltip.reopenOrder')}>
                      <LockIcon
                        onClick={() => {
                          handleclick('reopenOrder')
                        }}
                        alignSelf='center'
                        w={5}
                        h={5}
                        mx={2}
                        color='tertiary'
                      />
                    </Tooltip>
                  </Box>
                )}

                <Button
                  data-testid='closeOrderOverview'
                  id='closeOrderOverview'
                  onClick={() => handleclick('closeOrder')}
                  variant='primaryInvertedCurved'
                  disabled={!isOrderActive}
                >
                  {isOrderActive ? (
                    t('orderOverview.closeOrder')
                  ) : (
                    <HStack spacing='4'>
                      <WarningIcon w={5} h={5} color='negative' />
                      <Text>{t('orderOverview.orderClosed')}</Text>
                    </HStack>
                  )}
                </Button>
                <Button
                  data-testid='startDocumentation'
                  id='startDocumentation'
                  onClick={() => setStartDoc(true)}
                  variant='primaryInvertedCurved'
                  style={{ marginLeft: '12px' }}
                >
                  {t('tour.orderDetails.startTour')}
                </Button>
              </Flex>
              <Box
                filter={isOrderActive ? 'grayscale(0)' : 'grayscale(1)'}
                opacity={isOrderActive ? '1' : '0.7'}
              >
                <Fade in={data.order_number}>
                  <VStack align='stretch' spacing='3rem'>
                    <SimpleGrid
                      data-testid='gridOrderOverview'
                      columns={smallScreen ? 1 : 3}
                      spacing={smallScreen ? 5 : 10}
                    >
                      <CardSmall
                        data-testid='cardOrderNumber'
                        color='gray.500'
                        heading={t('order') + ' ' + data.order_number}
                        content={
                          <Text>
                            {data.shipping_date &&
                              CreateDateTimeHTML(data.shipping_date)}
                          </Text>
                        }
                        rightcolumn={
                          <IconCircle
                            data-testid='editOrder'
                            label={t('tooltip.editOrder')}
                            icon={<EditIcon />}
                            onClick={() => handleclick('editOrder')}
                          />
                        }
                      />
                      <CardSmall
                        data-testid='cardCustomer'
                        color='gray.500'
                        heading={t('customer') + ' '}
                        content={<Text>{data.customer_name}</Text>}
                      />
                      <CardSmall
                        data-testid='cardEmailNotifs'
                        id='switchSendNotif'
                        color='gray.500'
                        heading={t('emailNotifications')}
                        content={
                          <Link
                            data-testid='emailLink'
                            as={ReactRouterLink}
                            to={'/settings'}
                          >
                            {t('settingsMenu')}
                          </Link>
                        }
                        rightcolumn={
                          <Switch
                            data-testid='switchSendNotif'
                            isChecked={data.send_notifications}
                            colorScheme='switch'
                            onChange={(e) => handleEmailNotifications(e)}
                            size='lg'
                          />
                        }
                      />
                    </SimpleGrid>
                    <VStack
                      data-testid='stackAssigned'
                      align='stretch'
                      spacing='5'
                    >
                      <Flex align='center' justify='start'>
                        <Heading data-testid='assignedSensors' size='md' mr='5'>
                          {t('orderOverview.assignedSensorsHeading')}
                        </Heading>
                        <IconCircle
                          data-testid='addSensorOverview'
                          id='addSensorOverview'
                          label={t('tooltip.assignSensor')}
                          icon={<AddIcon />}
                          onClick={() => handleclick('assignSensor')}
                        />
                      </Flex>
                      {data.hasOwnProperty('assigned_sensors_set') && (
                        <SimpleGrid
                          data-testid='gridAssignedSensors'
                          columns={smallScreen ? 1 : 3}
                          spacing={smallScreen ? 5 : 10}
                        >
                          {data &&
                            data.assigned_sensors_set.map((item, index) =>
                              item.assigment_end === null ? (
                                <CardSmall
                                  data-testid='sensorNumberAssigned'
                                  key={index}
                                  color='gray.500'
                                  heading={item.device_id_short}
                                  isHeadingClickable
                                  currentHeadingItem={{
                                    data: item,
                                    orderID: orderId,
                                  }}
                                  content={
                                    <>
                                      {item.assigment_sensor_info}
                                      <br />
                                      <Flex direction={'row'}>
                                        <BiLink data-testid='link' />
                                        <Text
                                          data-testid='dateAssigned'
                                          fontSize='xs'
                                        >
                                          {CreateDateTimeHTML(
                                            item.assigment_start
                                          )}
                                        </Text>
                                      </Flex>
                                    </>
                                  }
                                  rightcolumn={cardRightColumn(
                                    item,
                                    'editSensor',
                                    'unassignSensor'
                                  )}
                                />
                              ) : (
                                ''
                              )
                            )}
                        </SimpleGrid>
                      )}
                    </VStack>
                    <VStack
                      data-testid='historyStack'
                      align='stretch'
                      spacing='5'
                    >
                      <Heading data-testid='sensorHistory' size='md' mr='5'>
                        {t('orderOverview.sensorHistoryHeading')}
                      </Heading>
                      {data &&
                        data.assigned_sensors_set &&
                        data.assigned_sensors_set.length > 0 && (
                          <SimpleGrid
                            data-testid='gridSensorHistory'
                            columns={smallScreen ? 1 : 3}
                            spacing={smallScreen ? 5 : 10}
                          >
                            {data &&
                              data.assigned_sensors_set.map((item, index) =>
                                item.assigment_end !== null ? (
                                  <CardSmall
                                    data-testid='sensorNumberUnassigned'
                                    key={index}
                                    color='gray.500'
                                    heading={item.device_id_short}
                                    isHeadingClickable
                                    currentHeadingItem={{
                                      data: item,
                                      orderID: orderId,
                                    }}
                                    content={<>{item.assigment_sensor_info}</>}
                                    rightcolumn={cardRightColumn(
                                      item,
                                      'editHistorySensor',
                                      'reassignSensor'
                                    )}
                                    cardCaption={
                                      <Flex mt={'2'} alignItems={'center'}>
                                        <BiLink data-testid='linkHistory' />
                                        <Text
                                          data-testid='dateAssignedHistory'
                                          fontSize='xs'
                                        >
                                          {CreateDateTimeHTML(
                                            item.assigment_start
                                          )}
                                        </Text>
                                        <span fontSize='xs'>
                                          &ensp;&ndash;&ensp;
                                        </span>
                                        <BiUnlink data-testid='unlink' />
                                        <Text
                                          data-testid='dateUnassignedHistory'
                                          fontSize='xs'
                                        >
                                          {CreateDateTimeHTML(
                                            item.assigment_end
                                          )}
                                        </Text>
                                      </Flex>
                                    }
                                  />
                                ) : (
                                  ''
                                )
                              )}
                          </SimpleGrid>
                        )}
                    </VStack>
                    {orderLocations && selectedStartDate && selectedEndDate && (
                      <CardFull
                        data-testid='mapOrderOverview'
                        color='gray.500'
                        heading={t('location')}
                        position='relative'
                        content={
                          <ReactMapGL
                            data={mapOrderLocations(
                              orderLocations,
                              selectedStartDate,
                              selectedEndDate
                            )}
                            setClickedSensorId={setClickedSensorId}
                            isZoom={true}
                          />
                        }
                      />
                    )}
                    <Box>
                      <CardFull
                        data-testid='timePeriod'
                        color='gray.500'
                        heading={t('timePeriod')}
                        rounded='false'
                        shadow='false'
                        pt={0}
                        pb={0}
                      />
                      <Stack
                        direction={smallScreen ? 'column' : 'row'}
                        w={smallScreen ? '100%' : '70%'}
                      >
                        <Box minW={smallScreen ? '' : '270px'}>
                          <DatePicker
                            data-testid='datePicker'
                            key={
                              defaultStartDate && defaultStartDate.toString()
                            }
                            style={
                              smallScreen
                                ? { minWidth: '' }
                                : { minWidth: '270px' }
                            }
                            ref={fp}
                            options={{
                              static: document.body.click() ? 'false' : 'true',
                              mode: 'range',
                              showMonths: 2,
                              defaultDate: [defaultStartDate, selectedEndDate],
                              minDate: new Date(data.shipping_date),
                              maxDate: new Date(),
                            }}
                            placeholder='Select...'
                            onChange={(e) => {
                              console.log('event', e)
                              e[0] && setSelectedStartDate(e[0])
                              e[1] &&
                                setSelectedEndDate(
                                  new Date(
                                    e[1].getFullYear(),
                                    e[1].getMonth(),
                                    e[1].getDate(),
                                    23,
                                    59,
                                    59
                                  )
                                )
                              setAllDataButton(false)
                            }}
                          />
                        </Box>
                        {!allDataButton && (
                          <Button
                            variant='primaryInvertedCurved'
                            minW={smallScreen ? '' : '270px'}
                            flex
                            onClick={() => {
                              setSelectedStartDate(new Date(data.shipping_date))
                              setSelectedEndDate(new Date())
                              setAllDataButton(true)
                            }}
                          >
                            {t('showAllData')}
                          </Button>
                        )}
                      </Stack>
                    </Box>
                    {[...Array(NUMBER_OF_GRAPHS)].map((el, graphIndex) => (
                      <ShowGraphs
                        key={graphIndex}
                        setClickedSensorId={setClickedSensorId}
                        graphData={
                          graphIndex === 0
                            ? graphDataMapped(
                                apiGatewaySensorData,
                                'temperature'
                              )
                            : graphIndex === 1
                            ? graphDataMapped(apiGatewaySensorData, 'humidity')
                            : graphIndex === 2
                            ? graphDataMapped(
                                apiGatewaySensorData,
                                'percentage_m'
                              )
                            : graphIndex === 3
                            ? graphDataMapped(
                                apiGatewaySensorData,
                                'air_pressure'
                              )
                            : graphIndex === 4
                            ? graphDataMapped(
                                apiGatewaySensorData,
                                'air_quality'
                              )
                            : graphIndex === 5
                            ? graphDataMapped(apiGatewaySensorData, 'amb_light')
                            : ''
                        }
                        tooltipSymbolY={
                          graphIndex === 0
                            ? ' \u{00B0}C'
                            : graphIndex === 1
                            ? ' (% r.H.)'
                            : graphIndex === 2
                            ? ' (%)'
                            : graphIndex === 3
                            ? ' (hPa)'
                            : graphIndex === 5
                            ? ' (Lux)'
                            : ''
                        }
                        xHeading={t('date')}
                        yHeading={
                          graphIndex === 0
                            ? t('temperature')
                            : graphIndex === 1
                            ? t('humidity')
                            : graphIndex === 2
                            ? t('fillLevelTooltip')
                            : graphIndex === 3
                            ? t('airPressure')
                            : graphIndex === 4
                            ? t('airQuality')
                            : graphIndex === 5
                            ? t('ambientLight')
                            : ''
                        }
                        leftAxisLegend={
                          graphIndex === 0
                            ? t('temperature')
                            : graphIndex === 1
                            ? t('humidity')
                            : graphIndex === 2
                            ? t('fillLevelTooltip')
                            : graphIndex === 3
                            ? t('airPressure')
                            : graphIndex === 4
                            ? t('airQualityYAxis')
                            : graphIndex === 5
                            ? t('ambientLight')
                            : ''
                        }
                        yScale={
                          graphIndex === 1
                            ? { min: 0, max: 100 }
                            : graphIndex === 2
                            ? { min: 0, max: 100 }
                            : graphIndex === 3
                            ? { min: 300, max: 1100 }
                            : graphIndex === 4
                            ? { min: 0, max: 500 }
                            : ''
                        }
                        errMsg={t('errorNoData')}
                        isDatePickerUsed={allDataButton}
                        tickValuesY={10}
                      />
                    ))}
                    <VStack
                      data-testid='stackAlertLogs'
                      align='stretch'
                      spacing='5'
                    >
                      <Flex align='center' justify='start'>
                        <Heading data-testid='alertlogs' size='md' mr='5'>
                          {t('orderOverview.alertLogs.heading')}
                        </Heading>
                      </Flex>
                      {data.hasOwnProperty('alart_log_set') && (
                        <Fade in>
                          <TableExtended
                            columns={columns}
                            data={data.alart_log_set}
                            isPagination
                            defaultPageSize={5}
                          />
                        </Fade>
                      )}
                      <Stack direction='row-reverse' spacing={4}>
                        <Link
                          onClick={() => {
                            setReportLinkClicked(!reportLinkClicked)
                            HandlePdfClick()
                          }}
                        >
                          {t('downloadPDF')}
                        </Link>
                        {isFetching && <Spinner size='md' color={'tertiary'} />}
                      </Stack>
                    </VStack>
                    <VStack align='stretch'>
                      <Heading
                        size='md'
                        mr='5'
                        borderBottom={'1px'}
                        pb={'2'}
                        borderColor='gray.300'
                      >
                        {t('orderOverview.alertSettings.heading')}
                      </Heading>
                      {alarmRules &&
                        alarmRules.map((item, index) => (
                          <Flex key={index} alignItems={'center'}>
                            {DisplayAlertInfo(t, item, ' : ')}
                            <span style={{ marginLeft: '1.5rem' }}>
                              <IconCircle
                                label={t('tooltip.deleteAlert')}
                                icon={<BiTrash />}
                                onClick={() => handleDeleteAlert(item.id)}
                              />
                            </span>
                          </Flex>
                        ))}
                      <CreateAlert submitHandler={postAlertRule} />
                    </VStack>
                    <VStack align='stretch'>
                      <Flex align='center' justify='start'>
                        <Heading
                          size='md'
                          mr='5'
                          borderBottom={'1px'}
                          pb={'2'}
                          borderColor='gray.300'
                        >
                          {t('settings.emailNotificationTitle')}
                        </Heading>
                        <IconCircle
                          data-testid='addIcon'
                          id='addEmail'
                          icon={<AddIcon />}
                          onClick={() => handleclick('addEmail')}
                        />
                      </Flex>
                      <VStack align='stretch'>
                        {data.notification_emails &&
                          data.notification_emails.map((items, index) => (
                            <HStack data-testid='emailStack' key={index}>
                              <Box w='60%'>{items}</Box>
                              <Box w='40%'>
                                <HStack spacing='2'>
                                  <IconCircle
                                    data-testid='editEmail'
                                    icon={<EditIcon />}
                                    onClick={() =>
                                      handleclick(
                                        'editEmail',
                                        setSelectedEmailAddress(items)
                                      )
                                    }
                                  />
                                  <IconCircle
                                    data-testid='deleteEmail'
                                    icon={<DeleteIcon />}
                                    onClick={() =>
                                      handleclick(
                                        'removeEmail',
                                        setSelectedEmailAddress(items)
                                      )
                                    }
                                  />
                                </HStack>
                              </Box>
                            </HStack>
                          ))}
                      </VStack>
                    </VStack>
                  </VStack>
                  <ModalExtended
                    options={getModal(modalChoice)}
                    isOpen={isOpen}
                    onClose={onClose}
                    handleSubmit={handleSubmit}
                  />
                  <ModalExtended
                    callback={handleclick}
                    options={GetModal(
                      t,
                      modalChoice,
                      data,
                      mapsensorsUnassigned(unassignedSensors),
                      '',
                      selectedSensor,
                      mapIbcTypes(ibcTypes)
                    )}
                    isFail={
                      (registerSensor.data &&
                        registerSensor.data.status === 200) ||
                      !registerSensor.isSuccess ||
                      !registerIBCType.isSuccess
                    }
                    isOpen={isOpen}
                    onClose={onClose}
                    state={updateOrder}
                    handleSubmit={handleSubmit}
                    modalTitle={
                      modalChoice === 'editSensor' ||
                      modalChoice === 'editHistorySensor' ? (
                        <Box borderBottom='1px' borderColor='gray.200' py={4}>
                          {t(
                            'orderOverview.modalEditSensorDetails.modalEditSensorTitle'
                          )}{' '}
                          #{selectedSensor}
                        </Box>
                      ) : (
                        ''
                      )
                    }
                  />
                  <Modal
                    isOpen={!orderId}
                    blockScrollOnMount
                    isCentered
                    size='xl'
                    onClose={null}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>
                        <HStack spacing='4'>
                          <WarningIcon w={5} h={5} color='negative' />
                          <Text>
                            {t('orderOverview.orderNotSelectedTitle')}
                          </Text>
                        </HStack>
                      </ModalHeader>
                      <ModalBody>
                        {t('orderOverview.orderNotSelected')}
                      </ModalBody>

                      <ModalFooter data-testid='modalFooter'>
                        <Button
                          variant='primary'
                          mr={5}
                          as={ReactRouterLink}
                          to={{
                            pathname: '/',
                          }}
                        >
                          {t('continue')}
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Fade>
              </Box>
            </Box>
          </DocumentTitle>
        )
      )}
    </>
  )
}

export default withRouter(OrderOverview)
