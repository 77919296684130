import { useTranslation } from 'react-i18next'
import { Text } from '@chakra-ui/react'
import React from 'react'

/**
 * This function creates date and time html to show
 * @param dateStr is the date for modification
 * @param separator required to show on UI
 * @param changeFormat Bool to check if the date format should be changed
 * @returns {string}
 * @constructor
 */
export const CreateDateTimeHTML = (dateStr, separator, changeFormat) => {
  if (dateStr) {
    let date = dateStr.split('T')[0]
    return `${
      changeFormat
        ? date.split('-')[2] +
          '.' +
          date.split('-')[1] +
          '.' +
          date.split('-')[0]
        : date
    }${separator ? ' ' + separator + ' ' : ' '}${new Date(
      dateStr
    ).toLocaleTimeString('de-DE', {
      timeStyle: 'short',
      hour12: false,
    })}`
  }
}

export const DEFAULT_TABLE_PROPS = {
  iconProps: {
    color: '#444444',
    fontSize: '15px',
  },
  centerIcon: {
    as: 'button',
    h: '20px',
    maxH: '20px',
  },
  vstack: {
    spacing: '3',
    align: 'stretch',
  },
}

/**
 * This function filters the data based on key provided and returns an object
 * @param data is the whole data set
 * @param dataKey is the key of an array in the data set
 * @param filterKey is the key with which the value needs to be compared
 * @param value
 * @returns {*}
 */
export const filterData = (data, dataKey, filterKey, value) =>
  data &&
  data[dataKey] &&
  data[dataKey].find((item) => item[filterKey] === value)

/**
 * This util function returns toast function to show in bottom right
 * @param success success message string as per operation performed
 * @param error success message string as per operation performed and it results in failure
 * @param warning warning message string as per operation performed and it results catch warnings
 * @returns {{success: {description: *, title: string}, error: {description: *, title: string}}}
 * @constructor
 */
export const ToastCallback = (success, error, warning) => {
  const { t } = useTranslation()
  return {
    success: {
      title: t('success') + '!',
      description: success,
    },
    error: {
      title: t('error') + '!',
      description: error,
    },
    warning: {
      title: t('warning') + '!',
      description: warning,
    },
  }
}

/**
 * This function returns text to display alerts for appropriate fields with values
 * @param t language instance
 * @param item is the current iterated object
 * @param separator to show before the measurement value
 * @returns {*}
 * @constructor
 */
export const DisplayAlertInfo = (t, item, separator) => {
  const itemKeys = {
    humidity: t('humidity'),
    temperature: t('temperature'),
    air_pressure: t('airPressure'),
    air_quality: t('airQuality'),
    amb_light: t('ambientLight'),
    fill_level: t('fillLevel'),
  }

  const label = itemKeys[item.alert_field] ?? ''

  return (
    <>
      {label && (
        <Text>
          {label}{' '}
          {item.eval_mode === 'GTE'
            ? t('orderOverview.alertSettings.overCriticalLevel')
            : t('orderOverview.alertSettings.underCriticalLevel')}{' '}
          {separator ? separator : ''} {item.measure_value}
          {item.alert_field === 'temperature' ? '°C' : ''}
        </Text>
      )}
    </>
  )
}

export const minBy = (arr, func) => {
  const min = Math.min(...arr.map(func))
  return arr.find((item) => func(item) === min)
}

export const maxBy = (arr, func) => {
  const max = Math.max(...arr.map(func))
  return arr.find((item) => func(item) === max)
}

export const getMinOrMax = (markers, minOrMax, latOrLng) => {
  if (minOrMax === 'max') {
    return maxBy(markers, (value) => value[latOrLng])[latOrLng]
  } else {
    return minBy(markers, (value) => value[latOrLng])[latOrLng]
  }
}
/**
 *
 * @param color
 * @param opacity
 * @returns Reduce opacity of colors
 */
export const addAlpha = (color, opacity) => {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
  return color + _opacity.toString(16).toUpperCase()
}
